import React, { useState, useEffect } from "react";
import { ApiHelperFunction } from "../../Helper/Helper";
import Header from "../../Share_Module/Header/Header";
import Footer from "../../Share_Module/Footer/Footer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
export default function Forgot_password() {
  const auth =
    window.localStorage.getItem("jobcupid_token") ||
    window.localStorage.getItem("google_token") ||
    window.localStorage.getItem("facebook_token");

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [userData, setuserData] = useState({
    email: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    // window.localStorage.setItem("Step",1)
  }, []);

  useEffect(() => {
    if (auth !== null) {
      history.push("/dashboard");
    }
  }, []);

  const validation = () => {
    let error = {};

    if (!userData.email) {
      error.email = "Email is required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userData.email
      )
    ) {
      error.email = "Enter a valid email";
    }

    return error;
  };

  const submitData = async (event) => {
    event.preventDefault();
    setLoader(true);

    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
      setLoader(false);
    } else {
      let response = await ApiHelperFunction({
        urlPath: "/api/user/forget-password-process",
        method: "post",

        formData: {
          email: userData.email,
        },
        isDaxtra: "",
      });

      if (response !== "") {
        setLoader(false);
        setuserData({ email: "" });
        window.localStorage.setItem("email", response.data.email);
        toast(response?.data?.message, {
          hideProgressBar: true,
          autoClose: 2500,
        });

        if (response.status === 200) {
          history.push("/otp-verification");
        }

        setuserData({
          email: "",
        });
      }
    }
    // setuserData({
    //   email: "",
    // });
  };
  return (
    <>
      <Header />
      <section
        className="loginPage d-flex align-items-center"
        style={{ margin: "9rem 0 5rem", minHeight: "65vh", marginTop: "20px" }}
      >
        <div className="container">
          <div className="row">
            {!loader ? (
              <div className="card my-0">
                <h3 style={{ textAlign: "center" }}>Forgot Your Password?</h3>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-12 passwordForm">
                      <div className="form-group mb-2">
                        <div className="label">Email Address</div>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={userData.email}
                          onChange={postUserData}
                          className="form-control"
                        />
                        <div className="error">{error.email}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="loginBtn-" onClick={submitData}>
                        <button className="btn shadow-none btn-color1 text-white  hoverBtn mt-2">
                          Reset password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="pt-md-4">
                <p className="registerLink">
                  New to JobCupid? <Link to="/auth/register">register</Link>
                </p>
              </div> */}
              </div>
            ) : (
              <Loader
              data={["Please wait.."]}
              needprompt={true}
             //  timebetween={3000}
           />
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
