import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../Share_Module/Footer/Footer";
import LoginPage from "./LoginPage/LoginPage";

import "./AuthModule.scss";
import RegisterPage from "./CreateAccountPage/Register";

export default function AuthModule() {
  return (
    <>
      <div className="authLogo text-center">
        <Link className="text-color1" to="/home">
          <svg
            width="302"
            height="175"
            viewBox="0 0 302 175"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.3089 155.049C21.3199 156.499 21.0456 157.938 20.5015 159.283C19.9822 160.571 19.159 161.714 18.102 162.615C16.9096 163.624 15.5301 164.388 14.0423 164.865C12.4578 165.395 10.4993 165.661 8.16685 165.661C6.58238 165.661 5.09078 165.603 3.69205 165.488C2.45267 165.396 1.21983 165.231 0 164.993V158.049H0.832037C1.56994 158.326 2.3388 158.583 3.13862 158.82C4.13938 159.084 5.17249 159.205 6.20711 159.179C7.82191 159.179 9.06333 158.963 9.93138 158.532C10.739 158.162 11.3948 157.526 11.7888 156.73C12.1491 155.95 12.3532 155.107 12.3896 154.249C12.4351 153.347 12.4578 152.196 12.4578 150.796V137.102H4.59231V130.779H21.3089V155.049Z"
              fill="#404041"
            />
            <path
              d="M55.6597 152.189C55.6597 156.449 54.4177 159.806 51.9335 162.259C49.4494 164.711 45.9621 165.937 41.4715 165.937C36.9821 165.937 33.4954 164.711 31.0113 162.259C28.5272 159.806 27.2852 156.449 27.2852 152.189C27.2852 147.893 28.5348 144.525 31.0341 142.085C33.5333 139.645 37.0125 138.422 41.4715 138.416C45.9937 138.416 49.4892 139.646 51.9582 142.108C54.4271 144.569 55.661 147.929 55.6597 152.189ZM45.4402 158.487C46.0013 157.781 46.4133 156.969 46.6513 156.099C46.9205 155.169 47.055 153.881 47.055 152.234C47.0816 150.946 46.9421 149.659 46.64 148.406C46.4278 147.514 46.035 146.676 45.4857 145.942C45.0301 145.339 44.415 144.876 43.7098 144.604C42.9935 144.339 42.2352 144.206 41.4715 144.211C40.7476 144.2 40.0269 144.309 39.3393 144.536C38.6413 144.804 38.0249 145.249 37.5501 145.826C37.0447 146.428 36.641 147.249 36.339 148.29C36.037 149.331 35.8873 150.646 35.8898 152.234C35.8702 153.473 36.0019 154.709 36.2822 155.915C36.4826 156.822 36.8756 157.675 37.4345 158.417C37.9015 158.999 38.5096 159.451 39.2009 159.732C40.6491 160.274 42.2422 160.286 43.6985 159.766C44.3864 159.509 44.9894 159.066 45.4402 158.487Z"
              fill="#404041"
            />
            <path
              d="M88.6487 151.82C88.6487 155.896 87.5235 159.218 85.2732 161.785C83.0228 164.353 80.2153 165.637 76.8505 165.638C75.5719 165.657 74.2966 165.503 73.0599 165.178C71.9686 164.869 70.9224 164.419 69.9478 163.84L69.6009 165.13H61.6104V129.235H69.9383V141.922C71.1422 140.955 72.4528 140.128 73.8445 139.458C75.1725 138.827 76.7045 138.511 78.4406 138.511C81.7132 138.511 84.2327 139.691 85.9991 142.051C87.7655 144.411 88.6487 147.668 88.6487 151.82ZM80.0895 151.994C80.0895 149.689 79.6985 147.915 78.9163 146.672C78.1342 145.429 76.738 144.807 74.7277 144.807C73.9089 144.812 73.0949 144.932 72.3093 145.163C71.4786 145.403 70.6818 145.747 69.9383 146.189V159.179C70.4904 159.383 71.062 159.53 71.6441 159.617C72.285 159.701 72.931 159.739 73.5773 159.732C75.772 159.732 77.4064 159.087 78.4804 157.797C79.5544 156.507 80.0908 154.573 80.0895 151.994Z"
              fill="#404041"
            />
            <path
              d="M110.635 165.799C108.231 165.821 105.84 165.436 103.564 164.662C101.454 163.964 99.5315 162.795 97.9405 161.245C96.3496 159.694 95.1323 157.801 94.3809 155.71C93.528 153.482 93.1016 150.906 93.1016 147.983C93.1016 145.26 93.5091 142.791 94.324 140.576C95.0896 138.449 96.302 136.51 97.8796 134.891C99.4459 133.319 101.34 132.114 103.427 131.361C105.743 130.512 108.193 130.089 110.66 130.114C111.967 130.106 113.273 130.187 114.57 130.357C115.655 130.503 116.731 130.715 117.792 130.992C118.746 131.258 119.682 131.585 120.595 131.972C121.433 132.333 122.167 132.668 122.797 132.976V141.304H121.783C121.351 140.937 120.809 140.501 120.157 139.996C119.444 139.449 118.699 138.947 117.924 138.492C117.051 137.99 116.131 137.575 115.178 137.252C114.16 136.911 113.092 136.74 112.019 136.748C110.809 136.742 109.608 136.94 108.465 137.335C107.299 137.753 106.237 138.416 105.351 139.28C104.444 140.156 103.71 141.314 103.149 142.756C102.588 144.198 102.307 145.948 102.307 148.006C102.307 150.154 102.61 151.949 103.217 153.392C103.823 154.835 104.588 155.973 105.512 156.804C106.414 157.627 107.486 158.244 108.651 158.61C109.753 158.964 110.904 159.147 112.062 159.152C113.145 159.151 114.222 158.989 115.258 158.671C116.283 158.362 117.264 157.921 118.176 157.361C118.906 156.933 119.607 156.459 120.274 155.94C120.921 155.434 121.452 154.998 121.867 154.63H122.79V162.846C121.929 163.225 121.108 163.587 120.326 163.93C119.524 164.276 118.701 164.569 117.862 164.808C116.823 165.111 115.768 165.357 114.702 165.545C113.356 165.74 111.995 165.825 110.635 165.799Z"
              fill="#FAAF40"
            />
            <path
              d="M154.648 165.13H146.321V162.27C145.009 163.298 143.597 164.191 142.105 164.935C140.829 165.541 139.309 165.845 137.543 165.846C134.688 165.846 132.487 165.025 130.942 163.382C129.397 161.74 128.625 159.31 128.626 156.093V139.225H136.956V152.075C136.956 153.383 136.998 154.471 137.081 155.339C137.135 156.09 137.339 156.822 137.68 157.494C137.96 158.054 138.431 158.495 139.007 158.741C139.598 159.002 140.424 159.133 141.486 159.133C142.285 159.118 143.078 158.986 143.84 158.741C144.718 158.471 145.556 158.083 146.33 157.587V139.223H154.658L154.648 165.13Z"
              fill="#FAAF40"
            />
            <path
              d="M189.53 151.819C189.556 153.808 189.225 155.785 188.552 157.657C187.899 159.41 187.042 160.856 185.982 161.993C184.932 163.153 183.65 164.08 182.22 164.715C180.804 165.334 179.273 165.649 177.726 165.638C176.458 165.665 175.192 165.506 173.97 165.166C172.879 164.824 171.822 164.38 170.814 163.839V174.642H162.486V139.223H170.814V141.922C172.018 140.955 173.328 140.128 174.719 139.458C176.047 138.826 177.579 138.51 179.317 138.51C182.542 138.51 185.05 139.694 186.839 142.062C188.628 144.43 189.525 147.682 189.53 151.819ZM180.973 151.994C180.973 149.475 180.543 147.648 179.684 146.51C178.825 145.373 177.467 144.805 175.609 144.805C174.791 144.81 173.978 144.93 173.193 145.161C172.362 145.401 171.565 145.745 170.822 146.186V159.199C171.39 159.419 171.987 159.559 172.594 159.615C173.226 159.676 173.847 159.707 174.459 159.707C176.651 159.707 178.286 159.066 179.362 157.784C180.439 156.501 180.976 154.571 180.973 151.994Z"
              fill="#FAAF40"
            />
            <path
              d="M204.088 135.533H195.298V129.235H204.088V135.533ZM203.857 165.13H195.529V139.223H203.857V165.13Z"
              fill="#FAAF40"
            />
            <path
              d="M236.869 165.13H228.541V162.431C227.112 163.601 225.775 164.463 224.531 165.016C223.169 165.593 221.701 165.876 220.223 165.847C217.075 165.847 214.556 164.631 212.666 162.2C210.776 159.769 209.828 156.493 209.823 152.373C209.823 150.174 210.139 148.225 210.771 146.526C211.355 144.914 212.242 143.43 213.386 142.153C214.443 140.992 215.741 140.074 217.188 139.466C218.598 138.848 220.119 138.525 221.657 138.518C223.209 138.518 224.48 138.684 225.471 139.015C226.515 139.366 227.535 139.786 228.524 140.271V129.235H236.852L236.869 165.13ZM228.541 158V145.269C227.95 145.02 227.332 144.842 226.699 144.739C226.114 144.636 225.521 144.581 224.927 144.576C222.747 144.576 221.113 145.257 220.024 146.621C218.935 147.984 218.39 149.875 218.39 152.293C218.39 154.834 218.827 156.679 219.702 157.828C220.576 158.976 221.981 159.55 223.917 159.549C224.733 159.538 225.541 159.393 226.31 159.12C227.106 158.86 227.858 158.482 228.541 158Z"
              fill="#FAAF40"
            />
            <path
              d="M253.64 165.13H245.013V156.065H253.64V165.13Z"
              fill="#404041"
            />
            <path
              d="M277.47 162.386C277.025 162.723 276.471 163.132 275.81 163.608C275.223 164.044 274.597 164.424 273.941 164.745C273.129 165.114 272.277 165.389 271.403 165.566C270.453 165.749 269.488 165.838 268.52 165.831C266.059 165.831 263.998 165.07 262.338 163.548C260.678 162.025 259.847 160.079 259.846 157.71C259.846 155.815 260.269 154.269 261.116 153.072C261.962 151.876 263.162 150.928 264.717 150.229C266.256 149.537 268.163 149.045 270.439 148.753C272.714 148.461 275.075 148.246 277.521 148.107V147.968C277.521 146.539 276.937 145.551 275.768 145.004C274.599 144.457 272.875 144.181 270.596 144.178C269.107 144.197 267.629 144.443 266.214 144.905C264.66 145.388 263.545 145.761 262.869 146.024H262.111V139.769C262.986 139.54 264.413 139.267 266.39 138.95C268.355 138.637 270.341 138.479 272.33 138.478C277.051 138.478 280.462 139.205 282.565 140.658C284.667 142.111 285.713 144.394 285.703 147.506V165.132H277.466L277.47 162.386ZM277.47 158.354V152.974C276.348 153.065 275.133 153.191 273.825 153.353C272.819 153.457 271.824 153.647 270.85 153.921C270.126 154.12 269.47 154.513 268.955 155.058C268.517 155.558 268.298 156.213 268.297 157.024C268.286 157.465 268.332 157.906 268.435 158.335C268.561 158.721 268.802 159.058 269.127 159.3C269.496 159.615 269.93 159.847 270.397 159.979C270.887 160.124 271.656 160.197 272.703 160.197C273.57 160.19 274.427 160.019 275.23 159.692C276.046 159.374 276.803 158.922 277.47 158.354Z"
              fill="#404041"
            />
            <path
              d="M301.903 135.533H293.112V129.235H301.903V135.533ZM301.671 165.13H293.344V139.223H301.671V165.13Z"
              fill="#404041"
            />
            <path
              d="M141.131 0.00476074V21.09H126.943V80.2954C126.978 85.0941 126.07 89.853 124.271 94.3017C122.556 98.565 119.832 102.348 116.333 105.327C112.618 108.532 108.142 111.014 102.903 112.771C97.6644 114.529 91.1755 115.406 83.4364 115.404C78.1965 115.404 73.2605 115.214 68.6284 114.835C64.53 114.525 60.4528 113.979 56.417 113.2V90.2192H59.1633C61.6095 91.1391 64.1555 91.9907 66.8014 92.7741C69.4472 93.5575 72.8303 93.951 76.9507 93.9548C82.2929 93.9548 86.3994 93.2422 89.2701 91.8169C92.1409 90.3917 94.1891 88.4054 95.4147 85.8581C96.6054 83.2795 97.2801 80.4929 97.401 77.6553C97.5602 74.6758 97.6385 70.8669 97.636 66.2285V20.9137H71.6097V0.00476074H141.131Z"
              fill="#404041"
            />
            <path
              d="M97.6366 20.9109H71.6104V21.0853H97.6366V20.9109Z"
              fill="#404041"
            />
            <path
              d="M199.372 113.64C191.135 113.64 183.451 112.416 176.505 110.001C169.733 107.642 163.858 104.096 159.046 99.4635C154.233 94.8313 150.433 88.9578 147.75 81.9566C145.024 74.834 143.641 66.4151 143.641 56.9481C143.641 48.1387 144.967 40.0799 147.57 33.0047C150.13 26.0433 153.892 19.9973 158.752 15.0316C163.399 10.3104 169.258 6.59562 176.156 4.00285C183.207 1.34942 191.046 0.00186589 199.457 0.00186589C203.766 -0.0250987 208.072 0.24022 212.345 0.795996C215.89 1.27411 219.402 1.96653 222.862 2.86946C225.351 3.56714 227.804 4.38947 230.21 5.33335L233.504 6.64679L237.729 2.24401L245.479 28.9677L218.463 22.3342L223.202 17.395L219.106 15.6893C214.419 13.7371 209.406 12.6113 204.322 12.598H204.205C199.41 12.5821 194.645 13.3661 190.108 14.9179C185.495 16.5365 181.221 19.2013 177.377 22.8478C173.617 26.4868 170.616 31.1871 168.425 36.873C166.244 42.4566 165.133 49.2475 165.133 57.0353C165.133 65.1528 166.339 72.1427 168.717 77.8134C171.121 83.5221 174.255 88.139 178.037 91.543C181.736 94.9105 186.124 97.4328 190.896 98.9347C195.362 100.341 199.749 100.703 203.619 101.019L203.919 101.044C213.721 101.853 220.597 102.168 225.626 102.397C229.591 102.587 232.496 102.71 234.361 102.994C232.845 104.374 229.845 106.379 223.973 108.798C216.226 111.996 207.926 113.641 199.544 113.64H199.372Z"
              fill="#FAAF40"
            />
          </svg>
        </Link>
      </div>
      <Switch>
        <Route path="/auth/login">
          <LoginPage />
        </Route>
        <Route path="/auth/register">
          <RegisterPage />
        </Route>
        <Route path="**">
          <div className="py-5">
            <div className=" py-5   text-center d-flex align-items-center justify-content-center flex-column">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
                width="160"
              >
                <title>404-browser</title>
                <g id="_404-browser" data-name="404-browser">
                  <g id="browser">
                    <path
                      d="M4,50H48a2,2,0,0,0,2-2V12H2V48A2,2,0,0,0,4,50Z"
                      fill="#eae8e8"
                    />
                    <path
                      d="M48,2H4A2,2,0,0,0,2,4v8H50V4A2,2,0,0,0,48,2Z"
                      fill="#40495a"
                    />
                    <rect x="6" y="6" width="6" height="2" fill="#748191" />
                    <rect x="16" y="6" width="30" height="2" fill="#748191" />
                  </g>
                  <rect x="4" y="14" width="44" height="34" fill="#fff" />
                  <rect x="28" y="17" width="18" height="2" fill="#ccc" />
                  <rect x="6" y="17" width="18" height="2" fill="#ccc" />
                  <rect x="25" y="17" width="2" height="2" fill="#777" />
                  <rect x="29" y="43" width="18" height="2" fill="#ccc" />
                  <rect x="6" y="43" width="18" height="2" fill="#ccc" />
                  <rect x="25" y="43" width="2" height="2" fill="#777" />
                  <path
                    d="M19.84,31.28H21v1.55H19.84V35H17.78V32.83H13.5v-1.2L17.76,25h2.08Zm-4.47,0h2.41V27.64h0l-.21.45Z"
                    fill="#ff6e40"
                  />
                  <path
                    d="M29.75,31.34a3.52,3.52,0,0,1-1,2.68A3.77,3.77,0,0,1,26,35a3.81,3.81,0,0,1-2.73-1,3.52,3.52,0,0,1-1-2.68V28.67a3.54,3.54,0,0,1,1-2.68A3.77,3.77,0,0,1,26,25a3.79,3.79,0,0,1,2.72,1,3.53,3.53,0,0,1,1,2.68Zm-2.22-2.88A2.28,2.28,0,0,0,27.13,27a1.5,1.5,0,0,0-2.26,0,2.3,2.3,0,0,0-.4,1.45v3.06a2.32,2.32,0,0,0,.4,1.46A1.37,1.37,0,0,0,26,33.5,1.35,1.35,0,0,0,27.13,33a2.34,2.34,0,0,0,.4-1.46Z"
                    fill="#ff6e40"
                  />
                  <path
                    d="M37.34,31.28H38.5v1.55H37.34V35H35.28V32.83H31v-1.2L35.26,25h2.08Zm-4.47,0h2.41V27.64h0l-.21.45Z"
                    fill="#ff6e40"
                  />
                </g>
              </svg>
              <br />
              <h1>
                Page Not Found. Go{" "}
                <Link className="text-color1" to="/home">
                  Home
                </Link>
              </h1>
            </div>
          </div>
        </Route>
      </Switch>
      {/* <Footer /> */}
    </>
  );
}
