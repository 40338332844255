import { useState, useEffect } from "react";
import AfterClickJobTitle from "./AfterClickJobTitle";
import ReactPaginate from "react-paginate";
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setdaxtraID, fetchJobList } from "../../Redux/Slices/SearchJobSlice";
import { ApiHelperFunction } from "../../Helper/Helper";
import Loader from "../../AuthModule/Forgot_password/Loader";
import Moment from "react-moment";
import AfterSearchResultSkeleton from "./Skeletons/AfterSearchResultSkeleton";
import AfterClickJobTitleSkeleton from "./Skeletons/AfterClickJobTitleSkeleton";
import { NavLink } from "react-router-dom";
import moment from "moment";
import "./AfterSearchResualt.scss";
// const searchResults = [
//   {
//     title: "Product Development Engineer 1",
//     sub_title: "ADVANCEDMICRODEVICESINC",
//   },
//   {
//     title: "Product Development Engineer 1 (Systems Debug)",
//     sub_title: "",
//   },
//   {
//     title: "Sr. Product Development Engineer",
//     sub_title: "ADVANCEDMICRODEVICESINC",
//   },
//   {
//     title: "Sr. Product Development Engineer",
//     sub_title: "ADVANCEDMICRODEVICESINC",
//   },
//   { title: "Sr. Product Developer", sub_title: "ADVANCEDMICRODEVICESINC" },
// ];

const searchTag = [
  "desktop support engineer",
  "system administrator",
  "hardware networking",
  "electronics engineer",
  "computer hardware",
  "computer operator",
  "desktop support",
  "networking engineer",
  "it support engineer",
  "hardware network engineer",
];

export default function AfterSearchResualt({
  job_id_list,
  child2parent,
  filterDate,
  srchData
}) {
  const dispatch = useDispatch();
  const [curr_page, setcurr_page] = useState(1);
  const [IsSearched, setIsSearched] = useState(false);
  const [activeData, setactiveData] = useState();
  const [perPage] = useState(9);
  const [totalCount, settotalCount] = useState();
  const [pageCount, setPageCount] = useState(1);
  const[pageSiblingCnt,setpageSiblingCnt]=useState(2);
  const[pageBndryCount,setpageBndryCount]=useState(3);
  const [offset, setOffset] = useState(0);
  const [playerData, setPlayerData] = useState(null);
  const [allData, setallData] = useState([]);
  const [Data, setData] = useState();
  const [hasData, sethasData] = useState(true);
  const [ID, setID] = useState("");
  const [Matching, setMatching] = useState("80");
  const [ChooseTab, setChooseTab] = useState("1");
  // const [user_id, setuser_id] = useState("");
  const [loader, setloader] = useState(false);
  const { AllJobstatus } = useSelector((e) => e.searchJob);

  const handelChooseTab = (data) => {
    setChooseTab(data);
  };

  const MatchingWidth = {
    width: Matching + "%",
  };
  const session_srchData=JSON.parse( window.sessionStorage.getItem("searchfilters"))


  const user_id = localStorage.getItem("user_id");

  const landing = (val) => {
    window.scrollTo(0, document.getElementById("resultWrap").offsetTop - 90);
    setactiveData(val);
  };

  const getJobList = async (current_page, isPaginated) => {
    if (isPaginated) {
      setloader(true);
    }
    let obj = {
      job_id_list: job_id_list,
      current_page: current_page,
      user_id: user_id,
      per_page: perPage,
    };
    let response2 = dispatch(fetchJobList(obj));

    response2
      ?.then((res) => {
        console.log("res",res);
        if (res?.payload?.status !== 201) {
          // setloader(false);
          child2parent(true);
          settotalCount(res?.payload?.total_record);
          setPageCount(res?.payload?.total_page);
          setcurr_page(res?.payload?.current_page);
          window.scrollTo(
            0,
            document.getElementById("resultWrap").offsetTop - 90
          );
          // document.getElementsByClassName("previous");

          let temp = [];
          temp = res?.payload?.data;
          landing(res?.payload?.data[0]?.daxtraID);
          setPlayerData(res?.payload?.data);
          setallData(res?.payload?.data);
          setData(res?.payload?.data);
          sethasData(true);

          setID(res?.payload?.data[0]?.daxtraID || "");
          dispatch(setdaxtraID(res?.payload?.data[0]?.daxtraID || ""));
          // setloader(false);
        } else {
          // setloader(false);
          setPlayerData([]);

          sethasData(false);
          child2parent(false);
          // setloader(false);
        }
        if (isPaginated) {
          setloader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

      // testing code ..

    // let response = await ApiHelperFunction({
    //   urlPath: "/api/search/keyword",
    //   method: "post",
    //   formData: {
    //     job_id_list,
    //     current_page,
    //     user_id,
    //   },
    //   isDaxtra: "",
    // });
    //   console.log(response?.data);

    //   if (response?.data?.status !== 201) {
    //     // console.log(response);
    //     // setloader(false);
    //     child2parent(true);
    //     // console.log("Step2.2");
    //     setresponseFromApi(response);
    //     settotalCount(response?.data?.total_record);
    //     setPageCount(response?.data?.total_page);
    //     setcurr_page(response?.data?.current_page);
    //     window.scrollTo(0, document.getElementById("resultWrap").offsetTop);
    //     document.getElementsByClassName("previous");
    //     setPlayerData(response?.data?.data);
    //     // console.log(response?.data?.data);
    //     setallData(response?.data?.data);
    //     setData(response?.data?.data);
    //     sethasData(true);

    //     setID(response?.data?.data[0]?.daxtraID || "");
    // dispatch(setdaxtraID(response?.data?.data[0]?.daxtraID || ""))
    //   }
    //   else {
    //     // setloader(false);
    //     sethasData(false);
    //     child2parent(false);
    //   }
  };

  const handlepage = (e, p) => {
    let isPaginated = true;
    // the loader will show only when get API is called via pagination,
    //getJobList() is called when the job search is called
    //so we need to know if the api is called via page refresh or pagination.
    getJobList(p, isPaginated);
  };

  useEffect(() => {
    let temp = [];

    if (filterDate?.startDate !== "") {
      //['daxtra add response'].DxResponse.Vacancy[0].StructuredOptions.DatePosted
      allData.forEach((record) => {
        //   console.log(
        //     record?.last_updated ||
        //     record?.[`daxtra add response`]?.DxResponse.Vacancy[0]

        //  );

        //   console.log(moment(
        //     record?.last_updated ||
        //     record?.[`daxtra add response`]?.DxResponse.Vacancy[0]
        //       ?.StructuredOptions?.DatePosted
        //   ).isBetween(filterDate?.startDate, filterDate?.endDate)
        //  );

        if (
          moment(
            // record?.last_updated ||
            // record?.[`daxtra add response`]?.DxResponse.Vacancy[0]
            //   ?.StructuredOptions?.DatePosted
            record?.updated_date || record?.created_date
          ).isBetween(filterDate?.startDate, filterDate?.endDate)
        ) {
        
          temp = [...temp, record];
        }
      });
      
      setPlayerData(temp);
      landing(temp[0]?.daxtraID);
    } 
    else {  
      setPlayerData(allData);
      landing(allData[0]?.daxtraID);
    }
 
  }, [filterDate?.startDate, job_id_list]);

  useEffect(() => {
    getJobList(1);
  }, [job_id_list]);

  //change visible page no. of pagiantion of different screen width
  useEffect(() => {
    if(window.innerWidth>1140)
    {
      setpageSiblingCnt(2);
      setpageBndryCount(3);
    }
    else if(window.innerWidth<768)
    {
      setpageSiblingCnt(1);
      setpageBndryCount(1);
    }
    
  }, [window.innerWidth])

  window.onresize=()=>{
    if(window.innerWidth>1140)
    {
      setpageSiblingCnt(2);
      setpageBndryCount(3);
    }
    else if(window.innerWidth<768)
    {
      setpageSiblingCnt(1);
      setpageBndryCount(1);
    }
    
  }
 
  // const getTime = (timestamp) => {
  //   // let timestamp = "2022-01-04 12:06:00";

  //   let time = "";
  //   if (moment().diff(timestamp, "year") <= 1) {
  //     if (
  //       moment().diff(timestamp, "months") < 12 &&
  //       moment().diff(timestamp, "months") > 1
  //     ) {
  //       time = moment().diff(timestamp, "months") + " months ago";
  //     } else if (moment().diff(timestamp, "months") === 1) {
  //       time = "1 month ago";
  //     } else if (moment().diff(timestamp, "months") < 1) {
  //       if (
  //         moment().diff(timestamp, "weeks") > 1 &&
  //         moment().diff(timestamp, "weeks") < 4
  //       ) {
  //         time = moment().diff(timestamp, "weeks") + " weeks ago";
  //       } else if (moment().diff(timestamp, "weeks") === 1) {
  //         time = "1 week ago";
  //       } else if (moment().diff(timestamp, "weeks") < 1) {
  //         if (
  //           moment().diff(timestamp, "days") > 1 &&
  //           moment().diff(timestamp, "days") < 7
  //         ) {
  //           time = moment().diff(timestamp, "days") + " days ago";
  //         } else if (moment().diff(timestamp, "days") === 1) {
  //           time = "1 Day ago";
  //         } else if (moment().diff(timestamp, "days") < 1) {
  //           if (
  //             moment().diff(timestamp, "hours") > 1 &&
  //             moment().diff(timestamp, "months") < 24
  //           ) {
  //             time = moment().diff(timestamp, "hours") + " hours ago";
  //           } else if (moment().diff(timestamp, "hours") === 1) {
  //             time = "1 hour ago";
  //           } else if (moment().diff(timestamp, "hours") < 1) {
  //             if (
  //               moment().diff(timestamp, "minutes") < 60 &&
  //               moment().diff(timestamp, "minutes") > 1
  //             ) {
  //               time = moment().diff(timestamp, "minutes") + " minutes ago";
  //             } else if (moment().diff(timestamp, "minutes") < 1) {
  //               time = "Just now";
  //             } else if (moment().diff(timestamp, "minutes") === 1) {
  //               time = "1 minute ago";
  //             }
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     time = moment().diff(timestamp, "year") + " years ago";
  //   }
  //   return time;
  // };


  return (
    <>
      <section className="resultWrap pt-5"> 
     
        {!loader ? (
          ""
        ) : (
          <>
          <Loader
            data={["Please wait.."]}
            needprompt={true}
            // timebetween={3000}
          />
          {playerData?.length === 0 && <AfterSearchResultSkeleton />}
          </>
        )}
        <div className="container-xl" id="resultWrap">
          <div className="secHead">
            <div className="row align-items-center  ">
             

                    
            </div>
          </div>

          <div className="secBody  ">
            {
              // hasData === false ||
              job_id_list?.length == 0 &&
              AllJobstatus !== "loading" &&
              playerData !== null &&
              playerData?.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12">
                    <span className="text-sm-center fa-2x text-color1 ">
                      <h4> No Data Found</h4>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="row">
                 
                  <div className="col-lg-5">
          <div className="resultSummary">
        
            <a className="  p-0 shadow-none active active-new">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 38 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="9" height="9" fill="currentColor" />
                          <rect x="11" width="27" height="9" fill="currentColor" />
                          <rect y="13" width="9" height="9" fill="currentColor" />
                          <rect
                            x="11"
                            y="13"
                            width="27"
                            height="9"
                            fill="currentColor"
                          />
                          <rect y="26" width="9" height="9" fill="currentColor" />
                          <rect
                            x="11"
                            y="26"
                            width="27"
                            height="9"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                      <div className="col-auto pe-1 fs-xl-18 result-no" id="resultWrap1">
                      <div className="span-text">
                      <span> 
                      <b>
                        {AllJobstatus !== "loading" && playerData !== null && (
                          <i>{hasData === false ? "0" : totalCount} Results</i>
                        )}
                      </b> 
                      {(srchData?.keyword || session_srchData?.keyword) &&
                       <span  style={{ textTransform: "capitalize"}}> for "{srchData?.keyword || session_srchData?.keyword}"
                        </span>}
                        
                      {(srchData?.filters?.Location|| session_srchData?.filters?.Location)
                       && <> In {srchData?.filters?.Location || session_srchData?.filters?.Location} </>}
                  
                      </span>
                      </div>
                      
                     
                     
                    </div>
            </div>
                    <div className="row">
                      {playerData?.map((data, ind) => (
                        <div
                          ClassName="col-lg-12 cardWrap"
                          key={ind}
                          onClick={() => {
                            setID(data?.daxtraID);
                            landing(data?.daxtraID);
                          }}
                        >
                          <div
                            className={
                              activeData === data?.daxtraID
                                ? "card active"
                                : "card"
                            }
                          >
                            <h4 className="dev-title">{data?.title}</h4>
                            <div className="job-title-top">
                            <h5> {data?.company}</h5>
                            <div className="d-flex">
                              <svg
                                width="18"
                                height="21"
                                viewBox="0 0 18 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.75 9C5.75 7.20507 7.20507 5.75 9 5.75C10.7949 5.75 12.25 7.20507 12.25 9C12.25 10.7949 10.7949 12.25 9 12.25C7.20507 12.25 5.75 10.7949 5.75 9Z"
                                  fill="#FBAF41"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.773543 7.87739C1.11718 3.70845 4.60097 0.5 8.78406 0.5H9.21599C13.3991 0.5 16.8829 3.70845 17.2265 7.87739C17.4115 10.122 16.7182 12.3508 15.2925 14.0943L10.4995 19.9561C9.72449 20.9039 8.27556 20.9039 7.50059 19.9561L2.70752 14.0943C1.28187 12.3508 0.588525 10.122 0.773543 7.87739ZM9 4.25C6.37665 4.25 4.25 6.37665 4.25 9C4.25 11.6234 6.37665 13.75 9 13.75C11.6234 13.75 13.75 11.6234 13.75 9C13.75 6.37665 11.6234 4.25 9 4.25Z"
                                  fill="#FBAF41"
                                />
                              </svg>

                              <span className="h5">{data?.location}</span>
                            </div>
                             </div>
                            <p className="job-title-down">
                            
                              <span style={{ fontWeight: "700" }}>
                             
                                <Moment fromNow>
                                  {/* {
                                 data?.last_updated ||  data?.["daxtra add response"].DxResponse
                                    .Vacancy[0].StructuredOptions.DatePosted || ""
                                } */}
                                  {data?.updated_date || data?.created_date}
                                </Moment>
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {ID ? (
                    <>
                      {" "}
                      <AfterClickJobTitle id="cardWrap" ID={ID} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )
            }
          </div>
          {hasData === false || playerData?.length === 0 ? (
            <div className="secFooter"></div>
          ) : (
            <div className="secFooter">
              <Stack
                spacing={2}
                className="d-flex list-unstyled justify-content-center align-items-center pagination"
                activeClassName="active"
              >
                <Pagination
                  count={pageCount}
                  page={curr_page}
                  onChange={handlepage}
                  shape="rounded"
                  siblingCount={pageSiblingCnt}
                  boundaryCount={pageBndryCount}
                   
                />
              </Stack>
            </div>
          )}
        </div>

       
      </section>
    </>
  );
}
