import { React, useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

export default function Loader({ data,needprompt,timebetween }) {
  const [open, setOpen] = useState(true);
  // let promptWithCV = [
  //   "Please wait..",
  //   "Understanding  resume...",
  //   "Looking in the best skill..",
  //   "Looking at your experience..",
  //   "Finding Best Jobs.."
  // ];

  // const [JobSrchPlaceHolders, setJobSrchPlaceHolders] = useState(promptWithCV);
  const [currentLoaderMsg, setcurrentLoaderMsg] = useState("");

  const loaderMsgPrompter = (arrayofPrompts) => {
 
    if(needprompt===true)
   { 
          let count = 0;
          setcurrentLoaderMsg(arrayofPrompts[count]);//0
          if(arrayofPrompts?.length>1){
          const interval = setInterval(() => {
            if (count < arrayofPrompts?.length) {
              setcurrentLoaderMsg(arrayofPrompts[count + 1]);
              count++;
            }

            if (count === (arrayofPrompts?.length-1)) {
              clearInterval(interval);
            }
          }, timebetween|| 2000);
        }

}


  };
  useEffect(() => {
    loaderMsgPrompter(data);
  }, []);

  return (
    <div >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
<div className="loaderCard">
        <CircularProgress color="inherit" />
        <span className="" >
          {" "}
          {currentLoaderMsg}
        </span>
        </div>

      </Backdrop>
    </div>
  );
}
