import { useState, useEffect, React } from "react";
import { ApiHelperFunction } from "../../Helper/Helper";
// const listOfresume = ['Hardware Resume.pdf', 'Oliver Resume.pdf', 'William Resume.pdf', 'Benjamin Resume.pdf'];
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import Loader from "../../AuthModule/Forgot_password/Loader";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../Redux/Slices/AuthSlice";
import {
  searchJobByResumeID,
  AllJobstatus,
  searchJobByResumeFile,
} from "../../Redux/Slices/SearchJobSlice";
import { createbase64, getbase64 } from "../Services/base64Handler";
// import replace_icon from './img/replace_icon.svg'
export default function SearchUsingResume({
  searchByResume,
  getDataByResumeID,
}) {
  const dispatch = useDispatch();
  const user_selector = useSelector((state) => state.userProfile);
  const resume_selector = useSelector((state) => state.fetchResume);
  const history = useHistory();
  const search = useLocation().search;
  const setofID = new URLSearchParams(search).get("ID")?.split(",");
  // console.log(search);
  const [loader, setLoader] = useState(false);
  const [GetSearchUsingResume, SetGetSearchUsingResume] = useState("");
  const [SearchByResume, SetSearchByResume] = useState([]);
  const [listOfresume, setlistOfresume] = useState([]);
  const [resumeNameList, setresumeNameList] = useState([]);
  const [uploadedFile, setuploadedFile] = useState();
  const [job_id_list, setjob_id_list] = useState();
  let promptWithCV = [
    "Please wait..",
    "Uploading your resume..",
    "Understanding the resume..",
    "Looking in the best skills..",
    "Looking at your experience..",
    "Looking into your Projects..",
    "Looking at your designation..",
  ];

  const handelGetSearchUsingResume = (e) => {
    if (e.target.files.length > 0) {
      SetGetSearchUsingResume(e.target.files[0].name);
      setuploadedFile(e.target.files[0]);
    }
  };
  const clearFileInput = () => {
    SetGetSearchUsingResume("");
    SetSearchByResume([]);
    setuploadedFile();
  };
  const handelReUploadSearchUsignResume = () => {
    clearFileInput();
    // SetGetSearchUsingResume('');
  };

  const getDeletePath = async (_id, daxtra_id) => {
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/delete-resume",
      method: "post",
      formData: { resume_id: _id },
      isDaxtra: "",
    });

    let response2 = await ApiHelperFunction({
      urlPath: "/resume/delete/one/",
      method: "post",
      formData: { resume_id: daxtra_id },
    });

    if (response !== "" && response2 !== "") {
      return response;
    }

    // return response;
  };

  const Step1Api = async (file) => {
    let formData = new FormData();
    formData.append("resume_file", file || "");
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/upload",
      method: "post",
      formData,
      isDaxtra: "",
    });

    if (response !== "") {
      //   setstep1Done(true);
      return response;
    }
  };

  const Step2Api = async (file) => {
    let formData1 = new FormData();
    formData1.append("resume_file", file || "");
    let response1 = await ApiHelperFunction({
      urlPath: "/run/resume/flow/json",
      method: "post",
      formData: formData1,
    });

    if (response1 !== "") {
      //   setstep2Done(false);
      return response1;
    }
  };

  const finalApi = async (
    daxtraID,
    resume_file,
    resume_data_response,
    skill_matrix
  ) => {
    let response2 = await ApiHelperFunction({
      urlPath: "/api/resume/save-resume-data",
      method: "post",
      formData: {
        daxtraID: daxtraID,
        resume_file: resume_file,
        resume_data_response: resume_data_response,
        skill_matrix: skill_matrix,
      },
      isDaxtra: "",
    });

    getMultiResume();

    return response2;
  };

  const resumeReplace = async (_id, daxtra_id, file) => {
    setLoader(true);
    //to turn off the list of resumes.
    SetSearchByResume([]);

    let deleteResponse = getDeletePath(_id, daxtra_id);

    deleteResponse?.then(async (response) => {
      if (response?.status === 200) {
        let uploadResponse = await uploadPdf(file);
        //uploadResponse returns daxtraID of newly uploaded resume
        if (uploadResponse !== "" || uploadResponse !== undefined) {
          //if we want to search by the daxtra_id of newly uploaded resume
          //if we want to seach by the file instead.
          console.log("here");
          SearchByFile(file);

          // clearFileInput();//clear the file from the input

          // implement after api is done
          // toast("Resume uploaded. Fetching Data... Please wait.", {
          //   hideProgressBar: true,
          //   autoClose: 2500,
          // });
        }
      }
    });
  };

  const uploadPdf = async (file) => {
    let step1 = await Step1Api(file);
    let step2 = await Step2Api(file);

    if (step1 !== "" && step2 !== "") {
      let daxtraID = step2?.data?.daxtraID;
      let resume_file = step1?.data?.data || file || "";
      let resume_data_response = step2?.data?.resume_data_response;
      let skill_matrix = step2?.data?.skill_matrix;
      let finalresponse = await finalApi(
        daxtraID,
        resume_file || file || "",
        resume_data_response,
        skill_matrix
      );
      if (finalresponse?.status === 200) {
        return daxtraID;
      }

      //   setdisable(false);
      //   setLoader(false);
      //   if (file) return finalresponse;
    }
  };

  useEffect(() => {
    if (
      window.sessionStorage.getItem("srchfile") !== null ||
      window.sessionStorage.getItem("srchfile") !== undefined
    ) {
      let file = getbase64(
        window.sessionStorage.getItem("srchfile"),
        window.sessionStorage.getItem("srchFileName")
      );
      SetGetSearchUsingResume(
        file !== undefined && file !== null ? file?.name : ""
      );
      setuploadedFile(file !== undefined && file !== null && file);
    }
  }, []);

  const SearchByFile = async (file) => {
    // console.log("Search By File");
    // setLoader(true);
    let formData = new FormData();
    formData.append("resume_file", file);
    let response = dispatch(searchJobByResumeFile(formData));
    response
      .then((res) => {
        if (res?.payload?.status === "ok") {
          searchByResume(res?.payload.reranking_id_list, uploadedFile); // to send job_id_list  to searchJobWidget
          window.sessionStorage.setItem(
            "job_id_list",
            JSON.stringify(res?.payload.reranking_id_list)
          );
          createbase64(uploadedFile).then((base64) => {
            sessionStorage["srchfile"] = base64;
          });
        }

        getMultiResume();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // resumeReplace()

    //after calling client api
  };
  const SearchByID = (daxtra_id) => {
    // console.log("Search By ID");
    getDataByResumeID(daxtra_id);
    // searchByResume("","",daxtra_id);
    setLoader(false);
    //after calling client api
    // searchByResume(job_id_list); // to send job_id_list  to searchJobWidget
  };
  const handelSearchByResume = async () => {
    let isSameFileName = false;
    let sameFileDaxtraID = "";
    let sameFileID = "";
    for (let i = 0; i < resumeNameList?.length; i++) {
      if (resumeNameList[i]?.filename === GetSearchUsingResume) {
        isSameFileName = true;
        sameFileDaxtraID = resumeNameList[i]?.daxtra_id;
        sameFileID = resumeNameList[i]?._id;
        break;
      }
    }

    //check if the user has uploaded a file first
    if (!uploadedFile) {
      swal({
        text: "You need to select a file first.",
        icon: "warning",
        buttons: {
          //   Cancel: { text: "Cancel", value: "Cancel" },
          OK: { text: "Ok", value: "ok" },
        },
        dangerMode: true,
      });
    } else {
      //need to check if space is available for new CV is there or not && new CV name matches with any old CV of not ****
      if (isSameFileName === true) {
        setLoader(true);
        swal({
          // title:"Are you sure you want to delete?",
          text: "You already have a resume file with the same name, would you like to keep the new resume or continue using the older one?",
          icon: "warning",
          buttons: {
            //   Cancel:{text:"Cancel",value:"Cancel"},

            UseOlder: {
              text: "Use older resume",
              value: "old",
            },

            UseNew: {
              text: "Use new resume",
              value: "new",
            },
          },
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete === "old") {
            clearFileInput();
            SearchByID(sameFileDaxtraID);
          } else if (willDelete === "new") {
            resumeReplace(sameFileID, sameFileDaxtraID, uploadedFile);
          } else {
            setLoader(false);
          }
        });
      } else if (isSameFileName === false) {
        // check if already 6 resumes are saved for that user
        if (resumeNameList?.length < 6) {
          setLoader(true);

          // if an user is signed in then we are saving he resume uploaded.
          if (
            user_selector?.UserProfile?._id !== "" ||
            user_selector?.UserProfile?._id !== undefined
          ) {
            uploadPdf(uploadedFile);
          }

          SearchByFile(uploadedFile);

          // let uploadResponse = await uploadPdf(uploadedFile);
          // if(uploadResponse!=="" || uploadResponse!==undefined)
          // {
          //   setLoader(false);
          //   SearchByID(uploadResponse);
          // }
          // getMultiResume();
          //save and search with new resume.
          //call api
        } else if (resumeNameList?.length === 6) {
          swal({
            text: "You already have maximum number of resumes saved. You need to replace one from the list below.",
            icon: "warning",
            buttons: {
              //   Cancel: { text: "Cancel", value: "Cancel" },
              OK: { text: "Ok", value: "ok" },
            },
            dangerMode: true,
          }).then((clicked) => {
            if (clicked) {
              SetSearchByResume(listOfresume);
              // window.scrollTo(0, document.getElementById("resumeList").offsetTop);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("searchID")) {
      SearchByID(window.sessionStorage.getItem("searchID"));
    }
    //   if(sessionStorage.getItem('fileBase64')!=="" || sessionStorage.getItem('fileBase64')!==undefined )
    //   {
    //    let file = getbase64(sessionStorage.getItem('fileBase64'),window.sessionStorage.getItem("srchFileName"))
    //    console.log(file);
    //    SearchByFile(file);
    //  }
    getMultiResume();
  }, []);

  const getMultiResume = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/get-resume-list",
      method: "GET",
      // formData,
      isDaxtra: "",
    });

    if (response?.status === 200) {
      dispatch(getUserProfile()); // to update userdetails when a  new cv has been uploaded.
      setlistOfresume(response?.data?.data);
      let temp = [];
      response?.data?.data?.map((name) => {
        temp = [
          ...temp,
          {
            filename: name?.resume_file?.name,
            daxtra_id: name?.daxtra_id,
            _id: name?._id,
          },
        ];
      });
      // setlistOfresume([]);

      setresumeNameList(temp);
    }

    //  else if (response?.status === 201) {
    //   // setResumeStatus(response?.status);
    //   setlistOfresume([]);
    // }
    else {
      setlistOfresume([]);
      setresumeNameList([]);
    }
  };

  const getDownloadPath = async (_id) => {
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/resume-download",
      method: "post",
      formData: { resume_id: _id },
      isDaxtra: "",
    });

    return response;
  };
  const resumeDownload = (_id, name) => {
    let response = getDownloadPath(_id);
    let path = "";
    response?.then((res) => {
      path = res?.data?.download_resume_path + name;
      saveAs(path, name);
    });
  };

  return (
    <>
      <div className="uploadYourResume">
        {!loader ? (
          ""
        ) : (
          <Loader data={promptWithCV} needprompt={true} timebetween={4000} />
        )}
        {GetSearchUsingResume === "" && (
          <label>
            <input
              type={GetSearchUsingResume !== "" ? "text" : "file"}
              name=""
              id=""
              hidden
              accept=".doc,.docx,.pdf"
              onChange={handelGetSearchUsingResume}
            />
            {GetSearchUsingResume === "" && (
              <>
                <div className="cont" style={{ cursor: "pointer" }}>
                  <svg
                    width="60"
                    height="44"
                    viewBox="0 0 60 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30 0C34.4638 0 38.9343 1.69605 42.3401 5.10174C45.2306 7.99221 46.8954 11.6464 47.3329 15.4142C54.4403 16.0595 60 22.0268 60 29.3023C60 37.0086 53.7527 43.2558 46.0465 43.2558H11.8605C5.28977 43.2558 0 37.966 0 31.3953C0 24.8247 5.28977 19.5349 11.8605 19.5349H12.689C12.0742 14.4039 13.7441 9.03929 17.6817 5.10174C21.0873 1.69605 25.5362 0 30 0ZM30 14.6512C29.6297 14.6498 29.3696 14.7193 29.0625 15L21.388 21.9767C20.8421 22.4667 20.7418 23.4226 21.2791 23.9607C21.7973 24.4799 22.7214 24.5424 23.2631 24.0479L28.6047 19.186V34.186C28.6047 34.9567 29.2292 35.5814 30 35.5814C30.7708 35.5814 31.3953 34.9567 31.3953 34.186V19.186L36.7369 24.0479C37.2786 24.5424 38.2286 24.5044 38.7209 23.9607C39.2132 23.4169 39.1854 22.4759 38.6119 21.9767L30.9375 15C30.7381 14.8173 30.3732 14.6581 30 14.6512Z"
                      fill="#FAAF40"
                    />
                  </svg>
                  <span>
                    Upload Your Resume
                    <br />
                    (File type: Doc, Docx, .PDF){" "}
                  </span>
                </div>
              </>
            )}
            {/* {GetSearchUsingResume !== '' &&
                        <div className="cont">
                            <span>{GetSearchUsingResume}</span>
                            <div className="d-inline-flex pt-4">
                                <div className="px-3">
                                    <a>
                                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.618 9.88H24.3613C24.822 9.88 25.2639 10.063 25.5897 10.3888L27.6121 12.4112C27.9378 12.737 28.1209 13.1788 28.1209 13.6396V26.3829C28.1209 27.3423 27.3431 28.12 26.3837 28.12H11.618C10.6586 28.12 9.88086 27.3423 9.88086 26.3829V11.6171C9.88086 10.6578 10.6586 9.88 11.618 9.88ZM13.7894 11.6171H12.0523C11.8124 11.6171 11.618 11.8116 11.618 12.0514V25.9486C11.618 26.1884 11.8124 26.3829 12.0523 26.3829H25.9494C26.1893 26.3829 26.3837 26.1884 26.3837 25.9486V13.8194C26.3837 13.7043 26.338 13.5938 26.2565 13.5124L24.4885 11.7443C24.4071 11.6629 24.2966 11.6171 24.1814 11.6171H24.0697C23.9086 11.6171 23.778 11.7477 23.778 11.9088V14.2229C23.778 15.1823 23.0003 15.96 22.0409 15.96H15.9609C15.0015 15.96 14.2237 15.1823 14.2237 14.2229V12.0514C14.2237 11.8116 14.0293 11.6171 13.7894 11.6171ZM19.0009 17.2629C21.1595 17.2629 22.9094 19.0128 22.9094 21.1714C22.9094 23.3301 21.1595 25.08 19.0009 25.08C16.8422 25.08 15.0923 23.3301 15.0923 21.1714C15.0923 19.0128 16.8422 17.2629 19.0009 17.2629ZM16.3951 14.2229H21.6066C21.8464 14.2229 22.0409 14.0284 22.0409 13.7886V12.0514C22.0409 11.8116 21.8464 11.6171 21.6066 11.6171H16.3951C16.1553 11.6171 15.9609 11.8116 15.9609 12.0514V13.7886C15.9609 14.0284 16.1553 14.2229 16.3951 14.2229ZM19.0009 19C17.8016 19 16.8294 19.9722 16.8294 21.1714C16.8294 22.3707 17.8016 23.3429 19.0009 23.3429C20.2001 23.3429 21.1723 22.3707 21.1723 21.1714C21.1723 19.9722 20.2001 19 19.0009 19Z" fill="currentColor" />
                                            <circle cx="19" cy="19" r="18.25" stroke="#D1D1D1" stroke-width="1.5" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="px-3"> 
                                    <a onClick={() => handelReUploadSearchUsignResume()}>
                                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="19" cy="19" r="18.25" stroke="#D1D1D1" stroke-width="1.5" />
                                            <path d="M25.525 26.6464C25.3297 26.8417 25.0131 26.8417 24.8179 26.6464L19.3536 21.1821C19.1583 20.9869 18.8417 20.9869 18.6464 21.1821L13.1821 26.6464C12.9869 26.8417 12.6703 26.8417 12.475 26.6464L11.3536 25.525C11.1583 25.3297 11.1583 25.0131 11.3536 24.8179L16.8179 19.3536C17.0131 19.1583 17.0131 18.8417 16.8179 18.6464L11.3536 13.1821C11.1583 12.9869 11.1583 12.6703 11.3536 12.475L12.475 11.3536C12.6703 11.1583 12.9869 11.1583 13.1821 11.3536L18.6464 16.8179C18.8417 17.0131 19.1583 17.0131 19.3536 16.8179L24.8179 11.3536C25.0131 11.1583 25.3297 11.1583 25.525 11.3536L26.6464 12.475C26.8417 12.6703 26.8417 12.9869 26.6464 13.1821L21.1821 18.6464C20.9869 18.8417 20.9869 19.1583 21.1821 19.3536L26.6464 24.8179C26.8417 25.0131 26.8417 25.3297 26.6464 25.525L25.525 26.6464Z" fill="currentColor" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    } */}
          </label>
        )}

        {GetSearchUsingResume !== "" && (
          <label>
            <div className="cont">
              <span>{GetSearchUsingResume}</span>
              <div className="d-inline-flex pt-4">
                {/* <div className="px-3">
                  <a>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.618 9.88H24.3613C24.822 9.88 25.2639 10.063 25.5897 10.3888L27.6121 12.4112C27.9378 12.737 28.1209 13.1788 28.1209 13.6396V26.3829C28.1209 27.3423 27.3431 28.12 26.3837 28.12H11.618C10.6586 28.12 9.88086 27.3423 9.88086 26.3829V11.6171C9.88086 10.6578 10.6586 9.88 11.618 9.88ZM13.7894 11.6171H12.0523C11.8124 11.6171 11.618 11.8116 11.618 12.0514V25.9486C11.618 26.1884 11.8124 26.3829 12.0523 26.3829H25.9494C26.1893 26.3829 26.3837 26.1884 26.3837 25.9486V13.8194C26.3837 13.7043 26.338 13.5938 26.2565 13.5124L24.4885 11.7443C24.4071 11.6629 24.2966 11.6171 24.1814 11.6171H24.0697C23.9086 11.6171 23.778 11.7477 23.778 11.9088V14.2229C23.778 15.1823 23.0003 15.96 22.0409 15.96H15.9609C15.0015 15.96 14.2237 15.1823 14.2237 14.2229V12.0514C14.2237 11.8116 14.0293 11.6171 13.7894 11.6171ZM19.0009 17.2629C21.1595 17.2629 22.9094 19.0128 22.9094 21.1714C22.9094 23.3301 21.1595 25.08 19.0009 25.08C16.8422 25.08 15.0923 23.3301 15.0923 21.1714C15.0923 19.0128 16.8422 17.2629 19.0009 17.2629ZM16.3951 14.2229H21.6066C21.8464 14.2229 22.0409 14.0284 22.0409 13.7886V12.0514C22.0409 11.8116 21.8464 11.6171 21.6066 11.6171H16.3951C16.1553 11.6171 15.9609 11.8116 15.9609 12.0514V13.7886C15.9609 14.0284 16.1553 14.2229 16.3951 14.2229ZM19.0009 19C17.8016 19 16.8294 19.9722 16.8294 21.1714C16.8294 22.3707 17.8016 23.3429 19.0009 23.3429C20.2001 23.3429 21.1723 22.3707 21.1723 21.1714C21.1723 19.9722 20.2001 19 19.0009 19Z"
                        fill="currentColor"
                      />
                      <circle
                        cx="19"
                        cy="19"
                        r="18.25"
                        stroke="#D1D1D1"
                        stroke-width="1.5"
                      />
                    </svg>
                  </a>
                </div> */}
                <div className="px-3">
                  <a onClick={() => handelReUploadSearchUsignResume()}>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="19"
                        cy="19"
                        r="18.25"
                        stroke="#D1D1D1"
                        stroke-width="1.5"
                      />
                      <path
                        d="M25.525 26.6464C25.3297 26.8417 25.0131 26.8417 24.8179 26.6464L19.3536 21.1821C19.1583 20.9869 18.8417 20.9869 18.6464 21.1821L13.1821 26.6464C12.9869 26.8417 12.6703 26.8417 12.475 26.6464L11.3536 25.525C11.1583 25.3297 11.1583 25.0131 11.3536 24.8179L16.8179 19.3536C17.0131 19.1583 17.0131 18.8417 16.8179 18.6464L11.3536 13.1821C11.1583 12.9869 11.1583 12.6703 11.3536 12.475L12.475 11.3536C12.6703 11.1583 12.9869 11.1583 13.1821 11.3536L18.6464 16.8179C18.8417 17.0131 19.1583 17.0131 19.3536 16.8179L24.8179 11.3536C25.0131 11.1583 25.3297 11.1583 25.525 11.3536L26.6464 12.475C26.8417 12.6703 26.8417 12.9869 26.6464 13.1821L21.1821 18.6464C20.9869 18.8417 20.9869 19.1583 21.1821 19.3536L26.6464 24.8179C26.8417 25.0131 26.8417 25.3297 26.6464 25.525L25.525 26.6464Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </label>
        )}

        <div className="searchBtn">
          <button
            className="btn shadow-none"
            onClick={() => handelSearchByResume()}
            // disabled={GetSearchUsingResume === "" && true}
            // disabled={uploadedFile ? false : true}
          >
            Search by Resume
          </button>
        </div>
        {SearchByResume.length !== 0 && (
          <div className="line">
            <hr />
          </div>
        )}

        <div className="SearchUsingResumeWrapResult">
          <div className="d-flex flex-wrap mx-n3">
            {SearchByResume.map((data, ind) => (
              <div className="col-12 col-sm-6 col-xl-3 p-3" key={ind}>
                <div className="surr">
                  <h5>
                    <span className="resumeNameDisplay">
                      {data?.resume_file?.name}
                    </span>
                  </h5>
                  <hr />
                  <div className="d-flex justify-content-around">
                    {/* <div className="px-3">
                      <a className="px-0 shadow-none ">
                        <svg
                          width="39"
                          height="38"
                          viewBox="0 0 39 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="19.3223"
                            cy="19"
                            r="18.25"
                            stroke="#D1D1D1"
                            stroke-width="1.5"
                          />
                          <path
                            d="M26.073 25.5802L22.3943 21.8971C24.2439 19.4897 24.059 16.0123 21.8394 13.8107C20.6269 12.5967 19.065 12 17.4825 12C15.9001 12 14.3382 12.5967 13.1256 13.8107C10.7211 16.2181 10.7211 20.1276 13.1256 22.535C14.3382 23.749 15.9001 24.3457 17.4825 24.3457C18.7978 24.3457 20.1131 23.9342 21.2023 23.0905L24.9016 26.7531C25.066 26.9177 25.2715 27 25.4976 27C25.7031 27 25.9292 26.9177 26.0936 26.7531C26.4018 26.4444 26.4018 25.9095 26.073 25.5802ZM17.5031 22.679C16.2906 22.679 15.1808 22.2058 14.3176 21.3621C12.5708 19.6132 12.5708 16.7531 14.3176 14.9835C15.1602 14.1399 16.2906 13.6667 17.5031 13.6667C18.7156 13.6667 19.8254 14.1399 20.6886 14.9835C21.5517 15.8272 22.0038 16.9588 22.0038 18.1728C22.0038 19.3868 21.5312 20.4979 20.6886 21.3621C19.846 22.2263 18.6951 22.679 17.5031 22.679Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div> */}
                    <div className="px-3" id="resumeList">
                      {/* Download Icon*/}{" "}
                      <Tooltip title="Download">
                        <a
                          className="px-0 shadow-none "
                          onClick={() => {
                            resumeDownload(data?._id, data?.resume_file?.name);
                          }}
                        >
                          <svg
                            width="39"
                            height="38"
                            viewBox="0 0 39 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="19.1875"
                              cy="19"
                              r="18.25"
                              stroke="#D1D1D1"
                              stroke-width="1.5"
                            />
                            <path
                              d="M12.1875 25.4702C12.1875 25.6116 12.2154 25.7516 12.2695 25.8823C12.3236 26.013 12.4029 26.1317 12.5029 26.2317C12.6029 26.3317 12.7216 26.411 12.8523 26.4651C12.983 26.5192 13.123 26.5471 13.2644 26.5471H25.1106C25.3962 26.5471 25.6701 26.4336 25.8721 26.2317C26.074 26.0297 26.1875 25.7558 26.1875 25.4702C26.1875 25.1846 26.074 24.9106 25.8721 24.7087C25.6701 24.5067 25.3962 24.3932 25.1106 24.3932H13.2644C12.9788 24.3932 12.7049 24.5067 12.5029 24.7087C12.301 24.9106 12.1875 25.1846 12.1875 25.4702Z"
                              fill="currentColor"
                            />
                            <path
                              d="M18.9397 12C18.6541 12 18.3802 12.1135 18.1782 12.3154C17.9762 12.5174 17.8628 12.7913 17.8628 13.0769V19.4889L15.1726 16.6523C14.9764 16.4447 14.7057 16.3235 14.4202 16.3154C14.1346 16.3073 13.8575 16.413 13.6498 16.6092C13.4422 16.8055 13.321 17.0761 13.3129 17.3617C13.3089 17.5031 13.3328 17.6439 13.3832 17.7761C13.4337 17.9082 13.5096 18.0292 13.6068 18.132L18.1578 22.9351C18.2585 23.0417 18.3799 23.1266 18.5145 23.1846C18.6491 23.2426 18.7942 23.2725 18.9408 23.2725C19.0874 23.2725 19.2324 23.2426 19.367 23.1846C19.5017 23.1266 19.623 23.0417 19.7237 22.9351L24.2705 18.132C24.3676 18.0292 24.4436 17.9082 24.494 17.7761C24.5444 17.6439 24.5683 17.5031 24.5643 17.3617C24.5603 17.2203 24.5285 17.0811 24.4707 16.952C24.4129 16.8228 24.3302 16.7064 24.2274 16.6092C24.1246 16.5121 24.0036 16.4361 23.8715 16.3857C23.7393 16.3353 23.5985 16.3114 23.4571 16.3154C23.3157 16.3194 23.1764 16.3512 23.0473 16.409C22.9182 16.4668 22.8018 16.5495 22.7046 16.6523L20.0166 19.4889V13.0769C20.0166 12.7913 19.9032 12.5174 19.7012 12.3154C19.4992 12.1135 19.2253 12 18.9397 12Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </Tooltip>
                    </div>
                    <div className="px-3">
                      {/* Replace Icon */}{" "}
                      <Tooltip title="Replace">
                        <a
                          className="px-0 shadow-none "
                          onClick={() => {
                            resumeReplace(
                              data?._id,
                              data?.daxtra_id,
                              uploadedFile
                            );
                          }}
                        >
                          <svg
                            className="replacesvg"
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="19"
                              cy="19"
                              r="18.25"
                              stroke="#D1D1D1"
                              stroke-width="1.5"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0697 21.5328L13.0697 17.804C13.0697 16.2542 14.3204 15.0024 15.8732 15.0024L21.4556 15.0024L21.4556 13.1361L15.8732 13.1361C13.2894 13.1361 11.2031 15.2238 11.2031 17.8038L11.2031 21.5326L13.0697 21.5328ZM26.1341 17.804L26.1341 21.5328C26.1341 23.0771 24.8756 24.3344 23.3347 24.3344L18.6686 24.3344L18.6686 26.2007L23.3347 26.2007C25.906 26.2007 28.0004 24.1082 28.0004 21.533L28.0004 17.8042L26.1341 17.804Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.0741 24.6073C16.7097 24.9717 16.7097 25.5626 17.0741 25.9271L19.8737 28.7266C20.238 29.0911 20.8289 29.0911 21.1934 28.7266C21.5578 28.3622 21.5578 27.7713 21.1934 27.4068L19.1337 25.2672L21.1934 23.1275C21.5578 22.763 21.5578 22.1722 21.1934 21.8077C20.8289 21.4432 20.238 21.4432 19.8737 21.8077L17.0741 24.6073Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20.0694 14.0691L18.0097 16.2087C17.6452 16.5732 17.6452 17.1641 18.0097 17.5285C18.3742 17.893 18.965 17.893 19.3293 17.5285L22.1289 14.729C22.4934 14.3645 22.4934 13.7736 22.1289 13.4092L19.3293 10.6096C18.965 10.2451 18.3741 10.2451 18.0097 10.6096C17.6452 10.9741 17.6452 11.5649 18.0097 11.9294L20.0694 14.0691Z"
                              fill="black"
                            />
                          </svg>

                          {/* <svg
                          width="39"
                          height="38"
                          viewBox="0 0 39 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="19.0508"
                            cy="19"
                            r="18.25"
                            stroke="#D1D1D1"
                            stroke-width="1.5"
                          />
                          <path
                            d="M24.0329 24.6464C23.8376 24.8417 23.5211 24.8417 23.3258 24.6464L19.4043 20.725C19.2091 20.5297 18.8925 20.5297 18.6972 20.725L14.7758 24.6464C14.5805 24.8417 14.2639 24.8417 14.0687 24.6464L13.4043 23.9821C13.2091 23.7869 13.2091 23.4703 13.4043 23.275L17.3258 19.3536C17.5211 19.1583 17.5211 18.8417 17.3258 18.6464L13.4043 14.725C13.2091 14.5297 13.2091 14.2131 13.4043 14.0179L14.0687 13.3536C14.2639 13.1583 14.5805 13.1583 14.7758 13.3536L18.6972 17.275C18.8925 17.4703 19.2091 17.4703 19.4043 17.275L23.3258 13.3536C23.5211 13.1583 23.8376 13.1583 24.0329 13.3536L24.6972 14.0179C24.8925 14.2131 24.8925 14.5297 24.6972 14.725L20.7758 18.6464C20.5805 18.8417 20.5805 19.1583 20.7758 19.3536L24.6972 23.275C24.8925 23.4703 24.8925 23.7869 24.6972 23.9821L24.0329 24.6464Z"
                            fill="currentColor"
                          />
                        </svg> */}
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
