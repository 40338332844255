import { React, useEffect } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { ApiHelperFunction } from "../../Helper/Helper";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { removeAll as removeJobData } from "../../Redux/Slices/SearchJobSlice";
import { removeAll as removeFavJobData } from "../../Redux/Slices/FavouriteSlice";
import { removeAll as removeAuthData } from "../../Redux/Slices/AuthSlice";
import useSignout from "../../Hooks/useSignout";
export default function Protectedroute(props) {
  const token = "undefined";

  let ProtectedCom = props.component;
  let isloggedin =
    window.localStorage.getItem("jobcupid_token") ||
    window.localStorage.getItem("google_token") ||
    window.localStorage.getItem("facebook_token");



  return isloggedin ? <ProtectedCom /> : <Redirect to="/auth/login" />;
}
