import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { ApiHelperFunction } from "../../Helper/Helper";
import moment from "moment";
import { end } from "@popperjs/core";
import { encryptData, decryptData } from "../../encrypt/Util";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function SearchFilterCard({
  locationList,
  educationList,
  jobTypeList,
  CompanyNameList,
  skillset,
  getSingleLocation,
  getSingleCompanyName,
  getSingleEducationName,
  getSinglejobtypelist,
  getSkillList,
  getSingleSkillName,
  // getDateFilter,
  setfilterDate,
}) {
  // const [jobtypelist, setjobtypelist] = useState([]);
  // const [skillset, setskillset] = useState([]);
  const [jobtype, setjobtype] = useState([]);
  const [listofLocations, setlistofLocations] = useState([]);
  const [listofCompanyName, setlistofCompanyName] = useState([]);
  const [listofEducationLevel, setlistofEducationLevel] = useState([]);
  const [listofjobtypelist, setlistofjobtypelist] = useState([]);
  const [srchKey, setsrchKey] = useState("");
  const [filters, setfilters] = useState([]);
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:focus-within .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fbaf41",
              borderWidth:"1px"
            },
            "&:hover .MuiAutocomplete-endAdornment": {
              backgroundColor: "rgb(255 255 255 / 59%)"
            },
            "&:not(:hover) .MuiAutocomplete-endAdornment": {
              // backgroundColor: "rgb(255 255 255 / 59%)"
              opacity:"0"
            }
          
            
          }
        }
      }
    }
  });

  // const getSkillList = async (srchKey,isSearched) => {
  //   let path1="/api/filter/get-skill-list"
  //   let method1="GET"
  //   let path2='/api/filter/search-skill-by-name'
  //   let method2="POST"
  //   let formData=  isSearched===true ? { skill_name: srchKey }: "";

  //   let response = await ApiHelperFunction({
  //     urlPath: isSearched===true ? path2: path1,
  //     method:  isSearched===true ? method2: method1,
  //     formData:formData ,
  //     isDaxtra: "",
  //   });
  //   let temp = [];

  //   if (response?.data?.data?.length > 0) {
  //     response?.data?.data?.map((item) => {
  //       temp = [...temp, { label: item, value: item }];
  //       // setjobtypelist([...jobtypelist,{"label":item,"value":item}])
  //     });
  //   }

  //   setskillset(temp);
  // };

  useEffect(() => {
    let sessionLocation = [];
    // let sessionLocation2 = [];
    window.scrollTo(0, document.getElementById("resultWrap").offsetTop);

    // sessionLocation2=JSON.parse(window.sessionStorage.getItem("locations2"))
    // const decryptedData = decryptData(sessionLocation2, '');
    // sessionLocation = JSON.parse(window.sessionStorage.getItem("locations"));
    let temp = [];

    sessionLocation?.map((item) => {
      temp = [...temp, { label: item, value: item }];
    });

    setlistofLocations(temp);
  }, [locationList]);

  useEffect(() => {
    window.scrollTo(0, document.getElementById("resultWrap").offsetTop);
    let sessionCompany = [];
    sessionCompany = JSON.parse(window.sessionStorage.getItem("CompanyName"));
    let temp1 = [];

    sessionCompany?.map((item) => {
      temp1 = [...temp1, { label: item, value: item }];
    });

    setlistofCompanyName(temp1);
  }, [CompanyNameList]);

  useEffect(() => {
    window.scrollTo(0, document.getElementById("resultWrap").offsetTop);
    let sessionEducation = [];
    sessionEducation = JSON.parse(
      window.sessionStorage.getItem("educationList")
    );
    let temp = [];

    sessionEducation?.map((item) => {
      temp = [...temp, { label: item, value: item }];
    });

    setlistofEducationLevel(temp);
  }, [educationList]);

  useEffect(() => {
    window.scrollTo(0, document.getElementById("resultWrap").offsetTop);
    let sessionjobtypelist = [];
    sessionjobtypelist = JSON.parse(
      window.sessionStorage.getItem("jobtypelist")
    );
    let temp = [];

    sessionjobtypelist?.map((item) => {
      temp = [...temp, { label: item, value: item }];
    });

    setlistofjobtypelist(temp);
  }, [jobTypeList]);

  const onKeyup = (e) => {
    e.preventDefault();

    setsrchKey(e.target.value);
    let timer; // Timer identifier
    const waitTime = 1000;

    if (e.target.value !== "") {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getSkillList(e.target.value);
      }, waitTime);
    }

    // let timer;              // Timer identifier
    // const waitTime = 900;
    // clearTimeout(timer);
    //   if(e.target.value!==""){
    //     // clearTimeout(timer);
    //     timer=setTimeout(() => {

    //   }, waitTime);
    // }
  };

  useEffect(() => {
    getSkillList("", false);
  }, [locationList, CompanyNameList]);

  const filterDatewise = (option) => {
    window.scrollTo(0, document.getElementById("resultWrap").offsetTop);
    let startDate = "";
    let endDate = moment().format("YYYY-MM-DD HH:mm:ss");

    if (option !== "") {
      switch (option) {
        case "LastWeek":
          startDate = moment().subtract(8, "d").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "FiffDays":
          startDate = moment().subtract(16, "d").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "LastMonth":
          startDate = moment().subtract(31, "d").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "SixMonths":
          startDate = moment().subtract(181, "d").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "OneYearAgo":
          startDate = moment().subtract(366, "d").format("YYYY-MM-DD HH:mm:ss");
          break;
      }
    }
    //  else if(option==="")
    //  {
    //    startDate="";
    //    endDate="";
    //  }

    let temp = { startDate: startDate, endDate: endDate };
    setfilterDate(temp);
    //  getDateFilter(temp)
  };

  const changeFilters = (nameofFilter) => {
    let temp = window.sessionStorage.getItem("searchfilters");

    let session_filters = JSON.parse(temp);
    return session_filters?.filters?.[`${nameofFilter}`];
  };

  return (
    <>
      <section className="filterWrap">
        <div className="row  ">
          <div className="col-sm-6 col-md-4 col-xl-2">
            <select
              name=""
              id=""
              className="form-control shadow-none"
              onChange={(e) => {
                filterDatewise(e.target.value);
              }}
            >
              <option value=" ">Date Posted</option>
              <option value="LastWeek">Last Week</option>
              <option value="FiffDays">Last 15 Days</option>
              <option value="LastMonth">Last Month</option>
              <option value="SixMonths">Last 6 Months</option>
              <option value="OneYearAgo">Last year</option>
            </select>
          </div>
          <div className="col-sm-6 col-md-4 col-xl-2">
            <select name="" id="" className="form-control shadow-none">
              <option value="1">Remote</option>
              <option value="1">List One</option>
              <option value="1">List Two</option>
              <option value="1">List Three</option>
            </select>
          </div>
          <div className="col-sm-6 col-md-4 col-xl-2">
            <select name="" id="" className="form-control shadow-none">
              <option value="1">Within 25 Kilometers</option>
              <option value="1">List One</option>
              <option value="1">List Two</option>
              <option value="1">List Three</option>
            </select>
          </div>
          <div className="col-sm-6 col-md-4 col-xl-2">
            <select name="" id="" className="form-control shadow-none">
              <option value="1">Salary Estimate</option>
              <option value="1">List One</option>
              <option value="1">List Two</option>
              <option value="1">List Three</option>
            </select>
          </div>
          <div className="col-sm-6 col-md-4 col-xl-2">
            {/* <select name="" id="" className="form-control shadow-none">
              <option value="1">Job Type List</option>
              <option value="1">List One</option>
              <option value="1">List Two</option>
              <option value="1">List Three</option>
            </select> */}
<ThemeProvider theme={theme}>
<Autocomplete
              disablePortal
              id="jobtypeList combo-box-demo"
              //        defaultValue={changeFilters('jobTypeList')}
              options={
                jobTypeList?.length > listofjobtypelist?.length
                  ? jobTypeList
                  : listofjobtypelist
              }
              onChange={(e, v) => {
                if (v?.label !== undefined) {
                  getSinglejobtypelist((v?.label).trim());
                } else {
                  getSinglejobtypelist("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Job Type List"
                  onChange={(e) => {
                  }}
                />
              )}
            />
</ThemeProvider>
          

          </div>
          <div className="col-sm-6 col-md-4 col-xl-2">
            <select name="" id="" className="form-control shadow-none">
              <option value="1">Job Language</option>
              <option value="1">List One</option>
              <option value="1">List Two</option>
              <option value="1">List Three</option>
            </select>
          </div>

          <div className="col-sm-6 col-md-4 col-xl-3">
            {/* <Select
              placeholder="Job Location"
              className="form-control shadow-none"
              options={locationList}
              onChange={(e) => {
                getSingleLocation((e?.value).trim());

              }}
            /> */}
<ThemeProvider theme={theme}>

            <Autocomplete
              disablePortal
              id="location combo-box-demo"
              // defaultValue={}
              defaultValue={changeFilters("Location")}
              options={
                locationList?.length > listofLocations?.length
                  ? locationList
                  : listofLocations
              }
              onChange={(e, v) => {
                if (v?.label !== undefined) {
                  getSingleLocation((v?.label).trim());
                } else {
                  getSingleLocation("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Job Location"
                  onChange={(e) => {
                  }}
                />
              )}
            />
            </ThemeProvider>
          </div>
          <div className="col-sm-6 col-md-4 col-xl-3 companyNameField">
          <ThemeProvider theme={theme}>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              defaultValue={changeFilters("CompanyName")}
              options={
                CompanyNameList?.length > listofCompanyName?.length
                  ? CompanyNameList
                  : listofCompanyName
              }
              onChange={(e, v) => {
                if (v?.label !== undefined) {
                  getSingleCompanyName((v?.label).trim());
                } else {
                  getSingleCompanyName("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Company Name"
                  onChange={(e) => {
                  }}
                />
              )}
            />
            </ThemeProvider>
            {/* <Select
              placeholder="Company Name"
              className="form-control shadow-none"
              options={CompanyNameList}
              onChange={(v) => {
                getSingleCompanyName((v?.value).trim());

              }}
            /> */}
          </div>

          <div className="col-sm-6 col-md-4 col-xl-2">
          <ThemeProvider theme={theme}>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={skillset}
              //        defaultValue={changeFilters('skillSet')}     //..this is for persisting the filters between refreshes
              onChange={(e, v) => {
                if (v?.label) {
                  getSingleSkillName((v?.label).trim(), true);
                } else {
                  getSingleSkillName("", false);
                  getSkillList("", false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Skill Set"
                  onChange={(e) => {
                    if (e.target.value !== "")
                      getSkillList(e.target.value.trim(), true);
                    else getSkillList("", false);
                  }}
                />
              )}
            />
            </ThemeProvider>
            {/* </div> */}
          </div>
          <div className="col-sm-6 col-md-4 col-xl-2">
            {/* <select name="" id="" className="form-control shadow-none">
              <option value="1">Education level</option>
              <option value="1">List One</option>
              <option value="1">List Two</option>
              <option value="1">List Three</option>
            </select> */}
            <ThemeProvider theme={theme}>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                educationList?.length > listofEducationLevel?.length
                  ? educationList
                  : listofEducationLevel
              }
              onChange={(e, v) => {
                if (v?.label !== undefined) {
                  getSingleEducationName((v?.label).trim());
                } else {
                  getSingleEducationName("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Education Level"
                  onChange={(e) => {
                  }}
                />
              )}
            />
            </ThemeProvider>
          </div>
        </div>
      </section>
    </>
  );
}
