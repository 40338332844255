import { Switch, Route, Link } from "react-router-dom";
import Footer from "../Share_Module/Footer/Footer";
import Header from "../Share_Module/Header/Header";
import Home_Page from "./Home_Page/Home_Page";
export default function CMS_Module() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home_Page />
        </Route>
        <Route exact path="/home">
          <Home_Page />
        </Route>
        <Route path="/*" >
          <div className="min-vh-100 text-center d-flex align-items-center justify-content-center flex-column">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
              width="160"
            >
              <title>404-browser</title>
              <g id="_404-browser" data-name="404-browser">
                <g id="browser">
                  <path
                    d="M4,50H48a2,2,0,0,0,2-2V12H2V48A2,2,0,0,0,4,50Z"
                    fill="#eae8e8"
                  />
                  <path
                    d="M48,2H4A2,2,0,0,0,2,4v8H50V4A2,2,0,0,0,48,2Z"
                    fill="#40495a"
                  />
                  <rect x="6" y="6" width="6" height="2" fill="#748191" />
                  <rect x="16" y="6" width="30" height="2" fill="#748191" />
                </g>
                <rect x="4" y="14" width="44" height="34" fill="#fff" />
                <rect x="28" y="17" width="18" height="2" fill="#ccc" />
                <rect x="6" y="17" width="18" height="2" fill="#ccc" />
                <rect x="25" y="17" width="2" height="2" fill="#777" />
                <rect x="29" y="43" width="18" height="2" fill="#ccc" />
                <rect x="6" y="43" width="18" height="2" fill="#ccc" />
                <rect x="25" y="43" width="2" height="2" fill="#777" />
                <path
                  d="M19.84,31.28H21v1.55H19.84V35H17.78V32.83H13.5v-1.2L17.76,25h2.08Zm-4.47,0h2.41V27.64h0l-.21.45Z"
                  fill="#ff6e40"
                />
                <path
                  d="M29.75,31.34a3.52,3.52,0,0,1-1,2.68A3.77,3.77,0,0,1,26,35a3.81,3.81,0,0,1-2.73-1,3.52,3.52,0,0,1-1-2.68V28.67a3.54,3.54,0,0,1,1-2.68A3.77,3.77,0,0,1,26,25a3.79,3.79,0,0,1,2.72,1,3.53,3.53,0,0,1,1,2.68Zm-2.22-2.88A2.28,2.28,0,0,0,27.13,27a1.5,1.5,0,0,0-2.26,0,2.3,2.3,0,0,0-.4,1.45v3.06a2.32,2.32,0,0,0,.4,1.46A1.37,1.37,0,0,0,26,33.5,1.35,1.35,0,0,0,27.13,33a2.34,2.34,0,0,0,.4-1.46Z"
                  fill="#ff6e40"
                />
                <path
                  d="M37.34,31.28H38.5v1.55H37.34V35H35.28V32.83H31v-1.2L35.26,25h2.08Zm-4.47,0h2.41V27.64h0l-.21.45Z"
                  fill="#ff6e40"
                />
              </g>
            </svg>
            <br />
            <h1>
              Page Not Found. Go{" "}
              <Link className="text-color1" to="/home">
                Home
              </Link>
            </h1>
          </div>
        </Route>

      </Switch>
      <Footer />
    </>
  );
}
