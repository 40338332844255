import InnerBanner from "../../Share_Module/InnerBanner/InnerBanner";
import { useEffect ,useState} from "react";
import axios from "axios";
import SearchJobWidget from "../../Share_Module/SearchJobWidget/SearchJobWidget";
import "./Home_Page.scss";
import { Link, useHistory } from "react-router-dom";
import NewsletterWidget from "../../Share_Module/NewsletterWidget/NewsletterWidget";
import { ApiHelperFunction } from "../../Helper/Helper";
import moment from "moment";
export default function Home_Page() {
  const history = useHistory();
const [companySet,setcompanySet]=useState([]);

  const getCompanySet = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/oncampus/event-list",
      method: "get",
      isDaxtra: "",
    });


    if (response?.data?.status === 200) {
      if (response?.data?.data?.length > 0) {
       
        setcompanySet(response?.data?.data);

      } 
      // else {
      //   // setnoEventMsg(response?.data?.message);
      //   // setnoEventMsg("There is no Event");
      //   // window.localStorage.setItem("isLoggedIn", false);
      //   // history.push("/auth/login");
      // }
      // //   setTabOptions(response?.data?.data)
    } else if (response?.data?.status === 500) {
      // window.localStorage.setItem("isLoggedIn", false);
      // history.push("/auth/login");
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
if(companySet !==undefined || companySet !== null ){
  if(companySet?.length ===0){
 getCompanySet();
  }
}
 
return () => {
  // clear search results when unmounting; //closed .
  // window.sessionStorage.clear();
};
  
}, []);


let user_id=window.localStorage.getItem("user_id");
  return (
    <>
    {user_id ?<>
    {/* <br/> <br/> <br/> <br/> <br/> <br/> */}
    <div className="blankDiv"></div>
    </>
    
  :
  <InnerBanner /> 
  }
      <div className="pageWrapper">
      <SearchJobWidget event_list={companySet}/>
      </div>

{/* recommendedJobSection  & NewsletterWidget is turned off until dynamically implemented.  */}
      {/* <section className="recommendedJobSection">
        <div className="container-xl">
          <h3>
            Recommended <span>jobs for you</span>
          </h3>
          <div className="row">
            {[0, 1, 2, 3, 4, 5].map((data, ind) => (
              <div className="col-sm-6 col-xl-4">
                <div className="card">
                  <h4>Product Development Engineer 1</h4>
                  <h5>ADVANCEDMICRODEVICESINC</h5>
                  <div className="d-flex">
                    <svg
                      width="18"
                      height="21"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75 9C5.75 7.20507 7.20507 5.75 9 5.75C10.7949 5.75 12.25 7.20507 12.25 9C12.25 10.7949 10.7949 12.25 9 12.25C7.20507 12.25 5.75 10.7949 5.75 9Z"
                        fill="#FBAF41"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.773543 7.87739C1.11718 3.70845 4.60097 0.5 8.78406 0.5H9.21599C13.3991 0.5 16.8829 3.70845 17.2265 7.87739C17.4115 10.122 16.7182 12.3508 15.2925 14.0943L10.4995 19.9561C9.72449 20.9039 8.27556 20.9039 7.50059 19.9561L2.70752 14.0943C1.28187 12.3508 0.588525 10.122 0.773543 7.87739ZM9 4.25C6.37665 4.25 4.25 6.37665 4.25 9C4.25 11.6234 6.37665 13.75 9 13.75C11.6234 13.75 13.75 11.6234 13.75 9C13.75 6.37665 11.6234 4.25 9 4.25Z"
                        fill="#FBAF41"
                      />
                    </svg>

                    <span className="h5">Singapore, Singapore, SG</span>
                  </div>
                  <div className="h6">Financial Performance: 80%</div>
                  <div className="h6">Workplace Satisfaction: 90%</div>
                  <p>Today, 17:38:15</p>
                  <div className="link">
                    <Link className="btn">Apply Now</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="allBtn">
            <button className="btn">All latest (50+)</button>
          </div>
        </div>
      </section> */}

      {/* <NewsletterWidget /> */}
    </>
  );
}
