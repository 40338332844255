import { Switch, Route, Link, NavLink, useHistory } from "react-router-dom";
import Update_Profile from "../Cms_Module/Update_Profile/Update_Profile";
import Footer from "../Share_Module/Footer/Footer";
import Header from "../Share_Module/Header/Header";
import "./DashboardModule.scss";
import DashboardPage from "./DashboardPage/DashboardPage";
import MyWalletPage from "./My-Wallet-page/MyWalletPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import ResumesPage from "./ResumesPage/ResumesPage";
import { useState, useEffect } from "react";
import { ApiHelperFunction } from "../Helper/Helper";
import MyJobs from "./JobsPage/MyJobs";
import  RecommendedJobs from "./RecommendedJobs/RecommendedJobs"
export default function DashboardModule() {
  const history = useHistory();
  const [openMobileMenu,setopenMobileMenu]=useState("");
  const getProfile = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/user/viewUserDetails",
      method: "GET",
      isDaxtra: "",
    });

    if (
      // response?.data?.status === 500 ||
      response?.data?.message === "There was a problem finding the user."
    ) {
      // history.push("/auth/login");
      // console.log("go back to login");
    }
  };
  useEffect(() => {
    getProfile();

    if (
      window.localStorage.getItem("google_token") ||
      window.localStorage.getItem("facebook_token") ||
      window.localStorage.getItem("jobcupid_token")
    ) {
      // setTimeout(() => {
      //   history.push("/auth/login");
      //   window.localStorage.clear();
      // }, 86400000);
    }
  }, []);
  const [profileImage, setprofileImage] = useState();

  const onProfilePicChange = (arg) => {
    setprofileImage(arg);
  };

  return (
    <>
      <Header profileImage={profileImage} />
      <div className="pageWrapper">
      <div className="DashboardModule">
        <div className="dashboardMenu">
          <div className="container">
            <div className="nav-menu" onClick={()=>{
              if(openMobileMenu==="mobile-menu")
              {
                setopenMobileMenu("");
              }
              else{
                setopenMobileMenu("mobile-menu");
              }
              }}>
              <img src="/img/mobile-menu.svg"/>
            </div>
            <nav className={`mb-0 ${openMobileMenu}`}>
              <ul className="list-unstyled mb-0 d-flex display-block new-design-all-dakhboard-menu">
                <li onClick={()=>{ setopenMobileMenu("");}}>
                  <NavLink to="/dashboard" activeClassName="active" exact>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.4"
                        y="0.4"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                      <rect
                        x="6.99961"
                        y="0.4"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                      <rect
                        x="0.4"
                        y="6.99998"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                      <rect
                        x="6.99961"
                        y="6.99998"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                    </svg>

                    <span>Dashboard</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/dashboard/profile"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.10694 7.18139C9.10297 6.60529 9.77606 5.51312 9.77606 4.25828C9.77606 2.40595 8.31174 0.9 6.5 0.9C4.68827 0.9 3.22394 2.40595 3.22394 4.25828C3.22394 5.51312 3.89703 6.60529 4.89306 7.18139C2.58474 7.89161 0.9 10.0895 0.9 12.6821V13V13.1H1H12H12.1V13V12.6821C12.1 10.0895 10.4153 7.89161 8.10694 7.18139ZM4.04366 4.25828C4.04366 2.86504 5.14807 1.73576 6.5 1.73576C7.85193 1.73576 8.95634 2.86504 8.95634 4.25828C8.95634 5.65151 7.85193 6.7808 6.5 6.7808C5.14807 6.7808 4.04366 5.65151 4.04366 4.25828ZM11.2632 12.2642H1.73693C1.94444 9.75168 4.00358 7.7755 6.5 7.7755C8.99642 7.7755 11.0556 9.75168 11.2632 12.2642Z"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-width="0.2"
                      />
                    </svg>
                    <span>Profile</span>
                  </NavLink>
                </li> */}
                <li  onClick={()=>{ setopenMobileMenu("");}}>
                  <NavLink
                    to="/dashboard/update-profile"
                    activeClassName="active"
                    exact
                  >
                    {/* <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4814 6.63758H13.0764V4.17806C13.0764 4.03862 12.9608 3.92574 12.8174 3.92574H12.3359V2.26327C12.3359 2.25491 12.3319 2.24753 12.3307 2.23966C12.3334 2.21433 12.3359 2.18949 12.3305 2.16367C12.3012 2.02693 12.1637 1.93987 12.0243 1.96766L10.9911 2.17646V0.252321C10.9911 0.135506 10.9078 0.0413158 10.7973 0.0127882C10.7887 0.010083 10.7801 0.00909934 10.771 0.00762378C10.7575 0.00565636 10.7457 0 10.7322 0C10.7246 0 10.7182 0.00368892 10.7108 0.00467263C10.7022 0.00467263 10.6943 0.00147557 10.6852 0.00319706L2.32195 1.23013C1.36333 1.37646 0.718999 2.01882 0.718999 2.82866L0.719245 10.313C0.718016 10.3535 0.691947 11.3055 1.25979 11.8785C1.53523 12.1554 1.89625 12.2964 2.33277 12.2964H13.0764V9.84226H13.4814C13.7667 9.84226 13.9991 9.61576 13.9991 9.33712V7.14222C13.9991 6.86383 13.7667 6.63758 13.4814 6.63758ZM4.88747 3.92574L11.8179 2.52518V3.92574H4.88747ZM2.4004 1.72936L10.4732 0.544729V2.28171L2.3436 3.92402C1.73197 3.91418 1.23717 3.42749 1.23717 2.8289C1.23717 2.14793 1.86453 1.81076 2.4004 1.72936ZM12.5584 11.7915H2.33277C2.03865 11.7915 1.81018 11.7052 1.63385 11.5283C1.21872 11.1117 1.23692 10.3373 1.23741 10.3213V3.99263C1.5313 4.2634 1.92675 4.43063 2.36179 4.43063C2.36204 4.43063 2.36204 4.43063 2.36204 4.43063H12.5584V6.63758H12.2318C11.9463 6.63758 11.7142 6.86383 11.7142 7.14247V9.33737C11.7142 9.616 11.9463 9.8425 12.2318 9.8425H12.5584V11.7915ZM13.4804 9.33737H12.2318V7.14247H13.4814L13.4804 9.33737Z"
                        fill="currentColor"
                      />
                      <path
                        d="M4.08733 6.59998H8.14956C8.29294 6.59998 8.40852 6.48686 8.40852 6.34766C8.40852 6.20797 8.29294 6.09509 8.14956 6.09509H4.08733C3.94396 6.09509 3.82812 6.20797 3.82812 6.34766C3.82837 6.48686 3.94396 6.59998 4.08733 6.59998Z"
                        fill="currentColor"
                      />
                      <path
                        d="M4.08733 8.40857H8.14956C8.29294 8.40857 8.40852 8.29545 8.40852 8.15625C8.40852 8.01681 8.29294 7.90393 8.14956 7.90393H4.08733C3.94396 7.90393 3.82812 8.01681 3.82812 8.15625C3.82837 8.29545 3.94396 8.40857 4.08733 8.40857Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.17563 9.88037H4.08733C3.94396 9.88037 3.82812 9.99301 3.82812 10.1324C3.82812 10.2721 3.94396 10.3848 4.08733 10.3848H8.17563C8.31876 10.3848 8.43459 10.2721 8.43459 10.1324C8.43459 9.99301 8.31876 9.88037 8.17563 9.88037Z"
                        fill="currentColor"
                      />
                    </svg> */}
                    <svg
                      width="16"
                      height="28"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.00008 0C5.29894 0 3.10925 2.18974 3.10925 4.8909C3.10925 6.57112 3.9565 8.05345 5.24707 8.93396C2.25512 10.03 0.0999403 12.8612 0.000301005 16.2091C-0.0106569 16.5772 0.278928 16.8846 0.647106 16.8956C1.01529 16.9065 1.32264 16.6169 1.33359 16.2488C1.44043 12.6592 4.38431 9.78181 8 9.78181C11.6157 9.78181 14.5596 12.6592 14.6664 16.2488C14.6774 16.6169 14.9847 16.9065 15.3529 16.8956C15.7211 16.8846 16.0107 16.5772 15.9997 16.2091C15.9001 12.8613 13.7449 10.03 10.753 8.934C12.0436 8.0535 12.8909 6.57115 12.8909 4.8909C12.8909 2.18974 10.7012 0 8.00008 0ZM4.44313 4.8909C4.44313 2.92641 6.03564 1.33388 8.00008 1.33388C9.96452 1.33388 11.557 2.92641 11.557 4.8909C11.557 6.8554 9.96452 8.44793 8.00008 8.44793C6.03564 8.44793 4.44313 6.8554 4.44313 4.8909Z"
                        fill="#404041"
                      />
                    </svg>
                    <span>Update Profile</span>
                  </NavLink>
                </li>
                <li  onClick={()=>{ setopenMobileMenu("");}}>
                  <NavLink
                    to="/dashboard/my-wallet"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4814 6.63758H13.0764V4.17806C13.0764 4.03862 12.9608 3.92574 12.8174 3.92574H12.3359V2.26327C12.3359 2.25491 12.3319 2.24753 12.3307 2.23966C12.3334 2.21433 12.3359 2.18949 12.3305 2.16367C12.3012 2.02693 12.1637 1.93987 12.0243 1.96766L10.9911 2.17646V0.252321C10.9911 0.135506 10.9078 0.0413158 10.7973 0.0127882C10.7887 0.010083 10.7801 0.00909934 10.771 0.00762378C10.7575 0.00565636 10.7457 0 10.7322 0C10.7246 0 10.7182 0.00368892 10.7108 0.00467263C10.7022 0.00467263 10.6943 0.00147557 10.6852 0.00319706L2.32195 1.23013C1.36333 1.37646 0.718999 2.01882 0.718999 2.82866L0.719245 10.313C0.718016 10.3535 0.691947 11.3055 1.25979 11.8785C1.53523 12.1554 1.89625 12.2964 2.33277 12.2964H13.0764V9.84226H13.4814C13.7667 9.84226 13.9991 9.61576 13.9991 9.33712V7.14222C13.9991 6.86383 13.7667 6.63758 13.4814 6.63758ZM4.88747 3.92574L11.8179 2.52518V3.92574H4.88747ZM2.4004 1.72936L10.4732 0.544729V2.28171L2.3436 3.92402C1.73197 3.91418 1.23717 3.42749 1.23717 2.8289C1.23717 2.14793 1.86453 1.81076 2.4004 1.72936ZM12.5584 11.7915H2.33277C2.03865 11.7915 1.81018 11.7052 1.63385 11.5283C1.21872 11.1117 1.23692 10.3373 1.23741 10.3213V3.99263C1.5313 4.2634 1.92675 4.43063 2.36179 4.43063C2.36204 4.43063 2.36204 4.43063 2.36204 4.43063H12.5584V6.63758H12.2318C11.9463 6.63758 11.7142 6.86383 11.7142 7.14247V9.33737C11.7142 9.616 11.9463 9.8425 12.2318 9.8425H12.5584V11.7915ZM13.4804 9.33737H12.2318V7.14247H13.4814L13.4804 9.33737Z"
                        fill="currentColor"
                      />
                      <path
                        d="M4.08733 6.59998H8.14956C8.29294 6.59998 8.40852 6.48686 8.40852 6.34766C8.40852 6.20797 8.29294 6.09509 8.14956 6.09509H4.08733C3.94396 6.09509 3.82812 6.20797 3.82812 6.34766C3.82837 6.48686 3.94396 6.59998 4.08733 6.59998Z"
                        fill="currentColor"
                      />
                      <path
                        d="M4.08733 8.40857H8.14956C8.29294 8.40857 8.40852 8.29545 8.40852 8.15625C8.40852 8.01681 8.29294 7.90393 8.14956 7.90393H4.08733C3.94396 7.90393 3.82812 8.01681 3.82812 8.15625C3.82837 8.29545 3.94396 8.40857 4.08733 8.40857Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.17563 9.88037H4.08733C3.94396 9.88037 3.82812 9.99301 3.82812 10.1324C3.82812 10.2721 3.94396 10.3848 4.08733 10.3848H8.17563C8.31876 10.3848 8.43459 10.2721 8.43459 10.1324C8.43459 9.99301 8.31876 9.88037 8.17563 9.88037Z"
                        fill="currentColor"
                      />
                    </svg>

                    <span>My Subscriptions</span>
                  </NavLink>
                </li>
                <li  onClick={()=>{ setopenMobileMenu("");}}>
                  <NavLink
                    to="/dashboard/resumes"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7454 0H0.330849C0.143847 0 0 0.143847 0 0.330849V13.3203C0 13.5073 0.143847 13.6511 0.330849 13.6511H10.731C10.918 13.6511 11.0619 13.5073 11.0619 13.3203V0.330849C11.0763 0.143847 10.918 0 10.7454 0ZM10.4002 12.9894H0.676083V0.676083H10.4002V12.9894Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.21366 3.32295H7.65213C7.83914 3.32295 7.98298 3.17911 7.98298 2.9921C7.98298 2.8051 7.83914 2.66125 7.65213 2.66125H6.21366C6.02666 2.66125 5.88281 2.8051 5.88281 2.9921C5.88281 3.17911 6.02666 3.32295 6.21366 3.32295Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.21366 4.86214H8.65907C8.84607 4.86214 8.98992 4.71829 8.98992 4.53129C8.98992 4.34429 8.84607 4.20044 8.65907 4.20044H6.21366C6.02666 4.20044 5.88281 4.34429 5.88281 4.53129C5.88281 4.70391 6.02666 4.86214 6.21366 4.86214Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 7.94038H8.65976C8.84676 7.94038 8.99061 7.79654 8.99061 7.60954C8.99061 7.42253 8.84676 7.27869 8.65976 7.27869H2.41679C2.22978 7.27869 2.08594 7.42253 2.08594 7.60954C2.08594 7.78215 2.22978 7.94038 2.41679 7.94038Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 9.47957H8.65976C8.84676 9.47957 8.99061 9.33572 8.99061 9.14872C8.99061 8.96172 8.84676 8.81787 8.65976 8.81787H2.41679C2.22978 8.81787 2.08594 8.96172 2.08594 9.14872C2.08594 9.33572 2.22978 9.47957 2.41679 9.47957Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 11.0188H6.97675C7.16375 11.0188 7.3076 10.8749 7.3076 10.6879C7.3076 10.5009 7.16375 10.3571 6.97675 10.3571H2.41679C2.22978 10.3571 2.08594 10.5009 2.08594 10.6879C2.08594 10.8749 2.22978 11.0188 2.41679 11.0188Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 5.82588H4.58888C4.77588 5.82588 4.91973 5.68203 4.91973 5.49503V2.9777C4.91973 2.7907 4.77588 2.64685 4.58888 2.64685H2.41679C2.22978 2.64685 2.08594 2.7907 2.08594 2.9777V5.49503C2.08594 5.68203 2.22978 5.82588 2.41679 5.82588ZM2.74764 3.32293H4.24365V5.16418H2.74764V3.32293Z"
                        fill="currentColor"
                      />
                    </svg>

                    <span>My Resumes</span>
                    
                  </NavLink>
                </li>
                <li  onClick={()=>{ setopenMobileMenu("");}}>
                  <NavLink
                    to="/dashboard/my-job"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7454 0H0.330849C0.143847 0 0 0.143847 0 0.330849V13.3203C0 13.5073 0.143847 13.6511 0.330849 13.6511H10.731C10.918 13.6511 11.0619 13.5073 11.0619 13.3203V0.330849C11.0763 0.143847 10.918 0 10.7454 0ZM10.4002 12.9894H0.676083V0.676083H10.4002V12.9894Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.21366 3.32295H7.65213C7.83914 3.32295 7.98298 3.17911 7.98298 2.9921C7.98298 2.8051 7.83914 2.66125 7.65213 2.66125H6.21366C6.02666 2.66125 5.88281 2.8051 5.88281 2.9921C5.88281 3.17911 6.02666 3.32295 6.21366 3.32295Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.21366 4.86214H8.65907C8.84607 4.86214 8.98992 4.71829 8.98992 4.53129C8.98992 4.34429 8.84607 4.20044 8.65907 4.20044H6.21366C6.02666 4.20044 5.88281 4.34429 5.88281 4.53129C5.88281 4.70391 6.02666 4.86214 6.21366 4.86214Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 7.94038H8.65976C8.84676 7.94038 8.99061 7.79654 8.99061 7.60954C8.99061 7.42253 8.84676 7.27869 8.65976 7.27869H2.41679C2.22978 7.27869 2.08594 7.42253 2.08594 7.60954C2.08594 7.78215 2.22978 7.94038 2.41679 7.94038Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 9.47957H8.65976C8.84676 9.47957 8.99061 9.33572 8.99061 9.14872C8.99061 8.96172 8.84676 8.81787 8.65976 8.81787H2.41679C2.22978 8.81787 2.08594 8.96172 2.08594 9.14872C2.08594 9.33572 2.22978 9.47957 2.41679 9.47957Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 11.0188H6.97675C7.16375 11.0188 7.3076 10.8749 7.3076 10.6879C7.3076 10.5009 7.16375 10.3571 6.97675 10.3571H2.41679C2.22978 10.3571 2.08594 10.5009 2.08594 10.6879C2.08594 10.8749 2.22978 11.0188 2.41679 11.0188Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 5.82588H4.58888C4.77588 5.82588 4.91973 5.68203 4.91973 5.49503V2.9777C4.91973 2.7907 4.77588 2.64685 4.58888 2.64685H2.41679C2.22978 2.64685 2.08594 2.7907 2.08594 2.9777V5.49503C2.08594 5.68203 2.22978 5.82588 2.41679 5.82588ZM2.74764 3.32293H4.24365V5.16418H2.74764V3.32293Z"
                        fill="currentColor"
                      />
                    </svg>

                    <span>My Favourite Jobs</span>
                    
                  </NavLink>
                </li>
                <li  onClick={()=>{ setopenMobileMenu("");}}>
                  <NavLink
                    to="/dashboard/recommended-jobs"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7454 0H0.330849C0.143847 0 0 0.143847 0 0.330849V13.3203C0 13.5073 0.143847 13.6511 0.330849 13.6511H10.731C10.918 13.6511 11.0619 13.5073 11.0619 13.3203V0.330849C11.0763 0.143847 10.918 0 10.7454 0ZM10.4002 12.9894H0.676083V0.676083H10.4002V12.9894Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.21366 3.32295H7.65213C7.83914 3.32295 7.98298 3.17911 7.98298 2.9921C7.98298 2.8051 7.83914 2.66125 7.65213 2.66125H6.21366C6.02666 2.66125 5.88281 2.8051 5.88281 2.9921C5.88281 3.17911 6.02666 3.32295 6.21366 3.32295Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.21366 4.86214H8.65907C8.84607 4.86214 8.98992 4.71829 8.98992 4.53129C8.98992 4.34429 8.84607 4.20044 8.65907 4.20044H6.21366C6.02666 4.20044 5.88281 4.34429 5.88281 4.53129C5.88281 4.70391 6.02666 4.86214 6.21366 4.86214Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 7.94038H8.65976C8.84676 7.94038 8.99061 7.79654 8.99061 7.60954C8.99061 7.42253 8.84676 7.27869 8.65976 7.27869H2.41679C2.22978 7.27869 2.08594 7.42253 2.08594 7.60954C2.08594 7.78215 2.22978 7.94038 2.41679 7.94038Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 9.47957H8.65976C8.84676 9.47957 8.99061 9.33572 8.99061 9.14872C8.99061 8.96172 8.84676 8.81787 8.65976 8.81787H2.41679C2.22978 8.81787 2.08594 8.96172 2.08594 9.14872C2.08594 9.33572 2.22978 9.47957 2.41679 9.47957Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 11.0188H6.97675C7.16375 11.0188 7.3076 10.8749 7.3076 10.6879C7.3076 10.5009 7.16375 10.3571 6.97675 10.3571H2.41679C2.22978 10.3571 2.08594 10.5009 2.08594 10.6879C2.08594 10.8749 2.22978 11.0188 2.41679 11.0188Z"
                        fill="currentColor"
                      />
                      <path
                        d="M2.41679 5.82588H4.58888C4.77588 5.82588 4.91973 5.68203 4.91973 5.49503V2.9777C4.91973 2.7907 4.77588 2.64685 4.58888 2.64685H2.41679C2.22978 2.64685 2.08594 2.7907 2.08594 2.9777V5.49503C2.08594 5.68203 2.22978 5.82588 2.41679 5.82588ZM2.74764 3.32293H4.24365V5.16418H2.74764V3.32293Z"
                        fill="currentColor"
                      />
                    </svg>

                    <span>Recommended Jobs</span>
                    
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="pages">
        <Switch>
          <Route path="/dashboard/" exact>
            <DashboardPage />
          </Route>
          <Route path="/dashboard/update-profile/" exact>
            <Update_Profile parentcallBack={onProfilePicChange} />
          </Route>
          {/* <Route path="/dashboard/profile/" exact>
            <ProfilePage />
          </Route> */}
          <Route path="/dashboard/resumes/" exact>
            <ResumesPage />
          </Route>
          <Route path="/dashboard/my-wallet/" exact>
            <MyWalletPage />
          </Route>
          <Route path="/dashboard/my-job/" exact>
            <MyJobs />
          </Route>
          <Route path="/dashboard/recommended-jobs/" exact>
            <RecommendedJobs />
          </Route>
        </Switch>
      </div>
      </div>
      <Footer />
    </>
  );
}
