import { useState, useEffect } from "react";
import { ApiHelperFunction } from "../../../Helper/Helper";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { removeAll as removeJobData } from "../../../Redux/Slices/SearchJobSlice";
import { removeAll as removeFavJobData } from "../../../Redux/Slices/FavouriteSlice";
import { removeAll as removeAuthData } from "../../../Redux/Slices/AuthSlice";
const useSignout = (message) => {
    const history=useHistory();
    const dispatch=useDispatch();
    const a =   window.localStorage.getItem("google_token") ||
    window.localStorage.getItem("facebook_token") ||
    window.localStorage.getItem("jobcupid_token");
    const Signout = async(e) => {
        // e.preventDefault();
        let path = "";
    
        if (a !== "") {
            let response = await ApiHelperFunction({
              urlPath: "/api/user/logout",
              method: "post",
              headers: {
                "x-access-token": a,
                "Content-Type": "application/json",
              },
              isDaxtra: "",
            });
            if (response?.data?.status===200){
              // window.localStorage.removeItem("token");
              // window.localStorage.clear();
              // window.sessionStorage.clear();
              // dispatch(removeJobData());//clear the previous resume ID from
              // dispatch(removeFavJobData());
              // dispatch(removeAuthData())
              clearAllStorage();
              history.push("/auth/login");
              toast(message, {
                hideProgressBar: true,
                autoClose: 2500,
              });
            //   if (type === "manual") {
            //     toast("Logged out successfully.", {
            //       hideProgressBar: true,
            //       autoClose: 2500,
            //     });
            //   } else if (type === "auto") {
              
            //   }
      
              // let formData = new FormData();
            }
          }
  
      };
    
      const clearAllStorage = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
    
        dispatch(removeJobData()); //clear the previous resume ID from
        dispatch(removeFavJobData());
        dispatch(removeAuthData());
      };

  const [data, setData] = useState(null);



  return ()=>{
    Signout();
  };
};

export default useSignout;