import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../Helper/Helper";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
export default function Footer() {
  const [data, setData] = useState([]);
  // const [data1, setData1] = useState([]);
  // const [data2, setData2] = useState([]);
  // const [data3, setData3] = useState([]);
  // const [data4, setData4] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: API_URL + "/api/staticContent/staticContent-list",
    };

    axios(config)
      .then(function (response) {
        // let abc = response.data.data.splice(12, 4);
        setData(response.data.data || []);
        // setData1(abc[0] || []);
        // setData2(abc[1] || []);
        // setData3(abc[2] || []);
        // setData4(abc[3] || []);
      })
      .catch(function (error) {
      });
  }, []);

  return (
    <footer>
      <div className="container-xl">
        <div className="row">
          <div className="col-lg logo ">
            <Link to="/">
              <img src="/img/logoFooter.png" alt="" />
            </Link>
            <p>© 2020 JobCupid, Inc. All rights reserved.</p>
            <ul className="list-unstyled tpsNav d-flex">
              <li>
                <Link to="/">Terms</Link>
              </li>
              <li>|</li>
              <li>
                <Link to="">Privacy</Link>
              </li>
              <li>|</li>
              <li>
                <Link to="">Site Map</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-auto">
            <h5>Contact Us</h5>
            <p>
              Street, City,
              <br />
              State ZipCode
            </p>

            <p>
              <a href="mailto:admin@jobcupid.com">Email: admin@jobcupid.com</a>
            </p>
            <p>
              <a href="tel:(111) 111-1111">Phone: (111) 111-1111</a>
            </p>
          </div>
          <div className="col-md-3 col-lg-auto">
            <h5>Info</h5>
            <ul className="list-unstyled fNav">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-auto">
            <h5>Discover</h5>
            <ul className="list-unstyled fNav">
              <li>
                <Link to="/how-it-works">How it works?</Link>
              </li>
              <li>
                <Link to="/legal-notice">Legal Notice</Link>
              </li>
              <li>
                <NavLink to="/privacy_policy" activeClassName="active">
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/terms_and_condition" activeClassName="active">
                  Terms and Conditions
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-auto">
            <h5>Support</h5>
            <ul className="list-unstyled fNav">
              <li>
                <NavLink to="/help" activeClassName="active">
                  Help
                </NavLink>
              </li>
              <li>
                <NavLink to="/auth/login" activeClassName="active">
                  Login
                </NavLink>
              </li>
              <li>
                <NavLink to="/auth/register" activeClassName="active">
                  Registration
                </NavLink>
              </li>
              <li>
                <NavLink to="/support" activeClassName="active">
                  Support
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
