import { useEffect, useState } from "react";

import "./EmployerJobPage.scss";

export default function EmployerJobPage() {
  const [ChooseTabBox, setChooseTabBox] = useState("1");
  const [NumberOfEmployeesNeeded, setNumberOfEmployeesNeeded] = useState(2);
  useEffect(() => {}, []);
  const handelInc = () => {
    setNumberOfEmployeesNeeded(NumberOfEmployeesNeeded + 1);
  };
  const handelDec = () => {
    if (NumberOfEmployeesNeeded > 1) {
      setNumberOfEmployeesNeeded(NumberOfEmployeesNeeded - 1);
    }
    ("0" + 4).slice(-2);
  };

  const handelClickTab = (e) => {
    setChooseTabBox(e);
  };
  return (
    <>
      <section className="EmployerJobPage">
        <div className="container">
          <div className="text-center pageTitle">Add new job</div>

          <div className="chooseLine">
            <div className="row justify-content-between">
              <div className="col-auto">
                <div
                  className={`tabLink ${ChooseTabBox === "1" && "active"}`}
                  onClick={() => handelClickTab("1")}
                >
                  <div className="figure">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.4062 12.375H11.9896V6.46729H10.4062V12.375Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.5473 4.35432C10.3907 4.50719 10.3125 4.70194 10.3125 4.93853C10.3125 5.17877 10.3926 5.37533 10.5527 5.52821C10.7165 5.68109 10.9313 5.75753 11.197 5.75753C11.4628 5.75753 11.6757 5.68109 11.8359 5.52821C11.9996 5.37533 12.0815 5.17877 12.0815 4.93853C12.0815 4.70194 12.0015 4.50719 11.8413 4.35432C11.6811 4.20144 11.4664 4.125 11.197 4.125C10.924 4.125 10.7075 4.20144 10.5473 4.35432Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.0625 0C0.923416 0 0 0.923409 0 2.0625V14.4375C0 15.5766 0.923416 16.5 2.0625 16.5H7.18149L10.417 21.6769C10.5426 21.8779 10.7629 22 11 22C11.2371 22 11.4574 21.8779 11.583 21.6769L14.8185 16.5H19.9375C21.0766 16.5 22 15.5766 22 14.4375V2.0625C22 0.923416 21.0766 0 19.9375 0H2.0625ZM1.375 2.0625C1.375 1.68281 1.6828 1.375 2.0625 1.375H19.9375C20.3172 1.375 20.625 1.68281 20.625 2.0625V14.4375C20.625 14.8172 20.3172 15.125 19.9375 15.125H14.4375C14.2004 15.125 13.9801 15.2471 13.8545 15.4481L11 20.0153L8.1455 15.4481C8.01989 15.2471 7.79955 15.125 7.5625 15.125H2.0625C1.68281 15.125 1.375 14.8172 1.375 14.4375V2.0625Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <b>General info</b>
                </div>
              </div>
              <div className="col-auto">
                <div
                  className={`tabLink ${ChooseTabBox === "2" && "active"}`}
                  onClick={() => handelClickTab("2")}
                >
                  <div className="figure">
                    <svg
                      width="16"
                      height="29"
                      viewBox="0 0 16 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.3773 4.47687L0.749053 0.120783C0.570338 -0.0472979 0.288977 -0.0390511 0.120796 0.13988C-0.0474068 0.318594 -0.0389402 0.59985 0.139886 0.768147L4.75706 5.11371C4.79806 5.06251 5.3773 4.47687 5.3773 4.47687Z"
                        fill="currentColor"
                      />
                      <path
                        d="M15.8802 0.147407C15.712 -0.0314157 15.4306 -0.0403134 15.2519 0.128093L10.625 4.47844C10.625 4.47844 11.2037 5.06372 11.245 5.11559L15.8609 0.775675C16.0397 0.607594 16.0484 0.326231 15.8802 0.147407Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.6423 7.94996L9.88946 8.7028L11.7851 17.921L4.59811 15.8676L6.06105 8.65051L5.30691 7.89636L2.68848 20.8142C2.6045 21.2965 2.78603 21.9953 3.09397 22.3751L7.45788 28.1592C7.61239 28.3498 7.81564 28.4452 8.01887 28.4452C8.2208 28.4452 8.42285 28.3512 8.57748 28.1629L12.9141 22.4821C13.2246 22.1044 13.4022 21.4083 13.3109 20.9274L10.6423 7.94996ZM12.3873 20.8493L4.13513 18.2434C4.12915 18.2415 4.12328 18.2413 4.11733 18.2397L4.42104 16.7413L11.8794 18.8723C11.9142 18.8823 11.9491 18.8856 11.9838 18.887L12.3873 20.8493ZM12.2076 21.9427L8.01996 27.4283L3.80363 21.8397C3.79732 21.8314 3.79092 21.8233 3.78441 21.8151C3.63901 21.6359 3.52692 21.2085 3.56274 20.9756L3.94012 19.1139L12.3121 21.7577C12.3149 21.7586 12.3175 21.7582 12.3202 21.7589C12.2919 21.8219 12.2607 21.8773 12.2274 21.9178C12.2207 21.9259 12.214 21.9343 12.2076 21.9427Z"
                        fill="currentColor"
                      />
                      <path
                        d="M5.5171 6.8511L7.37109 8.70509C7.54394 8.87794 7.77181 8.96432 7.99968 8.96432C8.22755 8.96432 8.45542 8.87794 8.62827 8.70509L10.4823 6.8511C10.828 6.50538 10.828 5.93964 10.4823 5.59392L8.62827 3.73993C8.45542 3.56708 8.22755 3.48059 7.99968 3.48059C7.77181 3.48059 7.54394 3.56708 7.37109 3.73993L5.5171 5.59392C5.17138 5.93964 5.17138 6.50538 5.5171 6.8511ZM7.9973 4.36983C7.9975 4.36972 7.99837 4.36961 7.99968 4.36961V4.36852L9.85367 6.22251L7.99968 8.0765L6.14569 6.22251L7.9973 4.36983Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <b>Skills & Responsibilities</b>
                </div>
              </div>
              <div className="col-auto">
                <div
                  className={`tabLink ${ChooseTabBox === "3" && "active"}`}
                  onClick={() => handelClickTab("3")}
                >
                  <div className="figure">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.1429 24.4093L11.1429 13.3393C11.0758 13.1528 11.0657 12.9506 11.1138 12.7583C11.1618 12.566 11.266 12.3923 11.4129 12.2593L13.7529 9.91933C13.7397 9.61313 13.8199 9.31014 13.983 9.05062C14.146 8.79111 14.3842 8.58736 14.6658 8.46645C14.9475 8.34555 15.2592 8.31321 15.5597 8.37373C15.8602 8.43426 16.1351 8.58478 16.3479 8.80529C16.5608 9.02581 16.7015 9.30588 16.7514 9.60829C16.8013 9.9107 16.7579 10.2211 16.6271 10.4983C16.4964 10.7755 16.2843 11.0063 16.0192 11.1601C15.7541 11.3139 15.4485 11.3834 15.1429 11.3593L13.2429 13.2593L16.0629 21.0693L22.4829 2.59933L4.01293 9.01933L8.27293 10.5193C8.52754 10.6135 8.73431 10.8049 8.84777 11.0515C8.96123 11.2981 8.97208 11.5797 8.87793 11.8343C8.78378 12.0889 8.59234 12.2957 8.34573 12.4092C8.09912 12.5226 7.81754 12.5335 7.56293 12.4393L0.67293 9.94933C0.476439 9.88133 0.306033 9.75376 0.185423 9.58439C0.064814 9.41502 0 9.21226 0 9.00433C0 8.79641 0.064814 8.59365 0.185423 8.42428C0.306033 8.25491 0.476439 8.12734 0.67293 8.05933L23.7529 0.0593342C23.9315 -0.00509489 24.1248 -0.0173456 24.3101 0.0240144C24.4954 0.0653744 24.6651 0.158636 24.7994 0.292893C24.9336 0.427151 25.0269 0.596856 25.0682 0.782166C25.1096 0.967476 25.0974 1.16073 25.0329 1.33933L17.0329 24.4193C16.9639 24.6155 16.8354 24.7852 16.6654 24.9049C16.4954 25.0246 16.2923 25.0884 16.0844 25.0873C15.8764 25.0862 15.674 25.0203 15.5053 24.8988C15.3365 24.7773 15.2099 24.6062 15.1429 24.4093Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <b>Publish</b>
                </div>
              </div>
            </div>
          </div>
          {ChooseTabBox === "1" && (
            <div className="generalInfo">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="form-group">
                    <div className="label">Title</div>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Job Name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <div className="label">Company</div>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Company Name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <div className="label">Type of Job</div>
                    <select name="" id="" className="form-control">
                      <option value="1">Select</option>
                      <option value="1">Option 1</option>
                      <option value="1">Option 2</option>
                      <option value="1">Option 3</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <div className="label">Industry</div>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Industry"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <div className="label">Monthly salary</div>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="$/Month"
                      className="form-control"
                    />
                  </div>
                </div>
                <hr />
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="label">Number of employees needed</div>
                      <div className="d-flex align-items-center countingBox">
                        <button
                          className="btn shadow-none p-0"
                          onClick={() => handelDec()}
                        >
                          <svg
                            width="66"
                            height="62"
                            viewBox="0 0 66 62"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="64"
                              height="60"
                              rx="10"
                              stroke="#FBAF41"
                            />
                            <path
                              d="M32 17C24.2804 17 18 23.2804 18 31C18 38.7196 24.2804 45 32 45C39.7196 45 46 38.7196 46 31C46 23.2804 39.7196 17 32 17Z"
                              fill="#FBAF41"
                            />
                            <rect
                              x="27"
                              y="30"
                              width="10"
                              height="2"
                              fill="white"
                            />
                          </svg>
                        </button>
                        <input
                          type="text"
                          name=""
                          value={NumberOfEmployeesNeeded}
                          className="form-control "
                        />
                        <button
                          className="btn shadow-none p-0"
                          onClick={() => handelInc(1)}
                        >
                          <svg
                            width="66"
                            height="62"
                            viewBox="0 0 66 62"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="64"
                              height="60"
                              rx="10"
                              stroke="#FBAF41"
                            />
                            <path
                              d="M33 17C25.2804 17 19 23.2804 19 31C19 38.7196 25.2804 45 33 45C40.7196 45 47 38.7196 47 31C47 23.2804 40.7196 17 33 17Z"
                              fill="#FBAF41"
                            />
                            <path
                              d="M38 32H34V36H32V32H28V30H32V26H34V30H38V32Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="label">Number of employees needed</div>
                      <div className="d-flex">
                        <div className="timeBox">
                          <input
                            type="text"
                            name=""
                            id=""
                            value="00:00 Am"
                            className="form-control"
                          />
                        </div>
                        <div className="timeBox">
                          <input
                            type="text"
                            name=""
                            id=""
                            value="00:00 Pm"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pt-5 pb-4">
                      <label className="customCheckBox">
                        <span>24 x 7 Accessibility</span>
                        <input type="checkbox" hidden />
                        <i></i>
                      </label>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="card-body">
                  <div className="form-group">
                    <div className="label">Location</div>
                    <input
                      type="text"
                      placeholder="Job Location"
                      className="form-control jobLocation"
                    />
                  </div>
                  <div className="form-group">
                    <label className="customCheckBox">
                      <span>Remote possible</span>
                      <input type="checkbox" hidden />
                      <i></i>
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="label">Description</div>
                    <textarea
                      name=""
                      placeholder="Job Description"
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="label">Contract Length</div>
                    <select name="" id="" className="form-control">
                      <option value="1">Select</option>
                      <option value="1">Option 1</option>
                      <option value="1">Option 2</option>
                      <option value="1">Option 3</option>
                    </select>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-sm-auto">
                      <button className="btn shadow-none bgbtn">Save</button>
                    </div>
                    <div className="col-sm-auto">
                      <button className="btn shadow-none outLine">
                        Next Step
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {ChooseTabBox === "2" && (
            <div className="h1 text-center">Skills & Responsibilities</div>
          )}
          {ChooseTabBox === "3" && (
            <div className="h1 text-center">Publish</div>
          )}
        </div>
      </section>
    </>
  );
}
