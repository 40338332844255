import React, { useState, useEffect } from "react";
import { ApiHelperFunction } from "../../Helper/Helper";
import Header from "../../Share_Module/Header/Header";
import Footer from "../../Share_Module/Footer/Footer";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";

export default function Forgot_password() {
  const history = useHistory();
  const [disable, setdisable] = useState(false);
  const [userData, setuserData] = useState({
    otp: "",
    // email: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const validation = () => {
    let error = {};
    setError({});
    if (!userData.otp) {
      error.otp = "Please Enter OTP";
    } else if (!/^[0-9]/.test(userData.otp)) {
      error.otp = "Invalid OTP";
    }

    if (Object.keys(error)?.length > 0) {
      setError(error);
    }

    return error;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // if(window.localStorage.getItem("Step")==3 || !window.localStorage.getItem("Step") )
    // {
    //   history.push('/forgot-password')
    // }
  }, []);

  const submitData = async (event) => {
    // setdisable(true);
    event.preventDefault();
    let ErrorList = validation();
    // setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
      setuserData({ otp: "" });
    } else {
      let response = await ApiHelperFunction({
        urlPath: "/api/user/forget-password-otp-verification",
        method: "post",

        formData: {
          email: window.localStorage.getItem("email"),
          otp: userData.otp,
        },
        isDaxtra:""
      });

      if (response !== "") {
        setuserData({
          otp: "",
        });

        toast(response?.data?.message, {
          hideProgressBar: true,
          autoClose: 2500,
        });
        if (response.status === 200) {
          history.push("/change-password");
          setdisable(false);
        }
      }
    }
  };

  const resend_otp = async () => {
    setdisable(false);
    let response = await ApiHelperFunction({
      urlPath: "/api/user/resend-email-otp",
      method: "post",

      formData: {
        email: window.localStorage.getItem("email"),
      },
      isDaxtra: "",
    });

    if (response !== "") {
      toast(response?.data?.message, {
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };
  return (
    <>
      <Header />
      <section className="loginPage d-flex align-items-center" style={{ marginTop: "150px", minHeight: "65vh",marginTop:"80px"}} >
        <div className="container">
          <div className="row">
            <div className="card">
              <h3 style={{ textAlign: "center" }}>OTP Verification</h3>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "25px",
                  color: "gray",
                  fontWeight: "light",
                }}
              >
                OTP has been send to your E-mail address
              </h5>
              <div className="form">
                <div className="row">
                  <div className="col-md-12 passwordForm">
                    <div className="form-group mb-4" >
                      <div className="label">Please enter your OTP</div>
                      <input
                        type="password"
                        name="otp"
                        id="otp"
                        value={userData.otp}
                        onChange={postUserData}
                        className="form-control"
                      />
                      <div className="error">{error.otp}</div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <div className="label">Please enter your Email</div>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={userData.email}
                        onChange={postUserData}
                        className="form-control"
                      />
                      <p
                        className="error"
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontWeight: "normal",
                          marginBottom: "10px",
                        }}
                      >
                        {error.email}
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="row justify-content-between logForg">
                  <div className="col-auto">
                    <button
                      className="btn shadow-none btn-color1 text-white btn-lg hoverBtn mt-1"
                      onClick={submitData}
                      disabled={disable}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-auto forgot">
                    <Link to="#" onClick={resend_otp}>
                      Resend OTP?
                    </Link>
                  </div>
                </div>
                {/* <div className="loginBtn-" >
                  <button className="btn shadow-none btn-color1 text-white btn-lg hoverBtn">
                    Submit
                  </button>
                </div>
                <div className="col-auto forgot">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div> */}
              </div>

              {/* <div className="pt-md-4">
                <p className="registerLink">
                  New to JobCupid? <Link to="/auth/register">register</Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
