import { useState ,useEffect} from "react";
import { Link, useHistory } from "react-router-dom";

export default function OnCampusEvents({childToParent}) {
  const history = useHistory();
let child2parent=childToParent
// useEffect(() => {
//   child2parent("HEllo")
// }, [])
  return (
    <>
      <div className="OnCampusEventsTab py-4">
        <div className="loginPhase">
          <h1>Login to access upcoming events</h1>
          {/* <button className="btn" onClick={()=>{history.push('/auth/login')}} >Login</button> */}
          <Link className="btn" to="/auth/login">
            Login
          </Link>
        </div>
      </div>
    </>
  );
}
