import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { ApiHelperFunction } from "../../Helper/Helper";

const initialState = {
  status: "idle",
  feed_cover_status:"idle",
  ListofResumes: null,
  JointFeedBackNCoverLetter:null
};

export const getResumeList = createAsyncThunk(
  "/api/resume/get-resume-list",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/resume/get-resume-list",
      method: "GET",
      // formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;
    return resData;
  }
);


export const getFeedBackNCoverLetter = createAsyncThunk(
  "/api/resume/generate-resume-feedback-cover-letter",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/resume/generate-resume-feedback-cover-letter",
      method: "POST",
      formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;
    return resData;
  }
);


export const ResumeSlice = createSlice({
  name: "Resume",
  initialState,
  reducers: {
    removeAll: (state) => {
      state.ListofResumes = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //get list of resumes
      .addCase(getResumeList.pending, (state, action) => {
        state.status = "loading";
        state.ListofResumes = null;
      })
      .addCase(getResumeList.fulfilled, (state, action) => {
        state.status = "idle";
        if(action?.payload?.status===200)
        {
            state.ListofResumes = action?.payload?.data;
        }
        else{
            state.ListofResumes = null;
        }
      })
      .addCase(getResumeList.rejected, (state, action) => {
        state.status = "idle";
        state.ListofResumes = null;
      })
      //get feedback and cover letter together

      .addCase(getFeedBackNCoverLetter.pending, (state, action) => {
        state.feed_cover_status = "loading";
        state.JointFeedBackNCoverLetter = null;
      })
      .addCase(getFeedBackNCoverLetter.fulfilled, (state, action) => {
        state.feed_cover_status = "idle";

        if(action?.payload?.status===200)
        {
            state.JointFeedBackNCoverLetter = action?.payload?.response;
        }
        else{
            state.JointFeedBackNCoverLetter = null;
        }
      })
      .addCase(getFeedBackNCoverLetter.rejected, (state, action) => {
        state.feed_cover_status = "idle";
        state.JointFeedBackNCoverLetter = null;
      });



  },
});

export const { removeAll } =  ResumeSlice.actions;

export default ResumeSlice.reducer;
