import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory,useLocation} from "react-router-dom";
import Header from "../../Share_Module/Header/Header";
import { toast } from "react-toastify";
import Footer from "../../Share_Module/Footer/Footer";
// import Header from '../../Share_Module/Header/Header'
import { ApiHelperFunction, API_URL } from "../../Helper/Helper";
import DashboardModule from "../../Dashboard_Module/DashboardModule";
import swal from "sweetalert";
import "./update_profile.scss";
import { useDispatch } from "react-redux";
import {getUserProfile} from '../../Redux/Slices/AuthSlice'

import axios from "axios";

export default function Update_Profile(props) {

  const location = useLocation();
  // useEffect(() => {
  // }, [location]);
  const dispatch=useDispatch();
  const parentcallBak=props.parentcallBack
  const StaticLogo = "/img/6.png";
  const inputFile2 = useRef(null);
  const [bigImg, setbigImg] = useState();
  const [bigImgUpload, setbigImgUpload] = useState();

  useEffect(() => {
    window.scroll(0, 0);
    getProfile();
  }, []);
  const history = useHistory();
  const [imageshow, setimageshow] = useState("");
  const [userData, setUserData] = useState({
    firstname: "",
    // localStorage.getItem("fname") || localStorage.getItem("google_firstname"),
    lastname: "",
    email: "",
    schoolEmail: "",
    // localStorage.getItem("lname") || localStorage.getItem("google_lastname"),
  });
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [image, setimage] = useState("");

  const [error, setError] = useState({});
  const [imgUpload, setimgUpload] = useState();

  let name, value;

  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };
  const validation = () => {
    let error = {};

    if (!userData.firstname) error.firstname = "First name is required";
    else if (/\d/.test(userData?.firstname))
      error.firstname = "Please enter a valid First Name";

    if (!userData.lastname) error.lastname = "Last name is required";
    else if (/\d/.test(userData?.lastname))
      error.lastname = "Please enter a valid Last Name";
    // if(type==="file")
    if (userData?.email === "") {
      error.email = "Email is Required";
    } else if (!emailReg.test(userData.email)) {
      error.email = "Please enter a valid Email ";
    }

    if (userData?.schoolEmail !== "") {
      if (!emailReg.test(userData.schoolEmail))
        error.schoolEmail = "Please enter a valid School Email ";
    }
    // if(type==="file")

    return error;
  };

  const onChangeHandler = (e) => {
    let files = e?.target?.files[0];
    setimageshow(files?.name);
    if (files !== undefined) {
      setimgUpload(files);
    }
  };

  const imageupdate2 = () => {
    const temp = inputFile2?.current?.files[0];

    if (temp) {
      setbigImg(URL.createObjectURL(temp));
      setbigImgUpload(temp);
    } else {
      setbigImg();
    }
  };

  const submitData = async (event) => {
    const a = window.localStorage.getItem("token");
    event.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      if (
        window.localStorage.getItem("token") ||
        window.localStorage.getItem("google_token") ||
        window.localStorage.getItem("facebook_token") !== ""
      ) {
        let obj = {
          firstname: userData.firstname || "",
          lastname: userData.lastname,
          profile_image: bigImgUpload,
          //imgUpload
        };
        let formData = new FormData();

        formData.append("firstname", userData.firstname || "");
        formData.append("lastname", userData.lastname || "");
        formData.append("email", userData.email || "");
        formData.append("school_email", userData.schoolEmail || "");
        if (bigImgUpload) {
          formData.append("profile_image", bigImgUpload || "");
        }

        // if (userData?.schoolEmail === "") {
        //   swal({
        //     // title:"Are you sure you want to delete?",
        //     text: "You will not be able to see upcoming events without a school email",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        //   }).then((willUpdate) => {
        //     if (willUpdate) {
        //       updateUserProfile(formData);
        //     }
        //   });
        // } else {
        updateUserProfile(formData);
        // }
      }
    }
  };

  const updateUserProfile = async (formData) => {
    let response = await ApiHelperFunction({
      urlPath: "/api/user/update-user-profile",
      method: "post",
      // headers: {
      //   "x-access-token": window.localStorage.getItem("token"),
      //   "Content-Type": "application/json",
      // },
      formData: formData,
      isDaxtra:""
    });

    if (response !== "") {
      parentcallBak(bigImgUpload)
      dispatch(getUserProfile());
      // window.localStorage.setItem("fname", response.data.data.firstname);
      // window.localStorage.setItem("lname", response.data.data.lastname);
      // window.localStorage.setItem("profileprogress","100")
      toast(response?.data?.message, {
        hideProgressBar: true,
        autoClose: 2500,
      });
      history.push("/dashboard");
    }
  };

  const getProfile = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/user/viewUserDetails",
      method: "GET",
      isDaxtra:""
    });

    // if (response?.data?.data?.school_email === "") {
    //   toast(
    //     "You can not see upcoming on campus events without an School email!",
    //     {
    //       hideProgressBar: true,
    //       autoClose: 2750,
    //     }
    //   );
    // }
    setUserData({
      firstname: response?.data?.data?.firstname,
      lastname: response?.data?.data?.lastname,
      email: response?.data?.data?.email,
      schoolEmail: response?.data?.data?.school_email,
    });
    if (response?.data?.data?.profile_image !== "")
      setbigImg(
        response?.data?.profile_pic_file_path +
          response?.data?.data?.profile_image
      );


  };
  const data = bigImg;

  return (
    <>
      {/* <Header /> */}
      {/* <DashboardModule /> */}

      <section className="loginPage py-5">
        <div className="container">
          <div className="row">
            <div className="card">
              <h3 className="text-center">Update Profile</h3>
              <div className="form">
                <div className="row">
                  <div className="col">
                    <div className="uploadimg">
                      <div className="innerUploadimg">
                        <figure>
                          <img
                            src={!bigImg ? StaticLogo : bigImg}
                            alt=""
                            className="uploadimg"
                          />
                
                          <button
                            onClick={() => {
                              inputFile2.current.click();
                            }}
                          >
                            <input
                              class="form-control"
                              placeholder="profileImage"
                              style={{ display: "none" }}
                              type="file"
                              ref={inputFile2}
                              onChange={imageupdate2}
                              accept="image/*"
                            ></input>
                            <i className="fas fa-camera cameraIcon"></i>
                          </button>
                        </figure>
                        {/* <button
                          type="button"
                          onClick={() => {
                            inputFile2.current.click();
                          }}
                          className="imgUploadbtn"
                          >
                          
                          <input
                            class="form-control"
                            placeholder="profileImage"
                            style={{ display: "none" }}
                            type="file"
                            ref={inputFile2}
                            onChange={imageupdate2}
                            accept="image/*"
                          ></input>
                          </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 passwordForm">
                    <div className="form-group">
                      <div className="label">First Name</div>
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        value={userData.firstname}
                        onChange={postUserData}
                        className="form-control"
                      />
                      <div className="error">{error.firstname}</div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 passwordForm">
                    <div className="form-group">
                      <div className="label">Last Name</div>
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={userData.lastname}
                        onChange={postUserData}
                        className="form-control"
                      />
                      <div className="error">{error.lastname}</div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <div className="label">Prospect (Optional)</div>
                      <input
                        type="text"
                        name="prospect"
                        id="prospect"
                        value={userData.prospect}
                        onChange={postUserData}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="label">Employer (Optional)</div>
                      <input
                        type="text"
                        name="employer"
                        id="employer"
                        value={userData.employer}
                        onChange={postUserData}
                        className="form-control"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 passwordForm">
                    <div className="form-group">
                      <div className="label">Email</div>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={userData?.email}
                        onChange={postUserData}
                        className="form-control"
                        // disabled={true}
                      />
                      <div className="error">{error.email}</div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 passwordForm">
                    <div className="form-group">
                      <div className="label">Your school Email</div>
                      <input
                        type="text"
                        name="schoolEmail"
                        id="schoolEmail"
                        value={userData?.schoolEmail}
                        onChange={postUserData}
                        className="form-control"
                      />
                      <div className="error">{error.schoolEmail}</div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <div className="label">Prospect (Optional)</div>
                      <input
                        type="text"
                        name="prospect"
                        id="prospect"
                        value={userData.prospect}
                        onChange={postUserData}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="label">Employer (Optional)</div>
                      <input
                        type="text"
                        name="employer"
                        id="employer"
                        value={userData.employer}
                        onChange={postUserData}
                        className="form-control"
                      />
                    </div>
                  </div> */}
                </div>

                <div className="row justify-content-between logForg">
                  {/* <div className="col-lg-6">
                    <div className="loginBtn-">
                      <button className="btn shadow-none btn-color1 text-white btn-lg hoverBtn">
                        <input
                          style={{ width: "290px" }}
                          type="file"
                          name="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setimage(onChangeHandler(e))}
                          multiple={false}
                          // hidden
                        />
                      </button>
                    </div>
                  </div> */}
                  {/* <div className="col-auto">
                 <img  src={API + `/uploads/user/profile_pic/${imageshow}`} alt="" />
                  </div> */}
                  {/* <div className="col-auto forgot">
                    <Link to="/password-change">Change Password?</Link>
                  </div> */}
                </div>
                <div className="row justify-content-between logForg mt-2">
                  <div className="col-auto mx-auto">
                    <div className="loginBtn-" onClick={submitData}>
                      <button className="btn shadow-none btn-color1 text-white   hoverBtn">
                        Update Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="pt-md-4">
                <p className="registerLink">
                  New to JobCupid? <Link to="/auth/register">register</Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
}
