import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Header from "../../../Share_Module/Header/Header";
import Footer from "../../../Share_Module/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../../Helper/Helper";
import { geteventJobs } from "../../../Redux/Slices/EventHiringSlice";
import "./EventListByCompany.scss";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../AuthModule/Forgot_password/Loader";
import {
  getFavouriteJobList,
  getRecommnededJobs,
} from "../../../Redux/Slices/FavouriteSlice";
import Moment from "react-moment";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import queryString from "query-string";
import AfterSearchResualt from "../AfterSearchResualt";
import AfterClickJobTitle from "../AfterClickJobTitle";
import AfterSearchResultSkeleton from "../Skeletons/AfterSearchResultSkeleton";
export default function EventListByCompany() {
  const history = useHistory();
  const location = useLocation();
  const [resumeID, setresumeID] = useState("");
  const [compName, setcompName] = useState();
  const [job_id_list, setjob_id_list] = useState([]);
  const selector_events = useSelector((state) => state.eventHiring);
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);
  const [perPage, setperPage] = useState(2);
  const [pageNo, setpageNo] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [disable, setdisable] = useState(false);
  console.log(selector_events);
  let propmptForCompany = [
    "Please wait..",
    "Looking for events..",
    "Understanding the resume..",
    "Looking in the best skill..",
    "Looking at your experience..",
    "Looking at your designation..",
  ];
  useEffect(() => {
    let company = queryString?.parse(history?.location?.search)?.cmpName;
    let resumeID = queryString?.parse(history?.location?.search)?.resume;

    if (company && resumeID) {
      setresumeID(resumeID || "");
      setcompName(company || "");
      getJobIDByCompName(company, resumeID);
    } else {
      history.push("/"); //redirect to home if redirected from url directly
    }
  }, []);

  // const [apply,setapplynow]=useState("apply now")
  // const handlepage = (e, p) => {
  //   setpageNo(p);
  // };

  const getJobIDByCompName = (cmp_name, favResumeID) => {
    const CompanyJobs = dispatch(
      geteventJobs({
        resume_id: favResumeID,
        company_name: cmp_name,
      })
    );
    CompanyJobs?.then((res) => {
      setjob_id_list(
        res?.payload?.reranking_id_list?.length > 0
          ? res?.payload?.reranking_id_list
          : []
      );

      setprevJobs({ ...prevjobs, keyword: cmp_name });
    }).catch((err) => {
      console.log(err);
    });
  };
  const [filterDate, setfilterDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [prevjobs, setprevJobs] = useState({
    keyword: "",
    filters: {},
  });
  // const child2parent = (data) => {
  //   // sethasChildData(data);
  //   // setloader(false); //closing loader after data has reached AfterSerachResault
  // };

  return (
    <>
      <Header />
      <div className="DashboardModule">
        <div className="container">
          {selector_events?.status === "loading" ? (
            //  && job_id_list?.length === 0
            <>
              <span
                class="text-sm-center fa-2x text-color1  "
                style={{ marginTop: "35px" }}
              >

                <Loader
                  data={propmptForCompany}
                  needprompt={true}
                  timebetween={1800}
                />
              </span>
              <div className="nocontentGap">
                <AfterSearchResultSkeleton />
              </div>
            </>
          ) : (
            <>
              {job_id_list?.length > 0 ? (
                <>
                  <AfterSearchResualt
                    job_id_list={job_id_list}
                    // child2parent={child2parent}
                    filterDate={filterDate}
                    srchData={prevjobs}
                    // srchTerm={selector?.resumeName}
                    // child3parent={child3parent}
                  />
                </>
              ) : (
                <>
                  <div className="DashboardModule noEvents">
                    <h4> No Events Found</h4>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="footerCust">
        <Footer />
      </div>
    </>
  );
}
