// createbase64(file).then(base64 => {
//     sessionStorage["fileBase64"] = base64;
//     console.log("file stored",base64);
//   });

export const createbase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export function getbase64(dataurl, filename) {
  if (dataurl) {
    var arr = dataurl?.split(","),
      mime = arr[0]?.match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr?.charCodeAt(n);
    }

    let file = new File([u8arr], filename, { type: mime });
    return file
  }
}
