import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../../Share_Module/Header/Header";
import swal from "sweetalert";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../Share_Module/Footer/Footer";
import { ApiHelperFunction } from "../../Helper/Helper";
import DashboardModule from "../../Dashboard_Module/DashboardModule";
import { saveAs } from "file-saver";
import axios from "axios";
import queryString from "query-string";
import Loader from "../../AuthModule/Forgot_password/Loader";
import { ResumeListSkeleton } from "../Skeletons/ResumesPageSkeleton";
// import  heart_outline from '../img/heart_outline.svg'
import "./ResumesPage.scss";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { setResumeID } from "../../Redux/Slices/SearchJobSlice";
export default function ResumesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState();
  const [disable, setdisable] = useState(false);
  const [listOfresume, setlistOfresume] = useState([]);
  const [resumeStatus, setResumeStatus] = useState();
  const [step1Done, setstep1Done] = useState(false);
  const [step2Done, setstep2Done] = useState(false);
  const [loader, setLoader] = useState(false);
  let cvUploadPrompt = ["Please wait..", "Uploading your resume.."];

  const resumeReplace = (_id, daxtra_id, file) => {
    let deleteResponse = getDeletePath(_id, daxtra_id);
    //   getMultiResume();

    deleteResponse?.then((response) => {
      if (response?.status === 200) {
        let uploadResponse = uploadPdf(file);
        uploadResponse?.then((res) => {
          if (response?.status === 200) {
            toast.success("New Resume uploaded successfully", {
              hideProgressBar: true,
              autoClose: 2500,
            });

            //   // getMultiResume();
          }
        });
      }
    });
  };

  const resumeUpload = (e, ind) => {
    e.preventDefault();
    let file = e?.target?.files[0];

    setData(e?.target?.files[0]);

    for (let i = 0; i <= listOfresume?.length; i++) {
      let daxtra_id = listOfresume[i]?.daxtra_id;
      let curr_id = listOfresume[i]?._id;
      if (listOfresume[i]?.resume_file?.name === file?.name && file) {
        let message = `${file?.name} already exists. Are you sure you want to replace it with a newer file?`;
        swal({
          // title:"Are you sure you want to delete?",
          text: message,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willReplace) => {
          if (willReplace) {
            resumeReplace(curr_id, daxtra_id, file);
          } else {
            document.getElementById("resumeUpload").value = "";
            setData();
          }
        });
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getMultiResume();
  }, []);

  const Step1Api = async (file) => {
    let formData = new FormData();
    formData.append("resume_file", data || file || "");
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/upload",
      method: "post",
      formData,
      isDaxtra: "",
    });

    if (response !== "") {
      setstep1Done(true);
      return response;
    }
  };

  const Step2Api = async (file) => {
    let formData1 = new FormData();
    formData1.append("resume_file", data || file || "");
    let response1 = await ApiHelperFunction({
      urlPath: "/run/resume/flow/json",
      method: "post",
      formData: formData1,
    });

    if (response1 !== "") {
      setstep2Done(false);
      return response1;
    }
  };

  const finalApi = async (
    daxtraID,
    resume_file,
    resume_data_response,
    skill_matrix
  ) => {
    let response2 = await ApiHelperFunction({
      urlPath: "/api/resume/save-resume-data",
      method: "post",
      formData: {
        daxtraID: daxtraID,
        resume_file: resume_file,
        resume_data_response: resume_data_response,
        skill_matrix: skill_matrix,
      },
      isDaxtra: "",
    });


    getMultiResume();
    setData();
    document.getElementById("resumeUpload").value = "";
    return response2;
  };

  const uploadPdf = async (file) => {
    setLoader(true);
    setdisable(true);
    let step1 = await Step1Api(file);
    let step2 = await Step2Api(file);
    setLoader(true);

    // let finalresponse=""

    if (step2 && step2?.data?.status === "ok") {
      if (step1 !== "" && step2 !== "") {
        let daxtraID = step2?.data?.daxtraID;
        let resume_file = step1?.data?.data || file || "";
        let resume_data_response = step2?.data?.resume_data_response;
        let skill_matrix = step2?.data?.skill_matrix;

        let finalresponse = await finalApi(
          daxtraID,
          resume_file || file || "",
          resume_data_response,
          skill_matrix
        );
        setdisable(false);
        setLoader(false);
        if (file) return finalresponse;
      }
    } else {
      toast.error("Something went wrong!", {
        hideProgressBar: true,
        autoClose: 2500,
      });
      setLoader(false);
    }
  };

  const getMultiResume = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/get-resume-list",
      method: "GET",
      // formData,
      isDaxtra: "",
    });

    if (response?.status === 200) {
      setlistOfresume(response?.data?.data);
    }

    else {
      setlistOfresume([]);
    }
  };

  const getDownloadPath = async (_id) => {
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/resume-download",
      method: "post",
      formData: { resume_id: _id },
      isDaxtra: "",
    });

    return response;
  };

  const resumeDownload = (_id, name) => {
    let response = getDownloadPath(_id);
    let path = "";
    response?.then((res) => {
      path = res?.data?.download_resume_path + name;
      saveAs(path, name);
    });
  };

  const getDeletePath = async (_id, daxtra_id) => {
    let response2 = await ApiHelperFunction({
      urlPath: "/resume/delete/one/",
      method: "post",
      formData: { resume_id: daxtra_id },
    });

    if (response2?.data?.status !== "error") {
      let response = await ApiHelperFunction({
        urlPath: "/api/resume/delete-resume",
        method: "post",
        formData: { resume_id: _id },
        isDaxtra: "",
      });
      if (response !== "" && response2 !== "") {
        return response;
      }
    }

    // return response;
  };

  const makeFavourite = async (_id) => {
    let response = await ApiHelperFunction({
      urlPath: "/api/resume/save-favourite",
      method: "POST",
      formData: { resume_id: _id },
      isDaxtra: "",
    });

    return response;
  };

  const resumeDelete = (_id, daxtra_id) => {
    let path = "";

    swal({
      // title:"Are you sure you want to delete?",
      text: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let response = getDeletePath(_id, daxtra_id);
        response?.then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message, {
              hideProgressBar: true,
              autoClose: 2500,
            });

            getMultiResume();
          }
        });
      }
    });
  };

  const changeTofav = (index) => {
    let response = makeFavourite(index);

    response?.then((res) => {
      if (
        res?.data?.message ===
        "User's favourite resume has been selected successfully"
      ) {
        toast.success("Added to Favourites!", {
          hideProgressBar: true,
          autoClose: 2500,
        });
        getMultiResume();
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  return (
    <section
      className="DashBoardResumesPage d-flex align-items-center ipad_pro d-flex align-items-center"
      style={{ margin: "9rem 0 5rem", minHeight: "20vh", marginTop: "16px" }}
    >
      <div className="container">
        <div className="pageTitle text-center">My Resumes</div>
        {!loader ? (
          ""
        ) : (
          <Loader data={cvUploadPrompt} needprompt={true} timebetween={3000} />
        )}
        <div className="row ">
          {listOfresume?.length <= 5 ? (
            <>
              <div className="col-sm-6 col-md-4 col-lg-3 py-3">
                <div className="resCard">
                  <label>
                    <input
                      type="file"
                      hidden
                      id="resumeUpload"
                      accept={`.docx,.doc,.pdf`}
                      onChange={(e) => {
                        // setData(e?.target?.files[0]);
                        resumeUpload(e);
                      }}
                      multiple={false}
                    />
                    <div className="cont">
                      <svg
                        width="46"
                        height="34"
                        viewBox="0 0 46 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23 0C26.4222 0 29.8497 1.30031 32.4608 3.91134C34.6768 6.12736 35.9532 8.92889 36.2885 11.8176C41.7376 12.3123 46 16.8872 46 22.4651C46 28.3732 41.2104 33.1628 35.3023 33.1628H9.09302C4.05549 33.1628 0 29.1073 0 24.0698C0 19.0322 4.05549 14.9767 9.09302 14.9767H9.7282C9.25686 11.043 10.5372 6.93012 13.556 3.91134C16.1669 1.30031 19.5778 0 23 0ZM23 11.2326C22.7161 11.2315 22.5167 11.2848 22.2812 11.5L16.3975 16.8488C15.979 17.2244 15.902 17.9573 16.314 18.3699C16.7113 18.7679 17.4198 18.8159 17.8351 18.4367L21.9302 14.7093V26.2093C21.9302 26.8001 22.4091 27.2791 23 27.2791C23.5909 27.2791 24.0698 26.8001 24.0698 26.2093V14.7093L28.1649 18.4367C28.5802 18.8159 29.3086 18.7867 29.686 18.3699C30.0635 17.953 30.0421 17.2315 29.6025 16.8488L23.7188 11.5C23.5659 11.36 23.2861 11.2379 23 11.2326Z"
                          fill="#FAAF40"
                        />
                      </svg>
                      {!data ? (
                        <>
                          <p>
                            Upload your resume <br />
                            (File type- Doc, Docx, .PDF. Maximum- 6 Resumes)
                          </p>
                        </>
                      ) : (
                        <>
                          <span className="resumeNameDisplay">
                            {" "}
                            {data?.name}
                          </span>
                          <button onClick={uploadPdf} className="upload_click">
                            Upload
                          </button>
                        </>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
 
          {listOfresume?.map((data, ind) => (
            <div className="col-sm-6 col-md-4 col-lg-3 py-3" key={ind}>
              <div className="resCard">
                <div className="afterFileChoose text-center flex-column justify-content-center">
                  <h5 className="mb-0 resumeNameDisplay">
                    {data?.resume_file?.name}
                  </h5>
                  <hr />
                  <div className="d-flex justify-content-around">
                    {/* search icon */}
                    <div className="">
                      <span className="tooltiptext  my-5 mx-1">Search</span>
                      <Tooltip title="Search">
                        <button
                          className="btn p-0 shadow-none"
                          onClick={() => {
                            window.localStorage.setItem("tab", `cat1`);
                            window.sessionStorage.setItem("job_id_list", "");
                            window.sessionStorage.clear();
                            window.sessionStorage.setItem("status", "idle");
                       
                            dispatch(
                              setResumeID({
                                ID: data?.daxtra_id,
                                resume_name: data?.resume_file?.name,
                              })
                            );
                            window.sessionStorage.setItem("fileName",data?.resume_file?.name);
                            history.push('/home')
            
                          }}
                        >
                          <svg
                            width="39"
                            height="38"
                            viewBox="0 0 39 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="19.3223"
                              cy="19"
                              r="18.25"
                              stroke="#D1D1D1"
                              stroke-width="1.5"
                            />
                            <path
                              d="M26.073 25.5802L22.3943 21.8971C24.2439 19.4897 24.059 16.0123 21.8394 13.8107C20.6269 12.5967 19.065 12 17.4825 12C15.9001 12 14.3382 12.5967 13.1256 13.8107C10.7211 16.2181 10.7211 20.1276 13.1256 22.535C14.3382 23.749 15.9001 24.3457 17.4825 24.3457C18.7978 24.3457 20.1131 23.9342 21.2023 23.0905L24.9016 26.7531C25.066 26.9177 25.2715 27 25.4976 27C25.7031 27 25.9292 26.9177 26.0936 26.7531C26.4018 26.4444 26.4018 25.9095 26.073 25.5802ZM17.5031 22.679C16.2906 22.679 15.1808 22.2058 14.3176 21.3621C12.5708 19.6132 12.5708 16.7531 14.3176 14.9835C15.1602 14.1399 16.2906 13.6667 17.5031 13.6667C18.7156 13.6667 19.8254 14.1399 20.6886 14.9835C21.5517 15.8272 22.0038 16.9588 22.0038 18.1728C22.0038 19.3868 21.5312 20.4979 20.6886 21.3621C19.846 22.2263 18.6951 22.679 17.5031 22.679Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                    {/* Download icon */}

                    <div className="">
                      <span className="tooltiptext  my-5 mx-1">Download</span>
                      <Tooltip title="Download">
                        <button
                          className="btn p-0 shadow-none"
                          onClick={() => {
                            resumeDownload(data?._id, data?.resume_file?.name);
                          }}
                        >
                          <svg
                            width="39"
                            height="38"
                            viewBox="0 0 39 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="19.1875"
                              cy="19"
                              r="18.25"
                              stroke="#D1D1D1"
                              stroke-width="1.5"
                            />
                            <path
                              d="M12.1875 25.4701C12.1875 25.6115 12.2154 25.7516 12.2695 25.8822C12.3236 26.0129 12.4029 26.1316 12.5029 26.2316C12.6029 26.3316 12.7216 26.4109 12.8523 26.4651C12.983 26.5192 13.123 26.547 13.2644 26.547H25.1106C25.3962 26.547 25.6701 26.4336 25.8721 26.2316C26.074 26.0296 26.1875 25.7557 26.1875 25.4701C26.1875 25.1845 26.074 24.9106 25.8721 24.7086C25.6701 24.5067 25.3962 24.3932 25.1106 24.3932H13.2644C12.9788 24.3932 12.7049 24.5067 12.5029 24.7086C12.301 24.9106 12.1875 25.1845 12.1875 25.4701Z"
                              fill="currentColor"
                            />
                            <path
                              d="M18.9397 12C18.6541 12 18.3802 12.1135 18.1782 12.3154C17.9762 12.5174 17.8628 12.7913 17.8628 13.0769V19.4889L15.1726 16.6523C14.9764 16.4447 14.7057 16.3235 14.4202 16.3154C14.1346 16.3073 13.8575 16.413 13.6498 16.6092C13.4422 16.8055 13.321 17.0761 13.3129 17.3617C13.3089 17.5031 13.3328 17.6439 13.3832 17.7761C13.4337 17.9082 13.5096 18.0292 13.6068 18.132L18.1578 22.9351C18.2585 23.0417 18.3799 23.1266 18.5145 23.1846C18.6491 23.2426 18.7942 23.2725 18.9408 23.2725C19.0874 23.2725 19.2324 23.2426 19.367 23.1846C19.5017 23.1266 19.623 23.0417 19.7237 22.9351L24.2705 18.132C24.3676 18.0292 24.4436 17.9082 24.494 17.7761C24.5444 17.6439 24.5683 17.5031 24.5643 17.3617C24.5603 17.2203 24.5285 17.0811 24.4707 16.952C24.4129 16.8228 24.3302 16.7064 24.2274 16.6092C24.1246 16.5121 24.0036 16.4361 23.8715 16.3857C23.7393 16.3353 23.5985 16.3114 23.4571 16.3154C23.3157 16.3194 23.1764 16.3512 23.0473 16.409C22.9182 16.4668 22.8018 16.5495 22.7046 16.6523L20.0166 19.4889V13.0769C20.0166 12.7913 19.9032 12.5174 19.7012 12.3154C19.4992 12.1135 19.2253 12 18.9397 12Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>

                    {/* Favourite icon */}
                    <div className="">
                      <span className="tooltiptext  my-5 mx-1">Favourite</span>
                      <Tooltip
                        title={
                          data?.isFavourite
                            ? "Remove from favourites"
                            : "Add to favourites"
                        }
                      >
                        <button
                          className="btn p-0 shadow-none favroutie-button"
                          onClick={() => {
                            changeTofav(data?._id);
                          }}
                        >
                          {data?.isFavourite ? (
                            <>
                              <i class="fas heart fa-heart"></i>
                            </>
                          ) : (
                            <>
                              <i class="fad fa-heart"></i>
                            </>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    {/* Delete icon */}
                    <div className="">
                      <span className="tooltiptext  my-5 mx-1">Delete</span>
                      <Tooltip title="Delete Resume">
                        <button
                          className="btn p-0 shadow-none"
                          onClick={() => {
                            resumeDelete(data?._id, data?.daxtra_id);
                          }}
                        >
                          <svg
                            width="39"
                            height="38"
                            viewBox="0 0 39 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="19.0508"
                              cy="19"
                              r="18.25"
                              stroke="#D1D1D1"
                              stroke-width="1.5"
                            />
                            <path
                              d="M24.0329 24.6464C23.8376 24.8417 23.5211 24.8417 23.3258 24.6464L19.4043 20.725C19.2091 20.5297 18.8925 20.5297 18.6972 20.725L14.7758 24.6464C14.5805 24.8417 14.2639 24.8417 14.0687 24.6464L13.4043 23.9821C13.2091 23.7869 13.2091 23.4703 13.4043 23.275L17.3258 19.3536C17.5211 19.1583 17.5211 18.8417 17.3258 18.6464L13.4043 14.725C13.2091 14.5297 13.2091 14.2131 13.4043 14.0179L14.0687 13.3536C14.2639 13.1583 14.5805 13.1583 14.7758 13.3536L18.6972 17.275C18.8925 17.4703 19.2091 17.4703 19.4043 17.275L23.3258 13.3536C23.5211 13.1583 23.8376 13.1583 24.0329 13.3536L24.6972 14.0179C24.8925 14.2131 24.8925 14.5297 24.6972 14.725L20.7758 18.6464C20.5805 18.8417 20.5805 19.1583 20.7758 19.3536L24.6972 23.275C24.8925 23.4703 24.8925 23.7869 24.6972 23.9821L24.0329 24.6464Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
