import "./InnerBanner.scss";
import { useEffect } from "react";
export default function InnerBanner() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
  return (
    <section className="innerBanner">
      <img src="img/1.png" alt="" />
      <div className="container-xl content">
        <h1>Career Starts Here</h1>
        <p>AI Platform for Career Development</p>
      </div>
    </section>
  );
}
