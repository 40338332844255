import { Switch, Route, Link, NavLink } from "react-router-dom";
import Footer from "../Share_Module/Footer/Footer";
import Header from "../Share_Module/Header/Header";
import "./EmployerDashboardModule.scss";
import EmployerDashboardPage from "./EmployerDashboardPage/EmployerDashboardPage";
import EmployerJobPage from "./EmployerJobPage/EmployerJobPage";
import EmployerProfilePage from "./EmployerProfilePage/EmployerProfilePage";

export default function EmployerDashboardModule() {
  return (
    <>
      <Header />
      <div className="DashboardModule">
        <div className="dashboardMenu">
          <div className="container">
            <nav className="mb-0">
              <ul className="list-unstyled mb-0 d-flex overflow-auto">
                <li>
                  <NavLink
                    to="/employer-dashboard"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.4"
                        y="0.4"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                      <rect
                        x="6.99961"
                        y="0.4"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                      <rect
                        x="0.4"
                        y="6.99998"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                      <rect
                        x="6.99961"
                        y="6.99998"
                        width="4.6"
                        height="4.6"
                        rx="0.6"
                        stroke="currentColor"
                        stroke-width="0.8"
                      />
                    </svg>

                    <span>Dashboard</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/employer-dashboard/profile"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.10694 7.18139C9.10297 6.60529 9.77606 5.51312 9.77606 4.25828C9.77606 2.40595 8.31174 0.9 6.5 0.9C4.68827 0.9 3.22394 2.40595 3.22394 4.25828C3.22394 5.51312 3.89703 6.60529 4.89306 7.18139C2.58474 7.89161 0.9 10.0895 0.9 12.6821V13V13.1H1H12H12.1V13V12.6821C12.1 10.0895 10.4153 7.89161 8.10694 7.18139ZM4.04366 4.25828C4.04366 2.86504 5.14807 1.73576 6.5 1.73576C7.85193 1.73576 8.95634 2.86504 8.95634 4.25828C8.95634 5.65151 7.85193 6.7808 6.5 6.7808C5.14807 6.7808 4.04366 5.65151 4.04366 4.25828ZM11.2632 12.2642H1.73693C1.94444 9.75168 4.00358 7.7755 6.5 7.7755C8.99642 7.7755 11.0556 9.75168 11.2632 12.2642Z"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-width="0.2"
                      />
                    </svg>
                    <span>Profile</span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/employer-dashboard/job"
                    activeClassName="active"
                    exact
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6 1.86667H10.5551L10.0086 0.773733C9.77013 0.296333 9.28993 0 8.75653 0H5.24347C4.71007 0 4.23033 0.296333 3.9914 0.773733L3.44493 1.86667H1.4C0.628133 1.86667 0 2.4948 0 3.26667V6.1908C0 6.5968 0.181067 6.96687 0.466667 7.2254V12.6C0.466667 13.3719 1.0948 14 1.86667 14H12.1333C12.9052 14 13.5333 13.3719 13.5333 12.6V7.22493C13.8189 6.9664 14 6.5968 14 6.19033V3.26667C14 2.4948 13.3719 1.86667 12.6 1.86667ZM4.82627 1.1914C4.9056 1.03227 5.06567 0.933333 5.24347 0.933333H8.75653C8.93433 0.933333 9.0944 1.03227 9.17373 1.1914L9.5116 1.86667H4.4884L4.82627 1.1914ZM0.933333 3.26667C0.933333 3.00953 1.14287 2.8 1.4 2.8H3.73333H10.2667H12.6C12.8571 2.8 13.0667 3.00953 13.0667 3.26667V6.1908C13.0667 6.42133 12.8945 6.61967 12.6658 6.6528L8.4 7.2618V6.53333C8.4 6.27573 8.19093 6.06667 7.93333 6.06667H6.06667C5.80907 6.06667 5.6 6.27573 5.6 6.53333V7.26227L1.33373 6.6528C1.10553 6.61967 0.933333 6.42133 0.933333 6.1908V3.26667ZM7.46667 7V8.86667H6.53333V7H7.46667ZM12.1333 13.0667H1.86667C1.60953 13.0667 1.4 12.8571 1.4 12.6V7.60433L5.6 8.20447V9.33333C5.6 9.59093 5.80907 9.8 6.06667 9.8H7.93333C8.19093 9.8 8.4 9.59093 8.4 9.33333V8.20447L12.6 7.60433V12.6C12.6 12.8571 12.3905 13.0667 12.1333 13.0667Z"
                        fill="#8C8C8C"
                      />
                    </svg>

                    <span>Jobs</span>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/employer-dashboard/" exact>
          <EmployerDashboardPage />
        </Route>
        <Route path="/employer-dashboard/profile/" exact>
          <EmployerProfilePage />
        </Route>

        <Route path="/employer-dashboard/job/" exact>
          <EmployerJobPage />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}
