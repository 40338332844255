import { useState, useEffect, Component ,lazy} from "react";
import "./SearchJobWidget.scss";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
// import queryString from "query-string";

import { useLocation, useHistory } from "react-router-dom";
import { ApiHelperFunction } from "../../Helper/Helper";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SearchFilterCard from "./SearchFilterCard";
// const AfterSearchResualt = lazy(() =>
//   import("./AfterSearchResualt")
// );

import AfterSearchResualt from "./AfterSearchResualt";
import SearchUsingResume from "./SearchUsingResume";
import OnCampusEvents from "./onCampusEvents";
import OnCampusJobList from "./OnCampusJobList";
import Loader from "../../AuthModule/Forgot_password/Loader";
import { encryptData, decryptData } from "../../encrypt/Util";
import { useDispatch, useSelector } from "react-redux";
import { createbase64, getbase64 } from "../Services/base64Handler";
import {
  searchJobByResumeID,
  AllJobstatus,
  searchJobByResumeFile,
  clearResumeID,
  searchDaxtraByKeyword
} from "../../Redux/Slices/SearchJobSlice";
import { getResumeList } from "../../Redux/Slices/ResumeSlice";
const popularSearches = [
  "Mechanical",
  "Engineer",
  "Work From Home",
  "Researcher",
  "Business Analyst",
  "Healthcare",
  "NGO",
  "Mechanical",
  "Engineer",
  "Work From Home",
];

export default function SearchJobWidget({ event_list }) {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.searchJob);
  const selector_user = useSelector((state) => state.userProfile);
  const history = useHistory();
  //daxtraID from resume list

  // console.log(history?.location);
  // console.log("test",queryString?.parse(history?.location?.search)?.name)
  // console.log("test",id)
  const [listOfresume, setlistOfresume] = useState([]);
  const [resumeNameList, setresumeNameList] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [isShowJobTitle, setisShowJobTitle] = useState(false);
  const [isShowLocation, setisShowLocation] = useState(false);
  const [loader, setloader] = useState(false);
  const [disable, setdisable] = useState(false);
  const [OptionChoose, setOptionChoose] = useState();
  const [DemoSearch, setDemoSearch] = useState(false);
  const [ChildData, setChildData] = useState("1");
  const [job_id_list, setjob_id_list] = useState([]);
  // const [hasChildData, sethasChildData] = useState(false);
  const [singleLocation, setsingleLocation] = useState("");
  const [locationList, setlocationList] = useState([]);
  const [educationList, seteducationList] = useState([]);
  const [jobTypeList, setjobTypeList] = useState([]);
  const [singlejobtypelist, setsinglejobtypelist] = useState("");
  const [skillset, setskillset] = useState([]);
  const [singleEducation, setsingleEducation] = useState("");
  const [singleSkillName, setsingleSkillName] = useState("");
  const [singleCompanyName, setsingleCompanyName] = useState("");
  const [CompanyNameList, setCompanyNameList] = useState([]);
  const [currentKeyword, setcurrentKeyword] = useState("");
  const [resumeFile, setResumeFile] = useState();
  const [OptOldResumeID, setOptOldResumeID] = useState();
  let promptWithCV = [
    "Please wait..",
    "Uploading your resume..",
    "Understanding the resume..",
    "Looking in the best skill..",
    "Looking at your experience..",
    "Looking at your designation..",
  ];
  let promptWithoutCV = [
    "Please wait..",
    "Looking for jobs..",
    "Looking in the best skills..",
    "Looking at experience levels..",
  ];
  const [jobs, setjobs] = useState({
    keyword: "",
    filters: {},
  });
  const [prevjobs, setprevJobs] = useState({
    keyword: "",
    filters: {},
  });
  const [filterDate, setfilterDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [error, setError] = useState({});
  const [srchFilename, setsrchFileName] = useState(
    window.sessionStorage.getItem("fileName") || ""
  );
  const validation = () => {
    let error = {};
    if (!jobs.keyword) {
      error.jobs = "Job Title keyword is required";
    }
    return error;
  };

  //   const ApiHelperFunction1="https://jcaiapi.herokuapp.com/job/by/keyword/search"
  const auth =
    window.localStorage.getItem("jobcupid_token") ||
    localStorage.getItem("google_token") ||
    localStorage.getItem("facebook_token");

  const [getTabOptions, setTabOptions] = useState([]);

  // const getMultiResume = async () => {
  //   let response = await ApiHelperFunction({
  //     urlPath: "/api/resume/get-resume-list",
  //     method: "GET",
  //     // formData,
  //     isDaxtra: "",
  //   });

  //   if (response?.status === 200) {
  //     // console.log(response?.data?.data);
  //     setlistOfresume(response?.data?.data);
  //     let temp = [];
  //     response?.data?.data?.map((name) => {
  //       temp = [
  //         ...temp,
  //         {
  //           filename: name?.resume_file?.name,
  //           daxtra_id: name?.daxtra_id,
  //           _id: name?._id,
  //         },
  //       ];
  //     });
  //     // console.log(temp);
  //     // setlistOfresume([]);

  //     setresumeNameList(temp);
  //   }

  //   //  else if (response?.status === 201) {
  //   //   // setResumeStatus(response?.status);
  //   //   setlistOfresume([]);
  //   // }
  //   else {
  //     setlistOfresume([]);
  //     setresumeNameList([]);
  //   }
  // };
  const getOpenTabs = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/homepagetab/homepagetab-list",
      method: "GET",
      isDaxtra: "",
    });

    if (!OptionChoose && !window.localStorage.getItem("tab")) {
      for (let i = 0; i <= response?.data?.data?.length; i++) {
        if (response?.data?.data[i]?.viewstatus === "show") {
          setOptionChoose(
            `cat${response?.data?.data?.indexOf(response?.data?.data[i]) + 1}`
          );
          window.localStorage.setItem(
            "tab",
            `cat${response?.data?.data?.indexOf(response?.data?.data[i]) + 1}`
          );
          break;
        }
      }
    } else if (window.localStorage.getItem("tab") !== "") {
      setOptionChoose(window.localStorage.getItem("tab"));
    }
    // setOptionChoose(`cat${(response?.indexOf(response?.data?.data[0]))+1}`)
    setTabOptions(response?.data?.data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOpenTabs();
    if (window.sessionStorage.getItem("job_id_list")) {
      let session_job_id_list = JSON.parse(
        window.sessionStorage.getItem("job_id_list")
      );
      setjob_id_list(session_job_id_list);
      setDemoSearch(true);
    }
    if (window.sessionStorage.getItem("searchfilters")) {
      let session_filters = JSON.parse(
        window.sessionStorage.getItem("searchfilters")
      );

      if (session_filters?.keyword !== "") {
        setisShowJobTitle(true);
      }
      if (session_filters?.filters?.Location !== "") {
        setisShowLocation(true);
      }

      setjobs(session_filters);
    }

    return () => {
      //  clearResumeIDSrchResults();
    };
  }, []);

  useEffect(() => {
    if (
      !resumeFile &&
      jobs?.keyword === "" &&
      selector?.resumeIDFromList !== ""
    ) {
      getDataByResumeID(selector?.resumeIDFromList);
    }
  }, []);

  useEffect(() => {
    if (selector_user?.UserProfile?._id !== "") {
      dispatch(getResumeList());
    }
  }, [selector_user?.UserProfile?._id]);

  //   useEffect(()=>{
  // console.log("here",history?.location);

  // setres_id( queryString?.parse(history?.location?.search)?.id || "");
  // setresume_name(queryString?.parse(history?.location?.search)?.name || "");
  //   },[history?.location])

  let name, value;

  const sub = (e) => {
    setdisable(false);
    setisShowJobTitle(true);
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setInputValue(e.target.value);
    setjobs({ ...jobs, [name]: value });
    if (e.target.value.length === 0) {
      setisShowJobTitle(false);
    }
  };

  // const getFilters = (e) => {
  //   setdisable(false);
  //   e.preventDefault();
  // };

  const clearResumeIDSrchResults = () => {
    dispatch(clearResumeID());
  };
  const clearFilters = () => {
    setsingleLocation("");
    setlocationList([]);
    seteducationList([]);
    setjobTypeList([]);
    setsinglejobtypelist("");
    setskillset([]);
    setsingleEducation("");
    setsingleSkillName("");
    setsingleCompanyName("");
    setCompanyNameList([]);
  };

  const clearAllJobData = () => {
    setprevJobs({}); //clearing previous filters on tab change.
    setprevJobs({ keyword: "", filters: {} });
    setjobs({ keyword: "", filters: {} });
    setsrchFileName(); // clear resume name // was persisting between tab changes.
    clearFilters();
    setjob_id_list([]);
    clearResumeIDSrchResults();
    window.sessionStorage.clear();
    history.push("/home");
    //to clear previous search results from sessionStorage i.e. clear jobtypelist,locationList etc.
  };
  const handelRadioBtn = (e) => {
    // clearResumeIDSrchResults();
    setDemoSearch(false);

    clearAllJobData();

    setOptionChoose(e.target.value);
    window.localStorage.setItem("tab", e.target.value);

    setChildData("1");

    // window.scroll({
    //   top: ban[0].offsetHeight / 2,
    //   left: 0,
    //   behavior: "smooth",
    // });
  };

  // const handleEventHiringData=()=>{

  // }

  const demoSearchData = () => {
    setDemoSearch(true);
  };

  const childToParent = (data) => {
    setChildData(data);
  };

  const child2parent = (data) => {
    setloader(false); //closing loader after data has reached AfterSearchResault
  };

  const getjobtypelist = async () => {
    //  /api/filter/get-jobType-level-list
    let response = await ApiHelperFunction({
      urlPath: "/api/filter/get-job-type-list",
      method: "GET",
      // formData: { job_id_list },
      isDaxtra: "",
    });

    let temp = [];
    response?.data?.data?.map((item) => {
      temp = [...temp, { label: item, value: item }];
    });
    let arrayofjobtypelist = response?.data?.data;
    if (currentKeyword !== jobs.keyword) {
      window.sessionStorage.setItem(
        "jobtypelist",
        JSON.stringify(arrayofjobtypelist)
      );
      setcurrentKeyword(jobs.keyword);
    }

    setjobTypeList(temp);
  };

  const getEducationLevel = async () => {
    //  /api/filter/get-jobType-level-list
    let response = await ApiHelperFunction({
      urlPath: "/api/filter/get-education-level-list",
      method: "GET",
      // formData: { job_id_list },
      isDaxtra: "",
    });

    let temp = [];
    response?.data?.data?.map((item) => {
      temp = [...temp, { label: item, value: item }];
    });
    let arrayofEducation = response?.data?.data;
    if (currentKeyword !== jobs.keyword) {
      window.sessionStorage.setItem(
        "educationList",
        JSON.stringify(arrayofEducation)
      );
      setcurrentKeyword(jobs.keyword);
    }

    seteducationList(temp);
  };

  const getLocation = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/filter/get-location-list",
      method: "POST",
      formData: { job_id_list },
      isDaxtra: "",
    });

    // let temp=locationList
    let temp = [];
    if (response?.data?.data?.length > 0) {
      response?.data?.data?.map((item) => {
        temp = [...temp, { label: item, value: item }];
      });
      let arrayofLocations = response?.data?.data;

      if (currentKeyword !== jobs.keyword) {
        // const encryptedData = encryptData(arrayofLocations, auth);
        // window.sessionStorage.setItem(
        //   "locations2",
        //   JSON.stringify(encryptedData)
        // );
        window.sessionStorage.setItem(
          "locations",
          JSON.stringify(arrayofLocations)
        );
        setcurrentKeyword(jobs.keyword);
      }

      setlocationList(temp);
    }
  };

  const getCompanyName = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/api/filter/get-company-list",
      method: "POST",
      formData: { job_id_list },
      isDaxtra: "",
    });
    // let temp=locationList
    let temp1 = [];
    if (response?.data?.data?.length > 0) {
      response?.data?.data?.map((item) => {
        temp1 = [...temp1, { label: item, value: item }];
      });
      let arrayofCompanyName = response?.data?.data;
      if (currentKeyword !== jobs.keyword) {
        window.sessionStorage.setItem(
          "CompanyName",
          JSON.stringify(arrayofCompanyName)
        );
        setcurrentKeyword(jobs.keyword);
      }
      setCompanyNameList(temp1);
    }
  };

  useEffect(() => {
    if (
      job_id_list?.length !== 0
      // && (prevjobs?.keyword!==jobs?.keyword) // change filters only if keyword has changed
    ) {
      getLocation();
      getCompanyName();
      getEducationLevel();
      getjobtypelist();
    }
    // else{
    //   setCompanyNameList([]);
    // }
  }, [job_id_list]);

  const getSingleLocation = (location) => {
    //  delete jobs?.filters?.PostCode
    if (location === "") {
      setsingleLocation("no location");
    } else {
      setsingleLocation(location);
    }
    let temp = jobs.filters;
    temp = { ...temp, Location: location };
    setjobs({
      ...jobs,
      filters: temp,
    });
  };

  const getSingleCompanyName = (CompanyName) => {
    if (CompanyName === "") {
      setsingleCompanyName("No Company");
    } else {
      setsingleCompanyName(CompanyName);
    }
    let temp = jobs.filters;
    temp = { ...temp, CompanyName: CompanyName };
    setjobs({
      ...jobs,
      filters: temp,
    });
  };

  const getSinglejobtypelist = (jobtypelist) => {
    if (jobtypelist === "") {
      setsinglejobtypelist("No Education");
    } else {
      setsinglejobtypelist(jobtypelist);
    }
    let temp = jobs.filters;
    temp = { ...temp, jobtypelist: jobtypelist };
  };
  const getSingleEducationName = (educationName) => {
    if (educationName === "") {
      setsingleEducation("No Education");
    } else {
      setsingleEducation(educationName);
    }
    let temp = jobs.filters;
    temp = { ...temp, EducationLevel: educationName };
    // setjobs({
    //   ...jobs,
    //   filters: temp,
    // });
  };

  const getSingleSkillName = (singleSkillName) => {
    if (singleSkillName === "") {
      setsingleSkillName("No Skills");
    } else {
      setsingleSkillName(singleSkillName);
    }
    let temp = jobs.filters;
    temp = { ...temp, SkillName: singleSkillName };

    // setjobs({
    //   ...jobs,
    //   filters: temp,
    // }); // not implemented in the api.
  };

  // const getDateFilter=(datefilter)=>{
  //   //  filterDate,setfilterDate
  //   setfilterDate(datefilter)

  // }

  const getSkillList = async (srchKey, isSearched) => {
    let path1 = "/api/filter/get-skill-list";
    let method1 = "GET";
    let path2 = "/api/filter/search-skill-by-name";
    let method2 = "POST";
    let formData = isSearched === true ? { skill_name: srchKey } : "";

    let response = await ApiHelperFunction({
      urlPath: isSearched === true ? path2 : path1,
      method: isSearched === true ? method2 : method1,
      formData: formData,
      isDaxtra: "",
    });
    let temp = [];

    if (response?.data?.data?.length > 0) {
      response?.data?.data?.map((item) => {
        temp = [...temp, { label: item, value: item }];
        // setjobtypelist([...jobtypelist,{"label":item,"value":item}])
      });
    }

    setskillset(temp);
  };
  /// For every filter add one useEffect to call the search api automatically.
  useEffect(() => {
    if (singleLocation !== "") {
      setloader(true); // start loader when filter is changed
      getData();
    }
  }, [singleLocation]);

  useEffect(() => {
    if (singleCompanyName !== "") {
      setloader(true); // start loader when filter is changed
      getData();
    }
  }, [singleCompanyName]);

  // useEffect(() => {
  //   if (singleSkillName !== "") {
  //     getData();
  //   }
  // }, [singleSkillName]);  //not implemented in the api.

  useEffect(() => {
   if(sessionStorage.getItem('fileBase64')!=="" || sessionStorage.getItem('fileBase64')!==undefined )
   {
    let file =getbase64(window.sessionStorage.getItem("srchfile"),window.sessionStorage.getItem("srchFileName"))
    setResumeFile(file)
  }
  }, [])

  const getData = () => {
    // loaderMsgPrompter();

    let obj = {
      keyword: jobs?.keyword,
      filters: jobs?.filters,
    };
    let tempFilters = {};
    if (Object.keys(obj?.filters)?.length > 0) {
      Object.keys(obj?.filters)?.map((item) => {
        if (obj.filters[item] !== "") {
          tempFilters = { ...tempFilters, [`${item}`]: obj?.filters[item] };
        }
      });
    }
    //to remove filters keys that don't have any values

  
    if (jobs?.keyword !== "") {
      let keywordSrch = {
        method: "post",
        data: {
          keyword: jobs?.keyword,
          // filters: jobs?.filters,
          filters: tempFilters,
        },
      };

        // let res2= dispatch(searchDaxtraByKeyword(keywordSrch?.data));

        //    res2?.then((results)=>{
        //     console.log(results);
        //    })

      axios(
        "https://api.jobcupid.ai/job/by/keyword/search",
        keywordSrch
      ).then(function (response) {
        console.log(response);
        if (
          response?.status === 200
          //  && response?.data?.job_id_list?.length > 0
        ) {
          // setdisable(false);
          setDemoSearch(true);
          // setDemoSearch1("");
          // setnoDataMsg(true);

          // setlocationList([]);
          // setCompanyNameList([]);

          setjob_id_list(response?.data?.job_id_list);
          setprevJobs(jobs);
          window.sessionStorage.setItem("searchfilters", JSON.stringify(jobs));
          window.sessionStorage.setItem(
            "job_id_list",
            JSON.stringify(response?.data?.job_id_list)
          );
          // loaderMsgPrompter(true)
        } else {
          error.jobs = "Data not found";
          setdisable(false);
        }
      });
   
    }

    if (resumeFile) {
      let resume_formData = new FormData();
      resume_formData.append("resume_file", resumeFile || "");
      // resume_formData.append("filters",tempFilters|| "");
      Object.keys(tempFilters)?.map((item) => {
        resume_formData.append(`filters[${item}]`, tempFilters[item] || "");
      });


      let response = dispatch(searchJobByResumeFile(resume_formData));
      response
        .then((res) => {
          if (res?.payload?.status === "ok") {
            searchByResume(res?.payload.reranking_id_list, resumeFile); // to send job_id_list  to searchJobWidget
            //create filters to remember
            window.sessionStorage.setItem(
              "searchfilters",
              JSON.stringify({"keyword":"","filters":tempFilters})
            );


            // window.sessionStorage.setItem(
            //   "job_id_list",
            //   JSON.stringify(res?.payload.reranking_id_list)
            // );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (
      !resumeFile &&
      jobs?.keyword === "" &&
      (selector?.resumeIDFromList !== "" || OptOldResumeID !== "")
    ) {
      getDataByResumeID(selector?.resumeIDFromList || OptOldResumeID);
    }
  };
 

  // const [currentLoaderMsg,setcurrentLoaderMsg]=useState("");

  // const loaderMsgPrompter=(breakNow)=>{

  //   // var elementNo=0
  //   // let msgInterval= setInterval(initInterval, 2000);

  //   // const initInterval= ()=> {
  //   //   if (breakNow===true)
  //   //   {
  //   //   clearInterval(msgInterval);
  //   //   }
  //   //   console.log(JobSrchPlaceHolders[elementNo]);
  //   //   setcurrentLoaderMsg(JobSrchPlaceHolders[elementNo]);
  //   //   elementNo=elementNo+1;

  //   // }
  //   let count = 0;
  //   setcurrentLoaderMsg(JobSrchPlaceHolders[count]);
  //   const interval = setInterval(() => {
  //            console.log(JobSrchPlaceHolders[count]);
  //     setcurrentLoaderMsg(JobSrchPlaceHolders[count+1]);
  //     count++;

  //     if (count === 4) {
  //       clearInterval(interval)
  //     }
  //   }, 2000);

  // // if (breakNow===true)
  // // {
  // // clearInterval(msgInterval);
  // // }
  // // else{
  // //   var msgInterval= setInterval(initInterval, 2000);
  // // }

  // // clearInterval(msgInterval)

  // // console.log("breaknow",breakNow);
  // //   let msgInterval= setInterval(() => {
  // //     console.log("closing");
  // //     if (breakNow===true)
  // //     {
  // //     clearInterval(msgInterval);
  // //     }
  // //   }, 2000);

  // }

  const submitData = (event) => {
    setloader(true);
    setdisable(true);
    event.preventDefault();
    let ErrorList = validation();
    setError(validation());

    if (Object.keys(ErrorList)?.length !== 0) {
      setloader(false);
    } else {
      // setloader(true);
      getData();
    }
  };
  const searchByResume = (job_id_list, file, resume_ID) => {
   
    // createbase64(file).then(base64 => {
    //   sessionStorage["fileBase64"] = base64;
    //   console.log("file stored",base64);
    // });
    setDemoSearch(true);
    window.sessionStorage.setItem("srchFileName", file.name);
    setjob_id_list(job_id_list);
    setResumeFile(file || ""); // get resumeFile from SearchUsingResume

    // window.sessionStorage.setItem("job_id_list",JSON.stringify(job_id_list));
  };



  const getDataByResumeID = (resume_id) => {
    setloader(true);
    let session_resumeID = window.sessionStorage.getItem("srchItem");

    let obj = {
      resume_id: resume_id,
      filters: jobs?.filters,
    };
    let tempFilters = {};
    if (Object.keys(obj?.filters)?.length > 0) {
      Object.keys(obj?.filters)?.map((item) => {
        if (obj.filters[item] !== "") {
          tempFilters = { ...tempFilters, [`${item}`]: obj?.filters[item] };
        }
      });
    }

    let resume_ID = resume_id || session_resumeID;
    let formData = {
      resume_id: resume_ID,
      // filters: jobs?.filters,
      filters: tempFilters,
    };
    let response = dispatch(searchJobByResumeID(formData));
    response
      .then((res) => {
        if (res?.payload?.status === "ok") {
          searchByResume(res?.payload.reranking_id_list, "", resume_ID); // to send job_id_list  to searchJobWidget
          //save the resume_id as while using filter we need to send the resume_id we're working with
          setOptOldResumeID(resume_ID);
          setloader(false);

          window.sessionStorage.setItem("searchfilters", JSON.stringify(jobs));
          window.sessionStorage.setItem(
            "job_id_list",
            JSON.stringify(res?.payload.reranking_id_list)
          );
          window.sessionStorage.setItem("srchItem", resume_ID);
          // window.sessionStorage.setItem("job_id_list",JSON.stringify(res?.payload.reranking_id_list));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <section className="SearchJobWidget">
        <div className="container-xl">
          <div className="TabBoxMain">
            <div className="topNav">
              <ul className="categoryNav list-unstyled d-flex  overflow-auto justify-content-center new-all-category-nav">
                {getTabOptions?.map((tab) => {
                  return (
                    <li
                      style={{
                        display: `${tab?.viewstatus !== "show" && "none"}`,
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="sCategory"
                          value={`cat` + (getTabOptions.indexOf(tab) + 1)}
                          checked={
                            selector?.resumeName === "" &&
                            OptionChoose ===
                              `cat` + (getTabOptions.indexOf(tab) + 1)
                          }
                          hidden
                          onChange={handelRadioBtn}
                        />
                        <span>{tab?.title}</span>
                      </label>
                    </li>
                  );
                })}

                {/* <li>
                  <label>
                    <input
                      type="radio"
                      name="sCategory"
                      value="cat1"
                      checked={OptionChoose === "cat1"}
                      hidden
                      onChange={handelRadioBtn}
                    />
                    <span>Search Using resume</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sCategory"
                      value="cat2"
                      //   checked={OptionChoose === "cat2"}
                      hidden
                      onChange={handelRadioBtn}
                    />
                    <span>Search Using Keywords</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sCategory"
                      value="cat3"
                      checked={OptionChoose === "cat3"}
                      hidden
                      onChange={handelRadioBtn}
                    />
                    <span>Resume feedback</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sCategory"
                      value="cat4"
                      checked={OptionChoose === "cat4"}
                      hidden
                      onChange={handelRadioBtn}
                    />
                    <span>Generate cover letter</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sCategory"
                      value="cat5"
                      checked={OptionChoose === "cat5"}
                      hidden
                      onChange={handelRadioBtn}
                    />
                    <span>On campus events</span>
                  </label>
                </li>
 */}
              </ul>
            </div>
            {OptionChoose === "cat1" && (
              <>
                {" "}
                {!loader ? (
                  ""
                ) : (
                  <Loader
                    data={promptWithCV}
                    needprompt={true}
                    timebetween={4000}
                  />
                )}
                {selector?.resumeName || srchFilename ? (
                  <>
                    <div
                      className="col-auto pe-1 fs-xl-18"
                      id="resultWrap1"
                    ></div>
                    <div className="row suk-Row">
                      <div className="col-sm">
                        <div className="inputBox d-flex align-items-center">
                          <span style={{ fontSize: "17px" }}>
                            Showing results for
                          </span>
                          <b className="resumeName">
                            "{selector?.resumeName || srchFilename}"
                          </b>
                        </div>
                        {/* <br /> */}
                      </div>

                      <div className=" col-sm-auto"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <SearchUsingResume
                      searchByResume={searchByResume}
                      getDataByResumeID={getDataByResumeID}
                    />
                  </>
                )}
              </>
            )}

            {OptionChoose === "cat2" && (
              <>
                {!loader ? (
                  ""
                ) : (
                  <Loader
                    data={promptWithoutCV}
                    needprompt={true}
                    timebetween={4000}
                  />
                )}
                <div className="row suk-Row">
                  <div className="col-sm">
                    <div className="inputBox d-flex align-items-center">
                      <b>What</b>
                      <input
                        type="text"
                        name="keyword"
                        onChange={sub}
                        value={jobs.keyword}
                        id="keyword"
                        className="form-control shadow-none border-0"
                        placeholder="Job title"
                        onKeyPress={(event) =>
                          event.key === "Enter" && submitData(event)
                        }
                      />

                      {isShowJobTitle === true ? (
                        <span
                          onClick={() => {
                            setjobs({ ...jobs, keyword: "" });
                            setisShowJobTitle(false);
                          }}
                          style={{
                            top: "50%",
                            right: "15px",
                            color: "black",
                            marginTop: "2px",
                            // marginRight: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <ClearIcon
                            onClick={() => {
                              setInputValue("");
                            }}
                          />
                        </span>
                      ) : (
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.3851 12.4458C8.73488 14.5685 4.85544 14.4014 2.39866 11.9446C-0.237379 9.30856 -0.237379 5.0347 2.39866 2.39866C5.0347 -0.237379 9.30856 -0.237379 11.9446 2.39866C14.4014 4.85544 14.5685 8.73487 12.4458 11.3851L17.6014 16.5408C17.8943 16.8337 17.8943 17.3085 17.6014 17.6014C17.3085 17.8943 16.8337 17.8943 16.5408 17.6014L11.3851 12.4458ZM3.45932 10.8839C1.40907 8.83369 1.40907 5.50957 3.45932 3.45932C5.50957 1.40907 8.83369 1.40907 10.8839 3.45932C12.9327 5.50807 12.9342 8.82881 10.8885 10.8794C10.8869 10.8809 10.8854 10.8824 10.8839 10.8839C10.8824 10.8854 10.8809 10.8869 10.8794 10.8884C8.82882 12.9342 5.50807 12.9327 3.45932 10.8839Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </div>

                    <div className="error">{error.jobs}</div>
                  </div>
                  <div className=" col-sm">
                    <div className="inputBox d-flex align-items-center">
                      <b>Where</b>
                      <input
                        // setjobs({...jobs,filters:{"PostCode":e?.target?.value}})}
                        type="text"
                        name="filters"
                        id="filters"
                        onKeyPress={(event) =>
                          event.key === "Enter" && submitData()
                        }
                        value={
                          jobs.filters?.Location || jobs.filters?.PostCode || ""
                        }
                        onChange={(e) => {
                          setdisable(false);
                          if (e?.target?.value?.length === 0) {
                            setisShowLocation(false);
                          }
                          if (e?.target?.value !== "") {
                            setisShowLocation(true);
                            if (isNaN(e?.target?.value)) {
                              setisShowLocation(true);
                              // delete jobs.PostCode
                              setjobs({
                                ...jobs,
                                filters: {
                                  // CompanyName: e?.target?.value,
                                  Location: e?.target?.value,
                                  // PostCode:""
                                },
                              });
                            } else {
                              setisShowLocation(true);
                              // delete jobs.Location
                              setjobs({
                                ...jobs,
                                filters: {
                                  PostCode: e?.target?.value,
                                  // Location:""
                                },
                              });
                            }
                          } else {
                            setjobs({
                              ...jobs,
                              filters: {},
                            });
                          }
                        }}
                        className="form-control shadow-none border-0"
                        placeholder="City, state, or pin code"
                      />
                      {isShowLocation === true ? (
                        <span
                          onClick={() => {
                            setisShowLocation(false);
                            //  getSingleLocation("");
                            setdisable(false);
                            setjobs({
                              ...jobs,
                              filters: {
                                // CompanyName: e?.target?.value,
                                Location: "",
                                PostCode: "",
                              },
                            });
                          }}
                          style={{
                            top: "50%",
                            right: "15px",
                            color: "black",
                            marginTop: "2px",
                            marginRight: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <ClearIcon />
                        </span>
                      ) : (
                        ""
                      )}
                      <svg
                        width="18"
                        height="21"
                        viewBox="0 0 18 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 9C5.75 7.20507 7.20507 5.75 9 5.75C10.7949 5.75 12.25 7.20507 12.25 9C12.25 10.7949 10.7949 12.25 9 12.25C7.20507 12.25 5.75 10.7949 5.75 9Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.773543 7.87739C1.11718 3.70845 4.60097 0.5 8.78406 0.5H9.21599C13.3991 0.5 16.8829 3.70845 17.2265 7.87739C17.4115 10.122 16.7182 12.3508 15.2925 14.0943L10.4995 19.9561C9.72449 20.9039 8.27556 20.9039 7.50059 19.9561L2.70752 14.0943C1.28187 12.3508 0.588525 10.122 0.773543 7.87739ZM9 4.25C6.37665 4.25 4.25 6.37665 4.25 9C4.25 11.6234 6.37665 13.75 9 13.75C11.6234 13.75 13.75 11.6234 13.75 9C13.75 6.37665 11.6234 4.25 9 4.25Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className=" col-sm-auto">
                    <button
                      className="btn btn-Custom"
                      onClick={submitData}
                      // onClick={()=>{
                      //   history.push(`/home?srch=${true}&&string=${jobs.keyword}`);
                      // }}
                      disabled={disable}
                      // onClick={() => !demoSearchData()}
                    >
                      {loader ? (
                        <CircularProgress
                          color="inherit"
                          margintop="10px"
                          size="20px"
                        />
                      ) : (
                        "Search Job"
                      )}
                    </button>
                  </div>
                </div>
                {/* {noDataMsg===true ? "" : "NODATA"} */}

                <div></div>

                {/* {DemoSearch === true && (
                  <>
                    {
                      hasChildData === true &&
                      <SearchFilterCard
                        DemoSearch1={DemoSearch1}
                        // job_id_list={job_id_list}
                        educationList={educationList}
                        jobTypeList={jobTypeList}
                        locationList={locationList}
                        CompanyNameList={CompanyNameList}
                        skillset={skillset}
                        getSingleLocation={getSingleLocation}
                        getSingleCompanyName={getSingleCompanyName}
                        getSingleEducationName={getSingleEducationName}
                        getSinglejobtypelist={getSinglejobtypelist}
                        getSkillList={getSkillList}
                        getSingleSkillName={getSingleSkillName}
                        // getDateFilter={getDateFilter}
                        setfilterDate={setfilterDate}
                      />
                    }
                  </>
                )} */}
              </>
            )}
            {(OptionChoose === "cat2" || OptionChoose === "cat1") && (
              <>
                {DemoSearch === true && (
                  // ( job_id_list?.length>0) &&
                  // educationList?.length > 0 ||
                  // jobTypeList?.length > 0 ||
                  // locationList?.length > 0 ||
                  // CompanyNameList?.length > 0 ||
                  // skillset?.length > 0) &&

                  <>
                    <SearchFilterCard
                      // DemoSearch1={DemoSearch1}
                      // job_id_list={job_id_list}
                      educationList={educationList}
                      jobTypeList={jobTypeList}
                      locationList={locationList}
                      CompanyNameList={CompanyNameList}
                      skillset={skillset}
                      getSingleLocation={getSingleLocation}
                      getSingleCompanyName={getSingleCompanyName}
                      getSingleEducationName={getSingleEducationName}
                      getSinglejobtypelist={getSinglejobtypelist}
                      getSkillList={getSkillList}
                      getSingleSkillName={getSingleSkillName}
                      // getDateFilter={getDateFilter}
                      setfilterDate={setfilterDate}
                    />
                  </>
                )}
              </>
            )}

            {OptionChoose === "cat3" && (
              <div className="ResumeFeedBack">
                <label>
                  <input type="file" name="" id="" hidden />
                  <div className="cont">
                    <svg
                      width="60"
                      height="44"
                      viewBox="0 0 60 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 0C34.4638 0 38.9343 1.69605 42.3401 5.10174C45.2306 7.99221 46.8954 11.6464 47.3329 15.4142C54.4403 16.0595 60 22.0268 60 29.3023C60 37.0086 53.7527 43.2558 46.0465 43.2558H11.8605C5.28977 43.2558 0 37.966 0 31.3953C0 24.8247 5.28977 19.5349 11.8605 19.5349H12.689C12.0742 14.4039 13.7441 9.03929 17.6817 5.10174C21.0873 1.69605 25.5362 0 30 0ZM30 14.6512C29.6297 14.6498 29.3696 14.7193 29.0625 15L21.388 21.9767C20.8421 22.4667 20.7418 23.4226 21.2791 23.9607C21.7973 24.4799 22.7214 24.5424 23.2631 24.0479L28.6047 19.186V34.186C28.6047 34.9567 29.2292 35.5814 30 35.5814C30.7708 35.5814 31.3953 34.9567 31.3953 34.186V19.186L36.7369 24.0479C37.2786 24.5424 38.2286 24.5044 38.7209 23.9607C39.2132 23.4169 39.1854 22.4759 38.6119 21.9767L30.9375 15C30.7381 14.8173 30.3732 14.6581 30 14.6512Z"
                        fill="#FAAF40"
                      />
                    </svg>
                    <span>Upload Your Resume</span>
                  </div>
                </label>

                <div className="w-100">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Paste Link Here"
                  />
                </div>

                <div className="searchBtn">
                  <button className="btn">Resume Feedback</button>
                </div>
              </div>
            )}
            {OptionChoose === "cat4" && (
              <div className="ResumeFeedBack">
                <label>
                  <input type="file" name="" id="" hidden />
                  <div className="cont">
                    <svg
                      width="60"
                      height="44"
                      viewBox="0 0 60 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 0C34.4638 0 38.9343 1.69605 42.3401 5.10174C45.2306 7.99221 46.8954 11.6464 47.3329 15.4142C54.4403 16.0595 60 22.0268 60 29.3023C60 37.0086 53.7527 43.2558 46.0465 43.2558H11.8605C5.28977 43.2558 0 37.966 0 31.3953C0 24.8247 5.28977 19.5349 11.8605 19.5349H12.689C12.0742 14.4039 13.7441 9.03929 17.6817 5.10174C21.0873 1.69605 25.5362 0 30 0ZM30 14.6512C29.6297 14.6498 29.3696 14.7193 29.0625 15L21.388 21.9767C20.8421 22.4667 20.7418 23.4226 21.2791 23.9607C21.7973 24.4799 22.7214 24.5424 23.2631 24.0479L28.6047 19.186V34.186C28.6047 34.9567 29.2292 35.5814 30 35.5814C30.7708 35.5814 31.3953 34.9567 31.3953 34.186V19.186L36.7369 24.0479C37.2786 24.5424 38.2286 24.5044 38.7209 23.9607C39.2132 23.4169 39.1854 22.4759 38.6119 21.9767L30.9375 15C30.7381 14.8173 30.3732 14.6581 30 14.6512Z"
                        fill="#FAAF40"
                      />
                    </svg>
                    <span>Upload Your Resume</span>
                  </div>
                </label>

                <div className="w-100">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Paste Link Here"
                  />
                </div>

                <div className="searchBtn">
                  <button className="btn">Generate Cover Letter</button>
                </div>
              </div>
            )}

            {OptionChoose === "cat5" && (
              <>
                {auth ? (
                  <OnCampusJobList
                    event_list={event_list}
                    // handleEventHiringData={handleEventHiringData}
                  />
                ) : (
                  <OnCampusEvents childToParent={childToParent} />
                )}
              </>
            )}
          </div>
        </div>
      </section>

      {/* {(OptionChoose === "cat5" &&  auth)&&( */}
      {/* {OptionChoose === "cat5" && auth && (
        // <>{(ChildData === "2" && auth !== "") && <AfterSearchResualt />}</>
        <OnCampusJobList />
      )} */}

      {
        // OptionChoose === "cat1" ||
        OptionChoose === "cat3" || OptionChoose === "cat4" ? (
          // ||  OptionChoose === "cat5"
          <>
            {ChildData !== "2" && (
              <div className="cat1 text-center py-3 py-xxxl-5 mb-xxxl-3 fs-xl-18">
                <div className="container-xl">
                  <p>
                    <b>
                      {" "}
                      <Link
                        className="postcv"
                        style={{ color: "black", fontWeight: "bolder" }}
                        to="/dashboard/resumes"
                      >
                        Post your resume –
                      </Link>
                    </b>{" "}
                    It only takes a few seconds
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <> </>
        )
      }
      {(OptionChoose === "cat2" ||
        OptionChoose === "cat1" ||
        OptionChoose === "cat5") && (
        <>
          {DemoSearch === true && (
            <>
              <AfterSearchResualt
                job_id_list={job_id_list}
                child2parent={child2parent}
                filterDate={filterDate}
                srchData={prevjobs}
                // srchTerm={selector?.resumeName}
                // child3parent={child3parent}
              />
            </>
          )}
          <section className="homePageOne text-center">
            <div className="container-xl">
              <p>
                <b style={{ color: "black", fontWeight: "bolder" }}>
                  {" "}
                  <Link
                    className="postcv"
                    to="/dashboard/resumes"
                    style={{ color: "black", fontWeight: "bolder" }}
                  >
                    Post your resume 
                  </Link> –
                </b>{" "}
                It only takes a few seconds
              </p>

              {/*  Popular searches and list of searches are turned off until dynamically implemented. */}
              {/* <h3>Popular searches</h3>
              <ul className="list-unstyled m-0 d-flex justify-content-center flex-wrap col-xxl-10 mx-auto">
                {popularSearches.map((data, ind) => (
                  <li key={ind}>
                    <Link to="/">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.9875 12.8715C9.7789 14.6403 6.54604 14.5011 4.49872 12.4538C2.30202 10.2571 2.30202 6.69554 4.49872 4.49884C6.69542 2.30214 10.257 2.30214 12.4537 4.49884C14.501 6.54616 14.6402 9.77902 12.8713 11.9876L17.1677 16.2839C17.4118 16.528 17.4118 16.9237 17.1677 17.1678C16.9236 17.4119 16.5279 17.4119 16.2838 17.1678L11.9875 12.8715ZM5.3826 11.5699C3.67406 9.86137 3.67406 7.09127 5.3826 5.38273C7.09115 3.67418 9.86124 3.67418 11.5698 5.38273C13.2771 7.09002 13.2783 9.8573 11.5735 11.5661C11.5723 11.5674 11.571 11.5686 11.5698 11.5699C11.5685 11.5711 11.5673 11.5724 11.566 11.5737C9.85719 13.2785 7.0899 13.2772 5.3826 11.5699Z"
                          fill="black"
                        />
                      </svg>
                      <span>{data}</span>
                    </Link>
                  </li>
                ))}
              </ul> */}
            </div>
          </section>
        </>
      )}
    </>
  );
}
