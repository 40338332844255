import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../Helper/Helper";

const initialState = {
  status: "idle",
  event_job_id:[]
};


export const geteventJobs = createAsyncThunk(
  "job/by/company/search",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/job/by/company/search",
      method: "POST",
      formData: formData,
    //   isDaxtra: "",
    });
    let resData = response?.data;
 
    return resData;
  }
);




export const EventHiringData = createSlice({
  name: "eventhiring",
  initialState,
  reducers: {
    removeAll: (state) => {
      state.status= "idle";
      state.event_job_id=[];
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(geteventJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(geteventJobs.fulfilled, (state, action) => {
        state.status = "idle";
        if(action?.payload?.status===200)
        {
            console.log(action.payload);
        //   state.EventHiringData=action?.payload?.data;
        //   state.ProfileImage=action?.payload?.profile_pic_file_path;
        //   state.favouriteJobCount=action?.payload?.favouriteJobCount;
        //   state.recmnd_job_count=action?.payload?.recommededJobCount;
        //   state.upcomingEvents=action?.payload?.upcomingEventCount;
        //   state.userResumeCount=action?.payload?.userResumeCount;
        }
 
      })
      .addCase(geteventJobs.rejected, (state, action) => {
        state.status = "idle";
      })

 
    


  },
});

export const { removeAll } = EventHiringData.actions;

export default EventHiringData.reducer;
