
import { configureStore } from "@reduxjs/toolkit";
import SearchJobSlice from "../Slices/SearchJobSlice";
import FavouriteSlice from "../Slices/FavouriteSlice";
import AuthSlice from "../Slices/AuthSlice";
import ResumeSlice from "../Slices/ResumeSlice";
import EventHiringSlice from "../Slices/EventHiringSlice";
export default configureStore({
  reducer: {
    searchJob:SearchJobSlice,
    favourite:FavouriteSlice,
    userProfile:AuthSlice,
    fetchResume:ResumeSlice,
    eventHiring:EventHiringSlice
  },
});
