import React, { useEffect, useState,useHistory } from "react";
import { ApiHelperFunction } from "./Helper/Helper";
import { BrowserRouter as Router, Switch, Route, Link ,Redirect} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import CMS_Module from "./Cms_Module/CMS_Module";
import Update_Profile from "./Cms_Module/Update_Profile/Update_Profile";
import AuthModule from "./AuthModule/AuthModule";
import DashboardModule from "./Dashboard_Module/DashboardModule";

import AboutUs from "./Cms_Module/AboutUs_Page/AboutUs";
import Careers from "./Cms_Module/Careers_Page/Careers";
import ContactUs from "./Cms_Module/ContactUs_Page/ContactUs";
import HowItWorks from "./Cms_Module/HowItWorks_Page/HowItWorks";
import Support from "./Cms_Module/Support/Support";
import Help from "./Cms_Module/Help/Help";
import Privacy from "./Cms_Module/Privacy_policy/Privacy_policy";
import LegalNotice from "./Cms_Module/LegalNotice_Page/LegalNotice";
import Terms from "./Cms_Module/Terms_condition/Terms_conditions";
import News from "./Cms_Module/News_Page/News";
// import DashboardPage from './Dashboard_Module/DashboardPage/DashboardPage'
import EmployerDashboardModule from "./Employer_Dashboard_Module/EmployerDashboardModule";
import Forgot_password from "./AuthModule/Forgot_password/Forgot_password";
import Change_password from "./AuthModule/Forgot_password/Change_password";
import Otp from "./AuthModule/Forgot_password/Otp";
import Password_change from "./AuthModule/Password_Change/Password_change";
import ProtectedRoute from "./Cms_Module/ProtectedRoute/ProtectedRoute";
import EventListByCompany from "./Share_Module/SearchJobWidget/EventListByCompany/EventListByCompany";
import testComp from "./Share_Module/SearchJobWidget/EventListByCompany/testComp";

// const DashboardModule = React.lazy(() =>
//   import("./Dashboard_Module/DashboardModule")
// )


function App() {


  return (
    <>
      <Router>
        <Switch>
          <ProtectedRoute path="/update-profile" component={Update_Profile} />
          <ProtectedRoute path="/password-change" component={Password_change} />
 
          <ProtectedRoute path="/dashboard" component={DashboardModule} />
      
          <ProtectedRoute
            path="/employer-dashboard"
            component={EmployerDashboardModule}
          />
           {/* <ProtectedRoute
            path="/event-list/:cmpName/:resume_id"
           component={EventListByCompany} 
          ></ProtectedRoute> */}
         
         <ProtectedRoute
            path="/event-list"
           component={EventListByCompany} 
          ></ProtectedRoute>
          {/* <ProtectedRoute path="/forgot-password" component={Forgot_password} /> */}
          {/* <Route path="/employer-dashboard">
            <EmployerDashboardModule />
          </Route> */}
        
          <Route path="/about-us">
            <AboutUs />
          </Route>

          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/how-it-works">
            <HowItWorks />
          </Route>
          <Route path="/privacy_policy">
            <Privacy />
          </Route>
          <Route path="/terms_and_condition">
            <Terms />
          </Route>
          <Route path="/legal-notice">
            <LegalNotice />
          </Route>

          <Route path="/support">
            <Support />
          </Route>
          <Route path="/help">
            <Help />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/forgot-password">
            <Forgot_password />
          </Route>
          <Route path="/otp-verification">
            <Otp />
          </Route>
          <Route path="/change-password">
            <Change_password />
          </Route>
          {/* <Route path="/password-change">
            <Password_change />
          </Route> */}
          {/* <Route path="/dashboard">
            <DashboardModule />
          </Route> */}
          <Route path="/auth">
            <AuthModule />
          </Route>
          {/* <Route path="/update-profile">
            <Update_Profile />
          </Route> */}
          <Route path="/">
            <CMS_Module />
          </Route>
        </Switch>
      </Router>
      
      <ToastContainer
        className="foo"
        toastStyle={{ backgroundColor: "#fbaf41", color: "#ffff" }}
      />
    </>
  );
}

export default App;
