import React, { useState, useEffect } from "react";
import { ApiHelperFunction } from "../../Helper/Helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "./AfterClickJobTitle.scss";
import { getSingleJobData } from "../../Redux/Slices/SearchJobSlice";
import AfterSearchResultSkeleton from "./Skeletons/AfterSearchResultSkeleton";
import { changetoFavourite } from "../../Redux/Slices/FavouriteSlice";
import { getFeedBackNCoverLetter } from "../../Redux/Slices/ResumeSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import useSignout from "../Services/Hooks/UseSignOut";
// import { CoverLetterFeedBack } from "./CoverLetterFeedBack";
export default function AfterClickJobTitle({ ID }) {
  const autosignout=useSignout();
  const history = useHistory();
  const selector_user = useSelector((state) => state.userProfile);
  const [currentID, setcurrentID] = useState("");
  const [resume_feedback, setresume_feedback] = useState({});
  const [coverletter, setcoverletter] = useState({});
  const [errMsg, seterrMsg] = useState("");
  const dispatch = useDispatch();
  const { SingleJobData, status } = useSelector((e) => e.searchJob);
  const { feed_cover_status, JointFeedBackNCoverLetter } = useSelector(
    (e) => e.fetchResume
  );
  const [Matching, setMatching] = useState(80);
  const [singleView, setsingleView] = useState({});
  const [financialRanking, setfinancialRanking] = useState({});
  const [satisfactionRanking, setsatisfactionRanking] = useState({});
  const [hiringData, sethiringData] = useState({});
  const [ceoanalysis, setceoanalysis] = useState({});
  const [backingAnalysis, setbackingAnalysis] = useState({});
  const MatchingWidth = {
    width: Matching + "%",
    transition: "width 2s ease",
  };

  const [ChooseTab, setChooseTab] = useState("1");
  const handelChooseTab = (data) => {
    setChooseTab(data);
    generateFeedbackNcoverLetter();
  };
  const generateFeedbackNcoverLetter = () => {
    if (
      selector_user?.userResumeCount >= 1 &&
      selector_user?.UserProfile?._id
    ) {
      if (ChooseTab === "2" || ChooseTab === "3") {
        // to stop api call if already coverletter or
        //feedback has been genrated.
        if (currentID == "" || ID !== currentID) {
          setcurrentID(ID);
          getFeedbackCoverLetter();
        }
      }
    }
  };

  useEffect(() => {
    if (ID !== "") {
      setChooseTab("1"); // always stay on 1st tab when opening.
      setsatisfactionRanking({});
      setfinancialRanking({});
      sethiringData({});
      setceoanalysis({});
      setbackingAnalysis({});
      getJobList(ID);
    }
    return () => {
      setresume_feedback({});
      setcoverletter({});
      seterrMsg("");
    };
  }, [ID]);

  useEffect(() => {
    generateFeedbackNcoverLetter();
  }, [ChooseTab]);

  const getFeedbackCoverLetter = () => {
    let formData = {
      company: singleView?.company || "",
      position_name: singleView?.title || "",
    };

    let response = dispatch(getFeedBackNCoverLetter(formData));

    response
      ?.then((res) => {
        if (res?.payload?.status === 200) {
          setcoverletter(res?.payload?.response?.cover_letter);
          setresume_feedback(res?.payload?.response?.resume_feedback);
        } 
        // else if(res?.payload.status===500 && res?.payload?.auth===false)
        // {
        //   autosignout("Your Session has expired");
        // }
        else {
          seterrMsg(res?.payload?.message);
          setcoverletter({});
          setresume_feedback({});
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // setcoverletter(CoverLetterFeedBack?.cover_letter);
    // setresume_feedback(CoverLetterFeedBack?.resume_feedback);
  };

  const getJobList = async (ID) => {
    let job_id_list = [ID];
    const user_id = localStorage.getItem("user_id");
    let obj = {
      job_id_list: job_id_list,
      user_id: user_id,
    };
    let response2 = dispatch(getSingleJobData(obj));

    response2
      ?.then((res) => {
        if (res?.payload?.status === 200 && res?.payload?.data?.length > 0) {
          let result = res?.payload?.data[0];
          if (Object.keys(result)?.length > 0) {
            setsingleView(result);
            if (
              result?.company_info &&
              Object.keys(result?.company_info)?.length !== 0
            ) {
              if (result?.company_info?.public === true) {
                calculateFinancialRanking(
                  result?.company_info?.public,
                  result?.company_info?.industryOverallFinancialRanking || "",
                  result?.company_info?.sectorOverallFinancialRanking || "",
                  result?.company_info?.industry || "",
                  result?.company_info?.sector || "",
                  ""
                );

                calculateSatisfactionRanking(
                  result?.company_info?.public,
                  result?.company_info?.industryOverAllSatisfactionRanking ||
                    "",
                  result?.company_info?.sectorOverAllSatisfactionRanking || "",
                  result?.company_info?.industry || "",
                  result?.company_info?.sector || ""
                );

                calculateHiringAnalysis(
                  result?.company_info?.public,
                  result?.company_info?.jobs,
                  result?.company_info?.avgOpenRoles || 0,
                  result?.company_info?.full_time_employees,
                  result?.company_info?.industry || "",
                  result?.company_info?.sector || "",
                  result?.company_info?.industry || "",
                  result?.company_info?.sector || ""
                );
                calculateceoanalysis(
                  result?.company_info?.public,
                  result?.company_info?.workplace_ratings?.comparably
                    ?.ceo_score,
                  ""
                );
              } else {
                //for private company, different set of values is sent to the functions.
                calculateInstitutionalBacking(
                  result?.company_info?.public,
                  result?.company_info?.ranking_data?.notable_investors
                );

                calculateFinancialRanking(
                  result?.company_info?.public,
                  "",
                  "",
                  result?.company_info?.industry || "",
                  result?.company_info?.sector || "",
                  result?.company_info?.ranking_data?.total_funded
                );
                calculateHiringAnalysis(
                  result?.company_info?.public,
                  result?.company_info?.jobs,
                  0,
                  result?.company_info?.ranking_data?.employee_count,
                  "",
                  "",
                  "",
                  ""
                );
                calculateceoanalysis(
                  result?.company_info?.public,
                  "",
                  result?.company_info?.ranking_data?.notable_leadership
                );
              }
            }
          }
        }
      })
      .catch((err) => {});
  };
  const makeFavourite = async (_id) => {
    let formData = { job_id: _id };
    let response = dispatch(changetoFavourite(formData));

    return response;
  };

  const changeTofav = (index) => {
    if (localStorage.getItem("user_id")) {
      let response = makeFavourite(index);

      response.then((res) => {
        if (res?.payload?.status === 200) {
          getJobList(ID);
        }
      });
    } else {
      toast("You have to Login first", {
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const calculateInstitutionalBacking = (isPublic, notable_investors) => {
    if (
      isPublic === false &&
      notable_investors &&
      notable_investors?.length > 0
    ) {
      let temp = notable_investors.slice(0, 6);
      let listofInvestors = temp.join(", ");
      setbackingAnalysis({
        label: ` Backed by Institutions`,
        data: `${listofInvestors}`,
        icon_opactiy: "1",
      });
    } else {
      setbackingAnalysis({});
    }
  };

  const calculateFinancialRanking = (
    isPublic,
    industry_value,
    sector_value,
    industry_name,
    sector_name,
    private_total_funding
  ) => {

    if (isPublic === true) {
      if (
        industry_value !== "N/A" ||
        industry_value !== "" ||
        sector_value !== "N/A" ||
        sector_value !== ""
      ) {
        if (industry_value >= 40 && industry_value <= 70) {
          setfinancialRanking({
            label: `Strong Financial Performance in ${industry_name} Industry`,
            data: `${industry_value} th percentile`,
            icon_opactiy: "0.5",
          });
        } else if (industry_value > 70) {
          setfinancialRanking({
            label: ` Financial Leader in ${industry_name} Industry`,
            data: `${industry_value}th percentile`,
            icon_opactiy: "1",
          });
        } else if (sector_value >= 70) {
          setfinancialRanking({
            label: ` Financial Leader in ${sector_name} Sector`,
            data: `${sector_value}th percentile`,
            icon_opactiy: "1",
          });
        }
      } else if (
        (industry_value !== "N/A" || industry_value !== "") &&
        (sector_value === "N/A" || sector_value === "")
      ) {
        if (industry_value >= 40 && industry_value <= 70) {
          setfinancialRanking({
            label: `Strong Financial Performance in ${industry_name} Industry`,
            data: `${industry_value}th percentile`,
            icon_opactiy: "0.5",
          });
        } else if (industry_value > 70) {
          setfinancialRanking({
            label: ` Financial Leader in ${industry_name} Industry`,
            data: `${industry_value} th percentile`,
            icon_opactiy: "1",
          });
        }
      } else if (
        (industry_value === "N/A" || industry_value === "") &&
        (sector_value !== "N/A" || sector_value !== "")
      ) {
        if (sector_value >= 40 && sector_value <= 70) {
          setfinancialRanking({
            label: `Strong Financial Performance in ${sector_name} Sector`,
            data: `${sector_value} th percentile`,
            icon_opactiy: "0.5",
          });
        } else if (sector_value > 70) {
          setfinancialRanking({
            label: ` Financial Leader in ${sector_name} Sector`,
            data: `${sector_value} th percentile`,
            icon_opactiy: "1",
          });
        }
      } else if (
        (industry_value === "N/A" || industry_value === "") &&
        (sector_value === "N/A" || sector_value === "")
      ) {
        setfinancialRanking({});
      }
    }
    //Private
    else if (isPublic === false) {
      ///FOR PRIVATE COMPANY CALCULATION
      if (private_total_funding && private_total_funding !== "N/A")
        setfinancialRanking({
          label: ` Total Funding`,
          data: `$ ${private_total_funding?.toLocaleString("en-US")}`,
          icon_opactiy: "1",
        });
      else {
        setfinancialRanking({});
      }
    }
  };

  const calculateSatisfactionRanking = (
    isPublic,
    industry_value,
    sector_value,
    industry_name,
    sector_name
  ) => {
    if (isPublic === true) {
      //may need to check industry_value !== " "
      if (
        industry_value !== "N/A" ||
        industry_value !== "" ||
        sector_value !== "N/A" ||
        sector_value !== ""
      ) {
        if (industry_value >= 40 && industry_value <= 70) {
          setsatisfactionRanking({
            label: `High Workplace Satisfaction in ${industry_name} Industry`,
            data: `${industry_value}th percentile`,
            icon_opactiy: "0.5",
          });
        } else if (industry_value > 70) {
          setsatisfactionRanking({
            label: `Leader in Workplace Satisfaction in ${sector_name} Industry`,
            data: `${industry_value}th percentile`,
            icon_opactiy: "1",
          });
        } else if (sector_value >= 70) {
          setsatisfactionRanking({
            label: `Leader in Workplace Satisfaction in ${sector_name} Sector`,
            data: `${sector_value} th percentile`,
            icon_opactiy: "1",
          });
        }
      } else if (
        (industry_value !== "N/A" || industry_value !== "") &&
        (sector_value === "N/A" || sector_value === "")
      ) {
        if (industry_value >= 40 && industry_value <= 70) {
          setsatisfactionRanking({
            label: `High Workplace Satisfaction in ${industry_name} Industry`,
            data: `${industry_value} th percentile`,
            icon_opactiy: "0.5",
          });
        }
        //80 >= company[‘industry_overall_ranking_financial’]
        else if (industry_value > 70) {
          setsatisfactionRanking({
            label: ` Leader in Workplace Satisfaction in ${industry_name} Industry`,
            data: `${industry_value} th percentile`,
            icon_opactiy: "1",
          });
        }
      } else if (
        (industry_value === "N/A" || industry_value === "") &&
        (sector_value !== "N/A" || sector_value !== "")
      ) {
        if (sector_value >= 40 && sector_value <= 70) {
          setsatisfactionRanking({
            label: `High Workplace Satisfaction in ${sector_name} Sector`,
            data: `${sector_value} th percentile`,
            icon_opactiy: "0.5",
          });
        }
        //80 >= company[‘industry_overall_ranking_financial’]
        else if (sector_value > 70) {
          setsatisfactionRanking({
            label: `Leader in Workplace Satisfaction in ${sector_name} Sector`,
            data: `${sector_value} th percentile`,
            icon_opactiy: "1",
          });
        }
      } else if (
        (industry_value === "N/A" || industry_value === "") &&
        (sector_value === "N/A" || sector_value === "")
      ) {
        setsatisfactionRanking({});
      }
    } else {
      // FOR PRIVATE COMPANY CALCULATION
    }
  };

  const calculateHiringAnalysis = (
    isPublic,
    jobs,
    avgOpenRoles,
    employeeno,
    industry_name,
    sector_name
  ) => {
    const NumberofJobs = jobs?.length; //"jobs"field is an array of jobs

    if (isPublic === true) {
      if (
        NumberofJobs > avgOpenRoles ||
        (employeeno !== 0 ? NumberofJobs / employeeno >= 0.1 : false)
      ) {
        sethiringData({
          label: "Strong Hiring Activity",
          data: "",
          icon_opactiy: "1",
        });
      } else {
        sethiringData({});
      }
    } else {
      //FOR PRIVATE COMPANY CALCULATION

      if (employeeno && (employeeno !== 0 || employeeno !== "N/A")) {
        if (NumberofJobs / employeeno >= 0.15) {
          sethiringData({
            label: "Strong Hiring Activity",
            data: "",
            icon_opactiy: "1",
          });
        } else {
          sethiringData({});
        }
      } else {
        sethiringData({});
      }
    }
  };

  const calculateceoanalysis = (isPublic, ceoObject, private_ceoObject) => {
    //if isPublic True,typeof ceoObject is number ; if isPublic false then typeof ceoObject is either array or object
    if (isPublic === true) {
      if (ceoObject > 67) {
        setceoanalysis({
          label: "Strong CEO Reviews",
          data: `Average CEO Score: ${ceoObject} `,
          icon_opactiy: "1",
        });
      } else {
        setceoanalysis({});
      }
    } else {
      //for PRIVATE COMPANY

      if (isPublic === false && typeof private_ceoObject !== "Number") {
        let lengthOfceo = private_ceoObject?.length;
        if (lengthOfceo > 0) {
          let temp = private_ceoObject.slice(0, 6);
          let listofceo = temp.join(", ");

          setceoanalysis({
            label: "Notable Company Leadership",
            data: ` ${listofceo}`,
            icon_opactiy: "1",
          });
        }
      }
    }
  };

  return (
    <div className="col-lg-7 pt-5 pt-lg-0">
      {ID ? (
        <div className="h-100 d-flex flex-column">
          <div className="cardDetails">
            <div className="partOne">
         
              {Object.keys(singleView)?.length === 0 && (
                <AfterSearchResultSkeleton />
              )}
              <div className=" after-click-top">
                <div className="after-click-next">
                  <h3>{singleView?.title}</h3>
                </div>
                <div className="row pb-2 fav-icon">
                  <div className="col-auto apply-md">
                  
                    <div className="">
                      <button
                        className="btn p-0 shadow-none favroutie-button1 heart-button "
                        onClick={() => {
                          changeTofav(singleView?._id);
                        }}
                      >
                        {singleView?.isFavourite === true ? (
                          <>
                            <i
                              class="fas heart fa-heart apply-icon-heart"
                              style={{ marginTop: "0px", fontSize: "18px" }}
                            ></i>
                          </>
                        ) : (
                          <>
                            <i
                              class="fal fa-heart"
                              style={{ marginTop: "0px", fontSize: "18px" }}
                            ></i>
                          </>
                        )}
                        <span className="favourite-icon">Add to Favourite</span>
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-auto Apply-now pe-0"
                    style={{ cursor: "pointer" }}
                  >
                    <button className="apply-button">
                      <img
                        className="apply-icon"
                        src="/img/apply-now-logo.svg"
                        alt=""
                      />
                      <span className="apply-text">Apply Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="job-title-top">
                <h5>{singleView?.company}</h5>
              
                <div className="d-flex">
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75 9C5.75 7.20507 7.20507 5.75 9 5.75C10.7949 5.75 12.25 7.20507 12.25 9C12.25 10.7949 10.7949 12.25 9 12.25C7.20507 12.25 5.75 10.7949 5.75 9Z"
                      fill="#FBAF41"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.773543 7.87739C1.11718 3.70845 4.60097 0.5 8.78406 0.5H9.21599C13.3991 0.5 16.8829 3.70845 17.2265 7.87739C17.4115 10.122 16.7182 12.3508 15.2925 14.0943L10.4995 19.9561C9.72449 20.9039 8.27556 20.9039 7.50059 19.9561L2.70752 14.0943C1.28187 12.3508 0.588525 10.122 0.773543 7.87739ZM9 4.25C6.37665 4.25 4.25 6.37665 4.25 9C4.25 11.6234 6.37665 13.75 9 13.75C11.6234 13.75 13.75 11.6234 13.75 9C13.75 6.37665 11.6234 4.25 9 4.25Z"
                      fill="#FBAF41"
                    />
                  </svg>

                  <span className="h5">
                  
                    {singleView?.location}
                  </span>
                </div>
              </div>
            </div>
            <div className="partTwo">
              <div className="row companyInsights">
                <div className="col-md-3 insight-header ">
                  <h5 className="insight-header-text companyinsights">
                    Company Insights
                  </h5>
                </div>

                <div className="col-md-9 insight-icon-list">
                  {
                    // singleView?.company_info &&
                    // Object.keys(singleView?.company_info).length === 0
                    status !== "loading" &&
                    Object?.keys(financialRanking)?.length === 0 &&
                    Object?.keys(satisfactionRanking)?.length === 0 &&
                    Object?.keys(hiringData)?.length === 0 ? (
                      <span className=" fw-5">
                        No Company Insights currently available
                      </span>
                    ) : (
                      <ul className="list-unstyled d-flex flex-wrap insight-content justify-content-around">
                        {/* <img src="img/2.svg" alt=""  /> */}

                        {/*   /// FOR CEO  Analysis Icon */}
                        {Object.keys(ceoanalysis)?.length !== 0 ? (
                          <li>
                            <img
                              src="img/ceo_analysis.png"
                              alt=""
                              className="insight-icons"
                            />
                            <div className={`hiring_tooltip toolTipView`}>
                              <h6> {ceoanalysis?.label}</h6>
                              <p> {ceoanalysis?.data}</p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {/*   /// FOR Hiring  Analysis Icon */}
                        {Object.keys(hiringData).length !== 0 ? (
                          <li>
                            {/* <img src="img/3.svg" alt="" /> */}
                            <img
                              src="img/hiring_analysis.png"
                              alt=""
                              className="insight-icons"
                              style={{
                                opacity: `${hiringData?.icon_opactiy || "1"}`,
                              }}
                            />
                            <div className={`hiring_tooltip toolTipView`}>
                              <h6> {hiringData?.label}</h6>
                              <p> {hiringData?.data}</p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {/*   /// FOR Financial   Icon */}
                        {Object.keys(financialRanking)?.length !== 0 ? (
                          <li>
                            {/* <img src="img/4.svg" alt="" /> */}
                            <img
                              src="img/dollar_icon_dark.png"
                              alt=""
                              className="insight-icons"
                              style={{
                                opacity: `${
                                  financialRanking?.icon_opactiy || "1"
                                }`,
                              }}
                            />
                            <div className={`financial_tooltip toolTipView`}>
                              <h6> {financialRanking?.label}</h6>
                              <p> {financialRanking?.data}</p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        {/*   /// FOR Satisfaction Icon */}
                        {Object.keys(satisfactionRanking)?.length !== 0 ? (
                          <li>
                            {/* <img src="img/5.svg" alt="" /> */}
                            <img
                              src="img/ranking_sat_dark.png"
                              alt=""
                              className="insight-icons"
                              style={{
                                opacity: `${
                                  satisfactionRanking?.icon_opactiy || "1"
                                }`,
                              }}
                            />
                            <div className={`satisfaction_tooltip toolTipView`}>
                              <h6> {satisfactionRanking?.label}</h6>
                              <p> {satisfactionRanking?.data}</p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        {/*      /// FOR Institutional Backing Analysis Icon */}
                        {Object.keys(backingAnalysis)?.length !== 0 ? (
                          <li>
                            <img
                              src="img/bank_icon.png"
                              alt=""
                              className="insight-icons"
                            />
                            <div className={`banking_tooltip toolTipView`}>
                              <h6> {backingAnalysis?.label}</h6>
                              <p> {backingAnalysis?.data}</p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="partThree">
              <div className="job-match">
                <h5>Match %</h5>
                <div className="progressBox">
                  <span className="number" style={{ left: `${Matching}%` }}>
                    {Matching}%
                  </span>
                  <div className="lineBox">
                    <div className="skin" style={MatchingWidth}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="customTab  ">
            <div className="tabHead">
              <ul className="list-unstyled d-flex overflow-auto">
                <li>
                  <button
                    className={`btn shadow-none text-nowrap ${
                      ChooseTab === "1" && "active"
                    }`}
                    onClick={() => handelChooseTab("1")}
                  >
                    Job Details
                  </button>
                </li>
                <li>
                  <button
                    className={`btn shadow-none text-nowrap ${
                      ChooseTab === "2" && "active"
                    }`}
                    onClick={() => handelChooseTab("2")}
                  >
                    feedback
                  </button>
                </li>
                <li>
                  <button
                    className={`btn shadow-none text-nowrap ${
                      ChooseTab === "3" && "active"
                    }`}
                    onClick={() => handelChooseTab("3")}
                  >
                    cover letter
                  </button>
                </li>
              </ul>
            </div>

            <div className="tabBody">
              {ChooseTab === "1" && (
                <>
                  {singleView?.description}

                </>
              )}
              {ChooseTab === "2" && (
                <>
                  <div className="feedBackWrap text-capitalize">
                    <div className="table-responsive">
                      {selector_user?.UserProfile?._id ? (
                        <>
                          {selector_user?.userResumeCount < 1 ? (
                            <>
                              <span className="reumeAlert">
                                You need at least one resume saved in your
                                profile to generate Feedback
                                <button
                                  className=" btn-Custom sign_in_bottom"
                                  onClick={() => {
                                    history.push("/dashboard/resumes");
                                  }}
                                >
                                  Upload a resume
                                </button>
                              </span>
                            </>
                          ) : (
                            <>
                              {feed_cover_status === "loading" ? (
                                <>
                                  <span className="reumeAlert">
                                    <CircularProgress
                                      color="inherit"
                                      className="resumeAlertLoader"
                                    />
                                    Generating resume feedback.. Please wait..
                                  </span>
                                </>
                              ) : (
                                <>
                                  {Object?.keys(resume_feedback)?.length !==
                                  0 ? (
                                    <>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Technical Skill Required</th>
                                            <th>Your Skill Level Match</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <>
                                            {/* {Object?.keys(resume_feedback)?.length !== 0 
                                      ? */}

                                            {Object.keys(resume_feedback)?.map(
                                              (item) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <span>{item}</span>
                                                    </td>
                                                    <td>
                                                      <b>
                                                        {
                                                          resume_feedback[item]
                                                            ?.skill_level
                                                        }
                                                      </b>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}

                                  
                                          </>
                                        </tbody>
                                      </table>
                                    </>
                                  ) : (
                                    <>
                                      <span className="reumeAlert">
                                        {errMsg}
                                      </span>{" "}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <span className="reumeAlert">
                            You need be logged in to use this feature
                            <button
                              className=" btn-Custom sign_in_bottom"
                              onClick={() => {
                                history.push("/auth/login");
                              }}
                            >
                              Sign in
                            </button>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              {ChooseTab === "3" && (
                <>
                  {selector_user?.UserProfile?._id ? (
                    <>
                      {selector_user?.userResumeCount < 1 ? (
                        <>
                          <span className="reumeAlert">
                            You need at least one resume saved in your profile
                            to a Cover Letter
                            <button
                              className=" btn-Custom sign_in_bottom"
                              onClick={() => {
                                history.push("/dashboard/resumes");
                              }}
                            >
                              Upload a resume
                            </button>
                          </span>
                        </>
                      ) : (
                        <>
                          {feed_cover_status == "loading" ? (
                            <span className="reumeAlert">
                              <CircularProgress
                                color="inherit"
                                className="resumeAlertLoader"
                              />
                              Generating resume Cover letter.. Please wait..
                            </span>
                          ) : (
                            <>
                              <p>
                                {coverletter?.text ? (
                                  coverletter?.text
                                ) : (
                                  <>
                                    <span className="reumeAlert">{errMsg}</span>
                                  </>
                                )}

                              </p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span className="reumeAlert">
                        You need be logged in to use this feature
                        <button
                          className=" btn-Custom sign_in_bottom"
                          onClick={() => {
                            history.push("/auth/login");
                          }}
                        >
                          Sign in
                        </button>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
