import React, {useEffect} from "react";
import Header from "../../Share_Module/Header/Header";
import Footer from "../../Share_Module/Footer/Footer";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <body className="text-center" style={{ marginTop: "200px", marginBottom:"200px",minHeight:"20vh" }}>
        <h1>Contact Us Page</h1>
      </body>
      <Footer />
    </>
  );
}
