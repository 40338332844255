import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function OnCampusJobListSkeleton() {
  return (
    <div>
      <div className="dateCategory ">
        <div>
          <h4 className="dataHead" style={{ width: "300px" }}>
            <Skeleton variant="text" />
          </h4>
          <div className="listCategory  ">
            <div className="row justify-content-between align-items-center ">
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
            </div>
            <div className="row justify-content-between align-items-center ">
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
              <div className="col-md-3 ">
                <Skeleton variant="text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
