import React from 'react'
import { withRouter } from 'react-router-dom'
import EventListByCompany from './EventListByCompany';
 const testComp = (props) => {
   console.log(props);
  return (

    <EventListByCompany {...props}/>

  )
}
export default withRouter(testComp);