import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch,useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../Helper/Helper";
import "./MyJobs.scss";
import CloseIcon from "@mui/icons-material/Close";
import { getFavouriteJobList } from "../../Redux/Slices/FavouriteSlice";
import Moment from "react-moment";
export default function MyJobs() {
  const dispatch=useDispatch();
  const [Data, setData] = useState([]);
  const [perPage, setperPage] = useState(2);
  const [pageNo, setpageNo] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [disable, setdisable] = useState(false);
  // const [apply,setapplynow]=useState("apply now")
  const handlepage = (e, p) => {
    // getMultiResume(p);

    setpageNo(p);
    // getMultiResume(p);
  };
  
  const ReadMore = ({ children, id }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <p className="text">
        {isReadMore ? <>{text.slice(0, 140)}...</>  : <>{text}</>}
        <span onClick={toggleReadMore} className="read-or-hide text-orange">
          {isReadMore ? <span> Read more</span> : <span> Show Less</span>}
        </span>
        <div className="col removefromFav">
          <hr />

          <button
            onClick={() => {
              // setapplynow("applied") // for job application
              // console.log(id);
            }}
            className="btn btn-Custom"
          >
            {/* {apply} */}
            Apply Now
          </button>
        </div>
      </p>
    );
  };

  const RemoveFavourite = async (_id) => {
    let response = await ApiHelperFunction({
      urlPath: "/api/job/select-favourite-job",
      method: "POST",
      formData: { job_id: _id },
      isDaxtra: "",
    });
    if (response?.status === 200) {
      toast("Job removed successfully", {
        hideProgressBar: true,
        autoClose: 2500,
      });
      getMultiResume();
    }
  };

  const getMultiResume = async () => {

    let formData= {
      pagination: {
        page: pageNo,
        perpage: perPage,
      },
    };
   let response_rdx= dispatch(getFavouriteJobList(formData));
   response_rdx?.then((res)=>{


 if (res?.payload?.status === 200) {
      if (pageNo !== 1 && res?.payload?.joblist?.data?.length === 0) {
        setpageNo(pageNo - 1);
      } else {
        settotalPages(res?.payload?.joblist?.pageCount);
        setData(res?.payload?.joblist?.data);
      }
    } else if (res?.payload?.status === 201) {
      setData([]);
    }

    
   }).catch((err)=>{console.log(err);})

  };

  useEffect(() => {
  
    getMultiResume(pageNo);
  }, [pageNo]);

  return (
    <>
      <div className="container">
        {Data?.length === 0 ? (
          <span class="text-sm-center fa-2x text-color1 ">
            <h4> No Data Found</h4>
          </span>
        ) : (
          Data?.map((e) => {
            return (
              <div className="row">
                <div className="col">
                  <div className="card py-4 px-4 singleDiv h-auto">
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-lg-8">
                            {" "}
                            <h2 className="res-title">{e?.title}</h2>
                          </div>
                          <div className="col-lg-4 removefromFav">
                            <button
                              onClick={() => {
                                RemoveFavourite(e?._id);
                              }}
                              className="btn btn-Custom btn-remove"
                            >
                              <CloseIcon /> Remove
                            </button>
                          </div>
                        </div>
                        <h5 className="res-next">{e?.company}</h5>
                        <span>
                          <h3>
                            <div className="row">
                              <div className="col-lg-7">
                                <span style={{ display: "flex" }}>
                                  <svg
                                    style={{ marginBottom: "14px" }}
                                    width="25"
                                    height="21"
                                    viewBox="0 0 18 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.75 9C5.75 7.20507 7.20507 5.75 9 5.75C10.7949 5.75 12.25 7.20507 12.25 9C12.25 10.7949 10.7949 12.25 9 12.25C7.20507 12.25 5.75 10.7949 5.75 9Z"
                                      fill="#FBAF41"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.773543 7.87739C1.11718 3.70845 4.60097 0.5 8.78406 0.5H9.21599C13.3991 0.5 16.8829 3.70845 17.2265 7.87739C17.4115 10.122 16.7182 12.3508 15.2925 14.0943L10.4995 19.9561C9.72449 20.9039 8.27556 20.9039 7.50059 19.9561L2.70752 14.0943C1.28187 12.3508 0.588525 10.122 0.773543 7.87739ZM9 4.25C6.37665 4.25 4.25 6.37665 4.25 9C4.25 11.6234 6.37665 13.75 9 13.75C11.6234 13.75 13.75 11.6234 13.75 9C13.75 6.37665 11.6234 4.25 9 4.25Z"
                                      fill="#FBAF41"
                                    />
                                  </svg>{" "}
                                  <h3 className="favourite_title1">
                                    {e?.location}{" "}
                                  </h3>
                                </span>
                              </div>
                              <div className="col-lg-5">
                                <span style={{ display: "flex" }}>
                                  <i
                                    class="fas fa-calendar-week"
                                    style={{
                                      color: "#FBAF41",
                                      paddingLeft: "2px",
                                      paddingRight: "8px",
                                      fontSize: "20px",
                                    }}
                                  ></i>

                                  <h3 style={{ fontSize: "15px" }}>
                                     {/* {
                                     getTime(
                                      e?.["daxtra add response"].DxResponse
                                        .Vacancy[0].StructuredOptions.DatePosted
                                    )
                                    } */}

                                    <Moment fromNow>
                                    {e?.updated_date || e?.created_date}
                                    {/* {
                                      e?.last_updated || 
                                      e?.["daxtra add response"].DxResponse
                                        .Vacancy[0].StructuredOptions.DatePosted
                                    } */}
                                    {/* e?.["daxtra add response"].DxResponse
                                        .Vacancy[0].StructuredOptions.DatePosted*/}
                                  </Moment>
                                  </h3>
                                </span>
                              </div>
                            </div>
                          </h3>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col"></div>
                    </div>
                    <div className="row">
                      <div className="col text-justify-left">
                        <ReadMore id={e?._id}>{e?.description}</ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className="secFooter paginationFooter">
        {Data?.length === 0 && Data ? (
          ""
        ) : (
          <Stack
            spacing={2}
            className="d-flex list-unstyled justify-content-center align-items-center pagination"
            activeClassName="active"
          >
            <Pagination
              count={totalPages}
              page={pageNo}
              onChange={handlepage}
              shape="rounded"
            />
          </Stack>
        )}
      </div>
    </>
  );
}
