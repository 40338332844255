import React, { useState, useEffect } from "react";
import { ApiHelperFunction } from "../../Helper/Helper";
import Header from "../../Share_Module/Header/Header";
import Footer from "../../Share_Module/Footer/Footer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function Forgot_password() {
  const [PassTog, setPassTog] = useState("password");
  const [PassTog1, setPassTog1] = useState("password");
  const handlPassTog = (data) => {
    setPassTog(data);
  };
  const handlPassTog1 = (data) => {
    setPassTog1(data);
  };
  const [disable, setdisable] = useState(false);
  const [res, setres] = useState("");
  const history = useHistory();
  const [userData, setuserData] = useState({
    old_password: "",
    new_password: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const validation = () => {
    let error = {};

    if (!userData.old_password) {
      error.old_password = "Old Password is required";
    } else if (userData.old_password.length < 8) {
      error.old_password = "Minimum 8 characters";
    }
    if (!userData.new_password) {
      error.new_password = "New Password is required";
    } else if (userData.new_password.length < 8) {
      error.new_password = "Minimum 8 character";
    }

    return error;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitData = async (event) => {
    event.preventDefault();
    setdisable(true);

    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      let response = await ApiHelperFunction({
        urlPath: "/api/user/change-password",
        method: "post",
        // headers: {
        //   "x-access-token": window.localStorage.getItem("token"),
        //   "Content-Type": "application/json",
        // },

        formData: {
          email: localStorage.getItem("email"),
          new_password: userData.new_password,
          old_password: userData.old_password,
        },
        isDaxtra:""
      });

      if (response !== "") {
        setdisable(true);
        toast(response?.data?.message, {
          hideProgressBar: true,
          autoClose: 2500,
        });

        if (response.status === 200) {
          history.push("/auth/login");
          localStorage.clear()
        }
      }
    }
  };
  return (
    <>
      <Header />
      <section className="loginPage  changePassWordWrap d-flex flex-column align-items-center justify-content-center d-flex align-items-center" style={{minHeight: "65vh",marginTop: "20px"}} >
        <div className="container">
          <div className="row">
            <div className="card my-0">
              <h3 style={{ textAlign: "center" }}>Change password</h3>
              <div className="form ">
                <div className="row">
                  <div className="col-md-12 passwordForm">
                    <div className="form-group mb-4">
                      <div className="label">Enter Old Password</div>
                      <div className="position-relative">
                        <input
                          type={PassTog}
                          name="old_password"
                          id="old_password"
                          value={userData.old_password}
                          onChange={postUserData}
                          className="form-control"
                        />
                        {PassTog === "text" ? (
                          <span onClick={() => handlPassTog("password")}>
                            Hide
                          </span>
                        ) : (
                          <span onClick={() => handlPassTog("text")}>Show</span>
                        )}
                      </div>
                    </div>
                    <div className="error" style={{ marginBottom: "10px" }}>
                      {error.old_password}
                    </div>
                  </div>
                  <div className="col-md-12 passwordForm">
                    <div className="form-group mb-4">
                      <div className="label">Enter New Password</div>
                      <div className="position-relative">
                        <input
                          type={PassTog1}
                          name="new_password"
                          id="new_password"
                          value={userData.new_password}
                          onChange={postUserData}
                          className="form-control"
                        />
                        {PassTog1 === "text" ? (
                          <span onClick={() => handlPassTog1("password")}>
                            Hide
                          </span>
                        ) : (
                          <span onClick={() => handlPassTog1("text")}>
                            Show
                          </span>
                        )}
                      </div>

                      <div className="error" style={{ marginBottom: "10px" }}>
                        {error.new_password}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {!disable ? ( */}
                <>
                  <div
                    // disabled={disable}
                    className="loginBtn-"
                    onClick={submitData}
                  >
                    <button className="btn shadow-none btn-color1 text-white   hoverBtn">
                      Submit
                    </button>
                  </div>{" "}
                </>
                
              </div>

              {/* <div className="pt-md-4">
                <p className="registerLink">
                  New to JobCupid? <Link to="/auth/register">register</Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
