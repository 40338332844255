import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../Helper/Helper";

const initialState = {
  status: "idle",
  changedtoFavMsg: "",
  favouriteJobs:[],
  recommendedJobs:[]
};

export const changetoFavourite = createAsyncThunk(
  "api/job/select-favourite-job",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/job/select-favourite-job",
      method: "post",
      formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;

    return resData;
  }
);

export const getFavouriteJobList = createAsyncThunk(
  "/api/job/get-favourite-job-list",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/job/get-favourite-job-list",
      method: "POST",
      formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;

    return resData;
  }
);

export const getRecommnededJobs = createAsyncThunk(
  "/api/resume/get-recommended-job-data",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/resume/get-recommended-job-data",
      method: "POST",
      formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;
    return resData;
  }
);




export const FavouriteSlice = createSlice({
  name: "Favourite",
  initialState,
  reducers: {
    removeAll: (state) => {
      // state = initialState;
      state.status= "idle";
      state.changedtoFavMsg= "";
      state.favouriteJobs=[];
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(changetoFavourite.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(changetoFavourite.fulfilled, (state, action) => {
        state.status = "idle";
        if (
          action?.payload?.status === 200 &&
          Object.keys(action?.payload)?.length > 0
        ) {
          state.changedtoFavMsg = action?.payload?.message;

          toast(action?.payload?.message, {
            hideProgressBar: true,
            autoClose: 2500,
          });
        }
      })
      .addCase(changetoFavourite.rejected, (state, action) => {
        state.status = "idle";
      })

      //get favourite jobs
      .addCase(getFavouriteJobList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getFavouriteJobList.fulfilled, (state, action) => {
        state.status = "idle";
        
        if (
          action?.payload?.status === 200 &&
          Object.keys(action?.payload?.joblist?.data)?.length > 0
        ) {
           state.favouriteJobs=action?.payload?.joblist?.data;
        }
      })
      .addCase(getFavouriteJobList.rejected, (state, action) => {
        state.status = "idle";
      })
      //get recommended jobs
      .addCase(getRecommnededJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getRecommnededJobs.fulfilled, (state, action) => {
        state.status = "idle";
        
        if (
          action?.payload?.status === 200 
          // &&          Object.keys(action?.payload?.joblist?.data)?.length > 0
        ) {
          //  state.recommendedJobs=action?.payload?.joblist?.data;


        }
      })
      .addCase(getRecommnededJobs.rejected, (state, action) => {
        state.status = "idle";
      });

  },
});

export const { removeAll } = FavouriteSlice.actions;

export default FavouriteSlice.reducer;
