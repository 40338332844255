import React, { useState, useEffect } from "react";
import { ApiHelperFunction } from "../../Helper/Helper";
import Header from "../../Share_Module/Header/Header";
import Footer from "../../Share_Module/Footer/Footer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "./Change_password.scss";
export default function Forgot_password() {
  const [PassTog, setPassTog] = useState("password");
  const [PassTog1, setPassTog1] = useState("password");
  const handlPassTog = (data) => {
    setPassTog(data);
  };
  const handlPassTog1 = (data) => {
    setPassTog1(data);
  };
  const history = useHistory();
  const [userData, setuserData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const validation = () => {
    let error = {};
    setError({});
    if (!userData.new_password) {
      error.new_password = "New Password is required";
    } else if (userData.new_password.length < 8) {
      error.new_password = "Minimum 8 characters";
    }
    if (!userData.confirm_password) {
      error.confirm_password = "Confirm Password is required";
    }
    if (userData.new_password !== userData.confirm_password) {
      error.confirm_password = "Password doesn't match";
    }
    if (Object.keys(error).length > 0) {
      setError(error);
    }

    if (Object.keys(error)?.length > 0) {
      setError(error);
    }

    return error;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const submitData = async (event) => {
    event.preventDefault();
    let ErrorList = validation();
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      let response = await ApiHelperFunction({
        urlPath: "/api/user/forget-password-change-password",
        method: "post",

        formData: {
          email: localStorage.getItem("email"),
          new_password: userData.new_password,
        },
        isDaxtra:""
      });

      if (response !== "") {
        toast(response?.data?.message, {
          hideProgressBar: true,
          autoClose: 2500,
        });
        if (response.status === 200) {
          history.push("/auth/login");
        }
      }
    }
  };
  return (
    <>
      <Header />
      <section className="loginPage changePasswordBeforeLogin d-flex align-items-center" >
        <div className="container">
          <div className="row">
            <div className="card my-0" >
              <h3 style={{ textAlign: "center" }}>Enter New password</h3>
              <div className="form changetoNewPass">
                <div className="row">
                  <div className="col-md-12 passwordForm">
                    <div className="form-group mb-4 ">
                      <div className="label">Enter New Password</div>
                      <div className="position-relative">
                        <input
                          type={PassTog}
                          name="new_password"
                          id="new_password"
                          value={userData.new_password}
                          onChange={postUserData}
                          className="form-control"
                        />

                        {PassTog === "text" ? (
                          <span onClick={() => handlPassTog("password")}>
                            Hide
                          </span>
                        ) : (
                          <span onClick={() => handlPassTog("text")}>Show</span>
                        )}
                      </div>
                      <div className="error">{error.new_password}</div>
                    </div>

                    <div className="form-group mb-3" >
                      <div className="label">Confirm New Password</div>
                      <div className="position-relative">
                        <input
                          type={PassTog1}
                          name="confirm_password"
                          id="confirm_password"
                          value={userData.confirm_password}
                          onChange={postUserData}
                          className="form-control"
                        />

                        {PassTog1 === "text" ? (
                          <span onClick={() => handlPassTog1("password")}>
                            Hide
                          </span>
                        ) : (
                          <span onClick={() => handlPassTog1("text")}>
                            Show
                          </span>
                        )}
                      </div>
                      <div className="error">{error.confirm_password}</div>
                    </div>
                  </div>
                </div>

                <div className="customBtn LoginBtn-  " onClick={submitData}>
                  <button className="btn shadow-none btn-color1 text-white btn-lg hoverBtn ">
                    Submit
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
