import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../Helper/Helper";

const initialState = {
  status: "idle",
  UserProfile: null,
  ProfileImage:null,
  favouriteJobs:[],
  favouriteJobCount:null,
  recmnd_job_count:null,
  upcomingEvents:null,
  userResumeCount:null,
  userFavouriteResumeID:null
};


export const getUserProfile = createAsyncThunk(
  "/api/user/viewUserDetails",
  async (formData) => {
 
    const response = await ApiHelperFunction({
      urlPath: "/api/user/viewUserDetails",
      method: "GET",
      // formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;
 
    return resData;
  }
);




export const UserProfile = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    removeAll: (state) => {
      // state = initialState;
      state.status= "idle";
      state.UserProfile= null;
      state.ProfileImage=null;
      state.favouriteJobs=[];
      state.favouriteJobCount=null;
      state.recmnd_job_count=null;
      state.userFavouriteResumeID=null;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.status = "idle";
        if(action?.payload?.status===200)
        {
          state.UserProfile=action?.payload?.data;
          state.ProfileImage=action?.payload?.profile_pic_file_path;
          state.favouriteJobCount=action?.payload?.favouriteJobCount;
          state.recmnd_job_count=action?.payload?.recommededJobCount;
          state.upcomingEvents=action?.payload?.upcomingEventCount;
          state.userResumeCount=action?.payload?.userResumeCount;
          state.userFavouriteResumeID=action?.payload?.favouriteResumeId
        }
        // if (
        //   action?.payload?.status === 200 &&
        //   Object.keys(action?.payload)?.length > 0
        // ) {
        //   state.changedtoFavMsg = action?.payload?.message;

        //   toast(action?.payload?.message, {
        //     hideProgressBar: true,
        //     autoClose: 2500,
        //   });
        // }
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.status = "idle";
      })

 
    


  },
});

export const { removeAll } = UserProfile.actions;

export default UserProfile.reducer;
