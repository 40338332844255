import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { ApiHelperFunction } from "../../Helper/Helper";

const initialState = {
  AllJobstatus: "idle",
  status:"idle",
  getDaxStatus:"idle",
  SingleJobData: {},
  FirstdaxtraID:"",
  JobListData:null,
  jobListfetchSuccessMsg:"",
  resumeIDFromList:"",
  resumeName:""
};

export const getSingleJobData = createAsyncThunk(
  "/api/search/keyword",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/search/keyword",
      method: "post",
      formData: formData,
      isDaxtra: "",
    });
    let resData = response?.data;
    
    return resData;
  }
);

export const fetchJobList=createAsyncThunk(
  "/api/search-joblist",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/search/keyword",
      method: "post",
      formData: formData,
      isDaxtra: "",
      
    });
    let resData = response?.data;
    
    return resData;
  }
);


export const searchJobByResumeID=createAsyncThunk(
  "/job/by/resume/search",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/job/by/resume/search",
      method: "POST",
      formData: formData,
      // isDaxtra: "",
    });
    let resData = response?.data;

    return resData;
  }
);

export const searchJobByResumeFile=createAsyncThunk(
  "/job/by/resume/file/search",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/job/by/resume/file/search",
      method: "POST",
      formData: formData,
      // isDaxtra: "",
    });
    let resData = response?.data;
    return resData;
  }
);



export const searchDaxtraByKeyword=createAsyncThunk(
  "job/by/keyword/search",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "/job/by/keyword/search",
      method: "POST",
      formData: formData,
      // isDaxtra: "",
    });
    let resData = response.data;
    return resData;
  }
);


export const SearchJobSlice = createSlice({
  name: "SearchJob",
  initialState,
  reducers: {
    setdaxtraID:(state,action)=>{
      state.FirstdaxtraID=action?.payload;
    },
    removeAll: (state) => {
        // state = initialState;
        state.AllJobstatus= "idle";
        state.SingleJobData= {};
        state.FirstdaxtraIDstate="";
        state.JobListData=null;
        state.jobListfetchSuccessMsg="";
        state.resumeIDFromList="";
        state.resumeName="";
      },
     setResumeID:(state,action)=>{
    state.resumeIDFromList=action?.payload?.ID;
    state.resumeName=action?.payload?.resume_name;

     },
     clearResumeID:(state)=>{
       state.resumeName="";
       state.resumeIDFromList="";
     } 

     
  },
  extraReducers: (builder) => {
    builder
    //Get job Details of one single job
      .addCase(getSingleJobData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSingleJobData.fulfilled, (state, action) => {
        state.status = "idle";
        if (Object.keys(action?.payload?.data)?.length > 0) {
          
          state.SingleJobData = action?.payload?.data[0];
        }
      })
      .addCase(getSingleJobData.rejected, (state, action) => {
        state.status = "idle";
      })

      //Get all jobs
      .addCase(fetchJobList.pending, (state, action) => {
        state.AllJobstatus = "loading";
        state.JobListData=null;
      })
      .addCase(fetchJobList.fulfilled, (state, action) => {
        state.AllJobstatus = "idle";
        if (action?.payload?.status===200  && action?.payload?.data?.length>0) {
        state.JobListData=action?.payload?.data;
        state.jobListfetchSuccessMsg=action?.payload?.message;
        }
      })
      .addCase(fetchJobList.rejected, (state, action) => {
        state.AllJobstatus = "idle";
      })

      //get DaxtraID of jobs from client API- Search by resume ID
      .addCase(searchJobByResumeID.pending, (state, action) => {
        state.AllJobstatus = "loading";
        state.JobListData=null;
      })
      .addCase(searchJobByResumeID.fulfilled, (state, action) => {
        state.AllJobstatus = "idle";
        
      })
      .addCase(searchJobByResumeID.rejected, (state, action) => {
        state.AllJobstatus = "idle";
      })
      // Search Jobs with Resume File from client API
      //get DaxtraID of jobs from client API- Search by resume ID
      .addCase(searchJobByResumeFile.pending, (state, action) => {
        state.AllJobstatus = "loading";
        state.JobListData=null;
      })
      .addCase(searchJobByResumeFile.fulfilled, (state, action) => {
        state.AllJobstatus = "idle";
        
      })
      .addCase(searchJobByResumeFile.rejected, (state, action) => {
        state.AllJobstatus = "idle";
      })

      //Search Job BKeyword- Get DaxtraID Array  
      .addCase(searchDaxtraByKeyword.pending, (state, action) => {
        state.getDaxStatus = "loading";
        state.JobListData=null;
      })
      .addCase(searchDaxtraByKeyword.fulfilled, (state, {payload}) => {
        state.getDaxStatus = "idle";
        // console.log(payload);
        
      })
      .addCase(searchDaxtraByKeyword.rejected, (state, action) => {
        state.getDaxStatus = "idle";
      })



  },
});

 export const { removeAll,setdaxtraID,setResumeID,clearResumeID } = SearchJobSlice.actions;

export default SearchJobSlice.reducer;
